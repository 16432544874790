import React, { Component } from "react";
import { Link } from "react-router-dom";

import intl from "react-intl-universal";
//引入事件对象
import { emit } from "../../../emit";

import "./CommonHeader.less";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { Carousel, Menu, Select } from "antd";
const { SubMenu } = Menu;
// eslint-disable-next-line
const { Option } = Select;

class CommonHeader extends Component {
  constructor(props) {
    super(props);

    //获取默认语言
    var DefaultLang = localStorage.getItem("lang");

    var LangArr = ["zh-CN", "en-US"];

    if (!LangArr.includes(DefaultLang)) {
      DefaultLang = "English";
      localStorage.setItem("lang", "en-US");
    } else {
      if (DefaultLang === "zh-CN") {
        DefaultLang = "中文";
      } else {
        DefaultLang = "English";
      }
    }

    var location = this.props.location.pathname.split("/");
    var current = location[location.length - 1]
      ? location[location.length - 1]
      : "home";

    var HomeClass = "header";

    if (current === "home") {
      HomeClass = "header HomeBanner";
    }

    this.state = {
      current: current,
      DefaultLang: DefaultLang,
      HomeClass: HomeClass,
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }
  handleClick = (e) => {
    this.setState({ current: e.key });
  };
  next() {
    this.slider.next();
  }
  prev() {
    this.slider.prev();
  }
  handleChange(val) {
    emit.emit("change_language", val);
  }
  render() {
    const bannerSet = {
      dots: true,
      lazyLoad: true,
      effect: "scrollx",
    };

    let BannerList = null,
      BannerBtn = null;

    if (this.state.current === "home") {
      BannerList = (
        <Carousel
          {...bannerSet}
          className="banner"
          ref={(el) => (this.slider = el)}
        >
          <div className="item">
            <div className="box">
              <h1 className="title">{intl.get("banner1-title")}</h1>
              <p className="desc">{intl.getHTML("banner1-desc")}</p>
              <a className="btn" href="#tool">
                {intl.get("banner-btn")}
              </a>
            </div>
            <img
              className="img"
              src="/assets/images/common/banner1.jpg"
              alt="banner"
            />
          </div>
          <div className="item">
            <div className="box">
              <h1 className="title">{intl.get("banner2-title")}</h1>
              <p className="desc">{intl.getHTML("banner2-desc")}</p>
              <Link className="btn" to="/team">
                {intl.get("banner-btn")}
              </Link>
            </div>
            <img
              className="img"
              src="/assets/images/common/banner.jpg"
              alt="banner"
            />
          </div>
          {/* <div className="item">
                    <div className="box">
                        <h1 className="title">{intl.get('banner3-title')}</h1>
                        <p className="desc">{intl.getHTML('banner3-desc')}</p>
                        <Link className="btn" to="/dream">{intl.get('banner-btn')}</Link>
                    </div>
                    <img className="img" src="/assets/images/common/banner.jpg" alt="banner" />
                </div>
                <div className="item">
                    <div className="box">
                        <h1 className="title">{intl.get('banner4-title')}</h1>
                        <p className="desc">{intl.getHTML('banner4-desc')}</p>
                        <Link className="btn" to="/application">{intl.get('banner-btn')}</Link>
                    </div>
                    <img className="img" src="/assets/images/common/banner.jpg" alt="banner" />
                </div>
                <div className="item">
                    <div className="box">
                        <h1 className="title">{intl.get('banner5-title')}</h1>
                        <p className="desc">{intl.getHTML('banner5-desc')}</p>
                        <Link className="btn" to="/lecture">{intl.get('banner-btn')}</Link>
                    </div>
                    <img className="img" src="/assets/images/common/banner.jpg" alt="banner" />
                </div>
                <div className="item">
                    <div className="box">
                        <h1 className="title">{intl.get('banner6-title')}</h1>
                        <p className="desc">{intl.getHTML('banner6-desc')}</p>
                        <Link className="btn" to="/online">{intl.get('banner-btn')}</Link>
                    </div>
                    <img className="img" src="/assets/images/common/banner.jpg" alt="banner" />
                </div>
                <div className="item">
                    <div className="box">
                        <h1 className="title">{intl.get('banner7-title')}</h1>
                        <p className="desc">{intl.getHTML('banner7-desc')}</p>
                        <Link className="btn" to="/dreamfam">{intl.get('banner-btn')}</Link>
                    </div>
                    <img className="img" src="/assets/images/common/banner.jpg" alt="banner" />
                </div> */}
        </Carousel>
      );

      BannerBtn = (
        <div className="banner-btn">
          <button className="left" onClick={this.prev}>
            <LeftOutlined />
          </button>
          <button className="right" onClick={this.next}>
            <RightOutlined />
          </button>
        </div>
      );
    }

    return (
      <header className={this.state.HomeClass}>
        {BannerList}

        {BannerBtn}

        {/* 导航 */}
        <div className="navigation">
          <div className="menu">
            <Link to="/">
              <img
                className="img"
                src="/assets/images/common/logo.svg"
                alt="logo"
              />
            </Link>

            <div className="navbox">
              <Menu
                className="navlist"
                selectedKeys={[this.state.current]}
                mode="horizontal"
                onClick={this.handleClick}
              >
                <Menu.Item className="nav" key="home">
                  <Link className="name" to="/">
                    {intl.get("menu1")}
                  </Link>
                </Menu.Item>
                <SubMenu className="nav" key="about" title={intl.get("menu2")}>
                  <Menu.Item className="nav" key="about1">
                    <Link to="/introduction">{intl.get("menu2-list1")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="about2">
                    <Link to="/team">{intl.get("menu2-list2")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="about3">
                    <Link to="/company">{intl.get("menu2-list3")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="about4">
                    <Link to="/mechanism">{intl.get("menu2-list4")}</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  className="nav"
                  key="language"
                  title={intl.get("menu3")}
                >
                  <Menu.Item className="nav" key="lang1">
                    <Link to="/its">{intl.get("menu3-list1")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="lang2">
                    <Link to="/s">{intl.get("menu3-list2")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="lang3">
                    <Link to="/it">{intl.get("menu3-list3")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="lang4">
                    <Link to="/c">{intl.get("menu3-list4")}</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu className="nav" key="dream" title={intl.get("menu4")}>
                  {/*
									<Menu.Item className="nav" key="train1">
                                        <Link to="/dream">{intl.get('menu4-list1')}</Link>
                                    </Menu.Item>*/}
                  <Menu.Item className="nav" key="train4">
                    <Link to="/dreamc">{intl.get("menu4-list4")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="train2">
                    <Link to="/dreamit">{intl.get("menu4-list2")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="train3">
                    <Link to="/dreamfam">{intl.get("menu4-list3")}</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  className="nav"
                  key="contact"
                  title={intl.get("menu5")}
                >
                  <Menu.Item className="nav" key="children">
                    <Link to="/children">{intl.get("menu5-list1")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="online">
                    <Link to="/online">{intl.get("menu5-list2")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="offline">
                    <Link to="/offline">{intl.get("menu5-list3")}</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu className="nav" key="major" title={intl.get("menu6")}>
                  <Menu.Item className="nav" key="article">
                    <Link to="/article">{intl.get("menu6-list1")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="application">
                    <Link to="/application">{intl.get("menu6-list2")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="lecture">
                    <Link to="/lecture">{intl.get("menu6-list3")}</Link>
                  </Menu.Item>
                  <Menu.Item className="nav" key="train">
                    <Link to="/train">{intl.get("menu6-list4")}</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item className="nav" key="join">
                  <Link className="name" to="/join">
                    {intl.get("menu7")}
                  </Link>
                </Menu.Item>
              </Menu>

              <Select
                className="language"
                defaultValue={this.state.DefaultLang}
                bordered={false}
                onChange={this.handleChange.bind(this)}
              >
                <Option value="zh-CN">中文</Option>
                <Option value="en-US">English</Option>
              </Select>
              {/* <div
                    className="language"
                    defaultValue={this.state.DefaultLang}
                    bordered={false}
                    onChange={this.handleChange.bind(this)}
                  >
                    <Option value="zh-CN">中文</Option>
                    <Option value="en-US">English</Option>
                  </div> */}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

// eslint-disable-next-line
export default (props) => (
  <CommonHeader {...props} key={props.location.pathname} />
);
