import axios from 'axios'
import qs from 'qs'
// import * as aes from './aes'


axios.defaults.timeout = 2000000;   //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'; //配置请求头
axios.defaults.baseURL = 'https://app.ourbethel.cn/bethelapp/v2/bethel_official/';   //配置接口地址

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
    //在发送请求之前做某件事
    // config.headers.Accept="appliaction/json,text/plan";
    if(config.method  === 'post')
    {
        config.data = qs.stringify(config.data);
        // config.data = aes.encrypt(config.data)
    }
    return config
},(error) =>{
    return Promise.reject(error)
})

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
    //解密
    // var data = aes.decrypt(res)

    //对响应数据做些事
    if(!res.data.success)
    {
        return Promise.resolve(res.data)
    }

    return res.data
}, (error) => {
    return Promise.reject(error);
})

//返回一个Promise(发送post请求)
const fetchPost = (url,param = {}) => {
    var LangActive = localStorage.getItem('lang')

    var LangArr = ['zh-CN','en-US'];

    if(!LangArr.includes(LangActive))
    {
        LangActive = 'zh-CN'
        localStorage.setItem('lang',LangActive)
    }

    if(LangActive === "zh-CN")
    {
        param.language = "chinese"
    }else
    {
        param.language = "english"
    }

    return new Promise((resolve, reject) => {
        axios.post(url,param)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

// 返回一个Promise(发送get请求)
const fetchGet = (url,param = {}) => {
    var LangActive = localStorage.getItem('lang')

    var LangArr = ['zh-CN','en-US'];

    if(!LangArr.includes(LangActive))
    {
        LangActive = 'zh-CN'
        localStorage.setItem('lang',LangActive)
    }

    if(LangActive === "zh-CN")
    {
        param.language = "chinese"
    }else
    {
        param.language = "english"
    }


    return new Promise((resolve, reject) => {
        axios.get(url,{params:param})
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export {
    fetchPost,
    fetchGet,
}