import React, { Component } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";

import "./Article.less";

import { Layout, Pagination } from "antd";

// import * as article from '../../api/article'
import * as home from "../../api/home";

import moment from "moment";

export default class Article extends Component {
  constructor(props) {
    super(props);
    console.log("we are in constructor");
    
    var type = localStorage.getItem("type")
      ? localStorage.getItem("type")
      : null;
    var page = localStorage.getItem("page") ? localStorage.getItem("page") : 1;
    var artlist = localStorage.getItem("articles")
      ? localStorage.getItem("articles")
      : [];

    this.state = {
      type: type,
      artlist: artlist,
      page: parseInt(page),
      page_size: 16,
      total_count: 0,
      total_pages: 1,
    };
  }
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  async componentDidMount(args) {
    // await this.setState({
    //   artlist: [],
    //   page: localStorage.getItem('page'),
    //   page_size: 16,
    //   total_count: 0,
    //   total_pages: 1,
    // });
    // console.log("we are in didMount", this.state);
    if (this.state.artlist.length===0){
      await this.ArtList();
    }
    
  }
  componentWillUnmount() {
    localStorage.setItem("page", this.state.page);
    localStorage.setItem("articels", this.state.artlist);
    // console.log("Article willUnmount!!!!", this.state);
    this.props.history.listen((location, action) => {
      // console.log(
      //   "history.listener",
      //   location,
      //   action,
      //   this.props.history.location.pathname
      // );
      if(location.pathname!=="/detail"){
        localStorage.setItem("page", 1);
        localStorage.setItem("articels", []);
      }
    });
    
  }
  ChangeType = async (type) => {
    localStorage.setItem("type", type);

    await this.setState({
      type: type,
      artlist: [],
      page: 1,
      total_count: 0,
      total_pages: 1,
    });

    await this.ArtList();
  };
  //获取文章列表
  ArtList = async () => {
    var data = {
      page: this.state.page,
      page_size: this.state.page_size,
      type: 1,
    };

    var type = this.state.type;

    if (type) {
      data.w_type = type;
    }

    if (type === "course") {
      data.type = 2;

      home.news(data).then((success) => {
        if (success.items.length) {
          this.setState({
            artlist: success.items,
            total_count: success.total_count,
            total_pages: success.total_pages,
          });
        }
      });
    } else {
      home.news(data).then((success) => {
        if (success.items.length) {
          this.setState({
            artlist: success.items,
            total_count: success.total_count,
            total_pages: success.total_pages,
          });
        }
      });
    }
  };
  ChangePage = async (page) => {
    if (page !== this.state.page) {
      await this.setState({
        page: page,
      });
      this.scrollToAnchor("articleList");
      this.ArtList();
    }
  };
  render() {
    let ArtList = () => {
      let arr = [];

      this.state.artlist.map((item, index) => {
        arr.push(
          <div className="item" key={index}>
            <div className="img">
              {/* <img src="/assets/images/major/article/article1.png" alt="banner" /> */}
              <img src={item.cover_url} alt="banner" />
            </div>
            <div className="title">
              <Link
                className="link"
                to={{
                  pathname: "/detail",
                  query: { id: item._id, type: this.state.type },
                }}
              >
                {item.title}
              </Link>
            </div>
            <div className="desc">{item.title}…</div>
            <div className="info">
              <div className="time">
                {moment(item.createdAt).format("YYYY/MM")}
              </div>
              <div className="type">{item.sub_title}</div>
            </div>
          </div>
        );
        return arr;
      });

      return arr;
    };

    return (
      <div id="article">
        <Helmet>
          <title>{intl.get("article-html-head-title")}</title>
          <meta
            name="keywords"
            content="1.最新科研，ASHA资讯，学术报道，临床案例，专业文章，专业课程"
          />
          <meta
            name="description"
            content="美国培声听力语言中心致力于打造专业人士的补给站，汇集最新科研，ASHA资讯，学术报道，临床案例，专业文章和独家设计专业课程提升专业人士的儿童言语-语言障碍的诊治水平。"
          />
        </Helmet>

        {/* banner图 */}
        <Layout className="SingleBanner">
          <div className="img">
            <img src="/assets/images/major/article/banner.png" alt="banner" />
          </div>
          <div className="box">
            <div className="content">
              <h3 className="title">{intl.get("article-banner-title")}</h3>
            </div>
          </div>
        </Layout>

        {/* 导航 */}
        <Layout className="menulist">
          <div className="navlist">
            <div onClick={() => this.ChangeType(null)} className="link">
              <span>{intl.get("article-menu-title1")}</span>
              <span>{intl.get("article-menu-desc1")}</span>
            </div>
            <div onClick={() => this.ChangeType("new")} className="link">
              <span>{intl.get("article-menu-title2")}</span>
              <span>{intl.get("article-menu-desc2")}</span>
            </div>
            <div onClick={() => this.ChangeType("case")} className="link">
              <span>{intl.get("article-menu-title3")}</span>
              <span>{intl.get("article-menu-desc3")}</span>
            </div>
            <div onClick={() => this.ChangeType("learning")} className="link">
              <span>{intl.get("article-menu-title4")}</span>
              <span>{intl.get("article-menu-desc4")}</span>
            </div>
            <div onClick={() => this.ChangeType("course")} className="link">
              <span>{intl.get("article-menu-title5")}</span>
              <span>{intl.get("article-menu-desc5")}</span>
            </div>
          </div>
        </Layout>

        <Layout className="articleContainer" id="articleList">
          <div className="articleList">{ArtList()}</div>

          <div className="pagebox">
            <Pagination
              className="pages"
              current={this.state.page}
              total={this.state.total_count}
              pageSize={this.state.page_size}
              onChange={this.ChangePage}
              showSizeChanger={false}
            />
          </div>
        </Layout>
      </div>
    );
  }
}
