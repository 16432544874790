import React,{Component} from 'react'
// import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './Application.less'

import {Layout} from 'antd'


export default class Application extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    render()
    {
        return (
          <div id="application">
            <Helmet>
              <title>{intl.get('application-html-head-title')}</title>
              <meta
                name="keywords"
                content="培伴APP，儿童语言诊疗服务平台，AI人工智能，个性化定制干预方案，语言筛查评估工具，专家力荐的智能APP，预约北美英国专家"
              />
              <meta
                name="description"
                content="美国培声听力语言中心多年来一直服务于国内知名三甲医院，地区医院和康复中心的临床专业人士（医生和治疗师等）、家长及各机构特教老师与主流学校教师，积累了丰富的经验。结合各方需求，培声倾力打造了一款集测评，诊疗，培训，课程，科普，问答，以及国内外治疗师分享为一体的强大APP。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img
                  src="/assets/images/major/application/banner.png"
                  alt="banner"
                />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">
                    {intl.get("application-banner-title")}
                  </h3>
                  <div className="desc">
                    {intl.getHTML("application-banner-desc")}
                  </div>
                  <div className="download">
                    <img
                      src={intl.get("application-downlad-img")}
                      alt="download"
                    />
                  </div>
                </div>
              </div>
            </Layout>

            <Layout className="AppContainer">
              {/* APP简介 */}
              <Layout className="greyContainer">
                <div className="introduction">
                  <div className="left">
                    <img
                      src="/assets/images/major/application/introduction.png"
                      alt="introduction"
                    />
                  </div>
                  <div className="right">
                    <div className="title">
                      {intl.get("application-introduction-title")}
                    </div>
                    <div className="short">
                      {intl.get("application-introduction-short")}
                    </div>
                    <div className="desc">
                      {intl.get("application-introduction-desc")}
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 面向人群 */}
              <Layout className="whiteContainer">
                <div className="crowd">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("application-crowd-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("application-crowd-explain-desc")}
                    </div>
                  </div>

                  <div className="crowdBox">
                    <div className="character">
                      <div className="img">
                        <img
                          src="/assets/images/major/application/crowd1.png"
                          alt="crowd"
                        />
                      </div>
                      <div className="zh">
                        {intl.get("application-crowd-icon-title1-cn")}
                      </div>
                      <div className="en">
                        {intl.get("application-crowd-icon-title1-en")}
                      </div>
                    </div>
                    <div className="character">
                      <div className="img">
                        <img
                          src="/assets/images/major/application/crowd2.png"
                          alt="crowd"
                        />
                      </div>
                      <div className="zh">
                        {intl.get("application-crowd-icon-title2-cn")}
                      </div>
                      <div className="en">
                        {intl.get("application-crowd-icon-title2-en")}
                      </div>
                    </div>
                    <div className="character">
                      <div className="img">
                        <img
                          src="/assets/images/major/application/crowd3.png"
                          alt="crowd"
                        />
                      </div>
                      <div className="zh">
                        {intl.get("application-crowd-icon-title3-cn")}
                      </div>
                      <div className="en">
                        {intl.get("application-crowd-icon-title3-en")}
                      </div>
                    </div>
                    <div className="img">
                      <img
                        src="/assets/images/major/application/crowd.png"
                        alt="crowd"
                      />
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 临床专业人士 */}
              <Layout className="clinicalContainer">
                <div className="back">
                  <img
                    src="/assets/images/major/application/clinical.png"
                    alt="clinical"
                  />
                </div>

                <div className="clinical">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("application-clinical-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("application-clinical-explain-desc")}
                    </div>
                  </div>

                  <div className="box">
                    <div className="left">
                      <div className="number">
                        <span>01</span>
                      </div>
                      <div className="title">
                        {intl.get("application-clinical-box-title")}
                      </div>
                      <div className="desc">
                        {intl.get("application-clinical-box-desc")}
                      </div>
                    </div>
                    <div className="right">
                      <img
                        src="/assets/images/major/application/clinicalimg.png"
                        alt="clinical"
                      />
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 专业的交流平台 */}
              <Layout className="whiteContainer">
                <div className="communication">
                  <div className="left">
                    <img
                      src="/assets/images/major/application/communication.png"
                      alt="communication"
                    />
                  </div>
                  <div className="right">
                    <div className="number">
                      <span>02</span>
                    </div>
                    <div className="title">
                      {intl.get("application-communication-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.get("application-communication-explain-desc")}
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 独家研发的专业培训 */}
              <Layout className="greyContainer">
                <div className="train">
                  <div className="left">
                    <div className="number">
                      <span>03</span>
                    </div>
                    <div className="title">
                      {intl.get("application-train-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.get("application-train-explain-desc")}
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src="/assets/images/major/application/train.png"
                      alt="clinical"
                    />
                  </div>
                </div>
              </Layout>

              {/* 家长 */}
              <Layout className="parentContainer">
                <div className="back">
                  <img
                    src="/assets/images/major/application/parent.png"
                    alt="parent"
                  />
                </div>

                <div className="parent">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("application-parent-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("application-parent-explain-desc")}
                    </div>
                  </div>

                  <div className="box">
                    <div className="left">
                      <img
                        src="/assets/images/major/application/parentimg.png"
                        alt="parent"
                      />
                    </div>

                    <div className="right">
                      <div className="number">
                        <span>01</span>
                      </div>
                      <div className="title">
                        {intl.get("application-parent-box-title")}
                      </div>
                      <div className="desc">
                        {intl.get("application-parent-box-desc")}
                      </div>
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 预约美国/加拿大/英国认证的言语语言病理师 */}
              <Layout className="whiteContainer">
                <div className="appointment">
                  <div className="left">
                    <div className="number">
                      <span>02</span>
                    </div>
                    <div className="title">
                      {intl.getHTML("application-appointment-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.get("application-appointment-explain-desc")}
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src="/assets/images/major/application/appointment.png"
                      alt="clinical"
                    />
                  </div>
                </div>
              </Layout>

              {/* 老师 */}
              <Layout className="teacherContainer">
                <div className="back">
                  <img
                    src="/assets/images/major/application/teacher.png"
                    alt="teacher"
                  />
                </div>

                <div className="teacher">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("application-teacher-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("application-teacher-explain-desc")}
                    </div>
                  </div>

                  <div className="box">
                    <div className="left">
                      <img
                        src="/assets/images/major/application/teacherimg.png"
                        alt="teacher"
                      />
                    </div>

                    <div className="right">
                      <div className="title">
                        {intl.get("application-teacher-box-title")}
                      </div>
                      <div className="desc">
                        {intl.getHTML("application-teacher-box-desc")}
                      </div>
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 六大功能模块 */}
              <Layout className="greyContainer">
                <div className="function">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("application-function-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("application-function-explain-desc")}
                    </div>
                  </div>

                  <div className="functionBox">
                    <div className="character lefticon">
                      <div className="left">
                        <img
                          src="/assets/images/major/application/function1.png"
                          alt="function"
                        />
                      </div>
                      <div className="right">
                        {intl.get("application-function-list1")}
                      </div>
                    </div>
                    <div className="character lefticon">
                      <div className="left">
                        <img
                          src="/assets/images/major/application/function2.png"
                          alt="function"
                        />
                      </div>
                      <div className="right">
                        {intl.get("application-function-list2")}
                      </div>
                    </div>
                    <div className="character lefticon">
                      <div className="left">
                        <img
                          src="/assets/images/major/application/function3.png"
                          alt="function"
                        />
                      </div>
                      <div className="right">
                        {intl.get("application-function-list3")}
                      </div>
                    </div>
                    <div className="character righticon">
                      <div className="left">
                        <img
                          src="/assets/images/major/application/function4.png"
                          alt="function"
                        />
                      </div>
                      <div className="right">
                        {intl.get("application-function-list4")}
                      </div>
                    </div>
                    <div className="character righticon">
                      <div className="left">
                        <img
                          src="/assets/images/major/application/function5.png"
                          alt="function"
                        />
                      </div>
                      <div className="right">
                        {intl.get("application-function-list5")}
                      </div>
                    </div>
                    <div className="character righticon">
                      <div className="left">
                        <img
                          src="/assets/images/major/application/function6.png"
                          alt="function"
                        />
                      </div>
                      <div className="right">
                        {intl.get("application-function-list6")}
                      </div>
                    </div>
                    <div className="img">
                      <img
                        src="/assets/images/major/application/function.png"
                        alt="function"
                      />
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 评估工具 */}
              <Layout className="whiteContainer">
                <div className="tool">
                  <div className="left">
                    <div className="number">
                      <span>01</span>
                    </div>
                    <div className="title">
                      {intl.getHTML("application-tool-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.getHTML("application-tool-explain-desc")}
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src="/assets/images/major/application/tool.png"
                      alt="clinical"
                    />
                  </div>
                </div>
              </Layout>

              {/* 干预方案 */}
              <Layout className="greyContainer">
                <div className="plan">
                  <div className="left">
                    <img
                      src="/assets/images/major/application/plan.png"
                      alt="plan"
                    />
                  </div>
                  <div className="right">
                    <div className="number">
                      <span>02</span>
                    </div>
                    <div className="title">
                      {intl.get("application-plan-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.get("application-plan-explain-desc")}
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 精准定位 */}
              <Layout className="whiteContainer">
                <div className="position">
                  <div className="left">
                    <div className="number">
                      <span>03</span>
                    </div>
                    <div className="title">
                      {intl.get("application-position-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.get("application-position-explain-desc")}
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src="/assets/images/major/application/position.png"
                      alt="clinical"
                    />
                  </div>
                </div>
              </Layout>

              {/* 预约专家 */}
              <Layout className="greyContainer">
                <div className="appointment">
                  <div className="left">
                    <img
                      src="/assets/images/major/application/appointment.png"
                      alt="appointment"
                    />
                  </div>
                  <div className="right">
                    <div className="number">
                      <span>04</span>
                    </div>
                    <div className="title">
                      {intl.get("application-appointment2-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.get("application-appointment2-explain-desc")}
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 研发 */}
              <Layout className="whiteContainer">
                <div className="development">
                  <div className="left">
                    <div className="number">
                      <span>05</span>
                    </div>
                    <div className="title">
                      {intl.get("application-development-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.getHTML("application-development-explain-desc")}
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src="/assets/images/major/application/development.png"
                      alt="clinical"
                    />
                  </div>
                </div>
              </Layout>

              {/* 在线解答 */}
              <Layout className="greyContainer">
                <div className="online">
                  <div className="left">
                    <img
                      src="/assets/images/major/application/online.png"
                      alt="online"
                    />
                  </div>
                  <div className="right">
                    <div className="number">
                      <span>06</span>
                    </div>
                    <div className="title">
                      {intl.get("application-online-explain-title")}
                    </div>
                    <div className="desc">
                      {intl.get("application-online-explain-desc")}
                    </div>
                  </div>
                </div>
              </Layout>

              {/* 二维码 */}
              <Layout className="whiteContainer qrcode">
                <div className="img">
                  <img src={intl.get("application-qrcode-img")} alt="qrcode" />
                </div>
              </Layout>
            </Layout>
          </div>
        );
    }
}