import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './S.less'

import { Layout} from 'antd';


export default class S extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="S">
            <Helmet>
              <title>Dream-S</title>
              <meta
                name="keywords"
                content="儿童语言测试，儿童语言能力筛查，2.5-8岁语言测试，2.5-8岁语言能力测试，语言发育迟缓测试"
              />
              <meta
                name="description"
                content="美国培声DREAM-S是中国大陆首个基于常模的普通话儿童语言能力筛查工具，由中美两国心理语言学家、语言学家、言语-语言病理学家、测试统计学家等组成的培声科研团队研发而成。其常模研究基于2010年的中华人民共和国人口普查数据，建立了中国大陆地区儿童语言发育常模。DREAM-S包含语言理解和语言表达两个部分，考察孩子在词汇、句法和语义方面相较同龄人的发展水平。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img src="/assets/images/language/S/banner.png" alt="banner" />
              </div>
              <div className="box">
                <div className="thumb">
                  <img src="/assets/images/language/S/thumb.png" alt="thumb" />
                </div>
                <div className="content">
                  <h3
                    className="title"
                    style={
                      intl.options.currentLocale === "en-US"
                        ? { fontSize: "2em", lineHeight: "40px" }
                        : { fontSize: "2.5em" }
                    }
                  >
                    {intl.getHTML("S-banner-title")}
                  </h3>
                  <p className="desc">{intl.get("S-banner-desc")}</p>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("S-menulist1-zh")}</span>
                  <span>{intl.get("S-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("application")}
                  className="link"
                >
                  <span>{intl.get("S-menulist2-zh")}</span>
                  <span>{intl.get("S-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("features")}
                  className="link"
                >
                  <span>{intl.get("S-menulist3-zh")}</span>
                  <span>{intl.get("S-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("internationalS")}
                  className="link"
                >
                  <span>{intl.get("S-menulist4-zh")}</span>
                  <span>{intl.get("S-menulist4-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("case")}
                  className="link"
                >
                  <span>{intl.get("S-menulist5-zh")}</span>
                  <span>{intl.get("S-menulist5-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("tool")}
                  className="link"
                >
                  <span>{intl.get("S-menulist6-zh")}</span>
                  <span>{intl.get("S-menulist6-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="SContainer">
              <div className="modular">
                {/* 简介 */}
                <div id="introduction">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("S-explain-title")}</h4>
                    </div>
                    <div className="desc">{intl.get("S-explain-desc")}</div>
                  </div>

                  <div className="content">
                    <div className="desc">{intl.get("S-explain-content")}</div>

                    <div className="back">
                      <img
                        src="/assets/images/language/S/introduction-back.png"
                        alt="back"
                      />
                    </div>
                  </div>
                </div>

                {/* 应用场景 */}
                <div id="application">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("S-application-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("S-application-explain-desc")}
                    </div>
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/S/application1.png"
                          alt="application1"
                        />
                      </div>
                      <div className="title">
                        {intl.getHTML("S-application-list1")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/S/application2.png"
                          alt="application2"
                        />
                      </div>
                      <div className="title">
                        {intl.getHTML("S-application-list2")}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 特点与优势 */}
                <div id="features">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("S-features-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("S-features-explain-desc")}
                    </div>
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/S/icon1.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("S-features-list1-title")}
                      </div>
                      <p className="desc">
                        {intl.get("S-features-list1-desc")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/S/icon2.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("S-features-list2-title")}
                      </div>
                      <p className="desc">
                        {intl.get("S-features-list2-desc")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/S/icon3.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("S-features-list3-title")}
                      </div>
                      <p className="desc">
                        {intl.get("S-features-list3-desc")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/S/icon4.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("S-features-list4-title")}
                      </div>
                      <p className="desc">
                        {intl.get("S-features-list4-desc")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* 国际标准 */}
                <div id="internationalS">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("S-internationalS-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("S-internationalS-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="back">
                      <img
                        src="/assets/images/language/S/international.png"
                        alt="back"
                      />
                    </div>

                    <div className="desc">
                      {intl.get("S-internationalS-explain-content")}
                    </div>
                  </div>
                </div>

                {/* 临床案例与测评报告 */}
                <div id="case">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("S-case-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("S-case-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="card">
                      <img src={intl.get("S-case-img")} alt="card" />
                    </div>
                    <div className="desc">
                      {intl.getHTML("S-case-explain-content")}
                    </div>
                    <div className="pages">
                      <div className="img">
                        <img
                          src="/assets/images/language/S/pages1.png"
                          alt="pages1"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* 语言测评工具购买 */}
                <div id="tool">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("S-tool-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("S-tool-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("S-tool-explain-content")}
                      <div className="notice">
                        {intl.get("S-tool-explain-notice")}
                      </div>
                      <div className="qrcode">
                        <img
                          src="/assets/images/common/contact-qrcode.jpeg"
                          alt="qrcode"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* 了解更多 */}
                <div id="learnmore">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("S-learnmore-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("S-learnmore-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("S-learnmore-explain-content")}
                    </div>

                    <div className="link">
                      <Link
                        className="btn"
                        to={{ pathname: "/mechanism", query: { tool_id: "s" } }}
                      >
                        {intl.get("S-learnmore-explain-btn")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        );
    }
}