import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './Cert.less'
import * as cert from '../../api/cert'

import {Layout, Tabs, Pagination} from 'antd'
const { TabPane } = Tabs;


export default class Cert extends Component{
    constructor(props)
    {
        super(props)

        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

        //判断是否扫描二维码带有参数ID
        var certification_id = this.GetParams('certification_id')
        var Type = this.GetParams('type') ? this.GetParams('type') : 'DREAM-C';

        this.state = {
            Type:Type,
            certification_id:certification_id,
            certlist:[],
            page:1,
            page_size:44,
            total_count:0,
            total_pages:1,
            date:date
        }
    }
    async componentDidMount()
    {
        await this.setState({
            certlist:[],
            page:1,
            total_count:0,
            total_pages:1
        })

        this.CertList()
    }
    CertList = () => {
        var data = {
            type:this.state.Type,
            page:this.state.page,
            page_size:this.state.page_size,
        }

        if(this.state.certification_id)
        {
            data.certification_id = this.state.certification_id
        }

        cert.certifications(data).then(success => {            
            if(success.items.length)
            {
                this.setState({
                    certlist:success.items,
                    total_count:success.total_count,
                    total_pages:success.total_pages
                })
            }
        })
    }
    ChangeCert = async (key) =>
    {
        await this.setState({
            Type:key,
            certlist:[],
            page:1,
            total_count:0,
            total_pages:1
        })

        this.CertList()
    }
    ChangePage = async (page) => {
        if(page !== this.state.page)
        {
            await this.setState({
                page:page
            })

            this.CertList()
        }
    }
    GetParams = (name) => {
        const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
        var str = this.props.location.search
        const r = str.substr(1).match(reg);
        if (r != null) return  decodeURIComponent(r[2]); return null;
    }

    render()
    {
        let CertList = () => {
            let arr = []

            this.state.certlist.map((item, index) => {
                if(item.certification_id === this.state.certification_id)
                {
                    // arr.push(<div className="item active" key={index}>{item.name}</div>)
                    arr.splice(0,0,<div className="item active" key={index}>{item.name}</div>)
                }else
                {
                    arr.push(<div className="item" key={index}>{item.name}</div>)
                }
                
                return arr
            })

            return arr
        }

        return (
          <div id="cert">
            <Helmet>
              <title>专业培训</title>
              <meta
                name="keywords"
                content="培训报名，最新培训，Dream-IT婴幼儿语言沟通发育迟缓临床专业资格培训，Dream-C儿童语言发育障碍临床专业资格培训，培声儿童语言家庭培训师资格培训，培声证书查询"
              />
              <meta
                name="description"
                content="为更好提升专业人士的儿童言语-语言障碍的诊治水平，美国培声国内外团队参考北美儿童言语语言硕士课程，打造了Dream-IT婴幼儿语言沟通发育迟缓临床专业资格培训，Dream-C儿童语言发育障碍临床专业资格培训，培声儿童语言家庭培训师资格培训，MENT培训，以及针对早教老师的儿童语言发育教师课程。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img src="/assets/images/major/cert/banner.png" alt="banner" />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">{intl.getHTML("cert-banner-title")}</h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <Link className="link" to="/train">
                  <span>{intl.get("train-menu-title1")}</span>
                  <span>{intl.get("train-menu-desc1")}</span>
                </Link>
                <Link className="link" to="/cert">
                  <span>{intl.get("train-menu-title2")}</span>
                  <span>{intl.get("train-menu-desc2")}</span>
                </Link>
              </div>
            </Layout>

            {/* 岗位 */}
            <div className="certbox">
              <div className="certlist">
                <Tabs
                  className="tablist"
                  defaultActiveKey={this.state.Type}
                  tabPosition="left"
                  tabBarGutter={0}
                  animated={true}
                  onChange={this.ChangeCert}
                >
                  <TabPane
                    className="certpanel"
                    tab={intl.get("cert-certlist1-name")}
                    key="DREAM-C"
                  >
                    <div className="title">
                      {intl.get("cert-certlist1-title")}
                    </div>

                    <div className="certimg">
                      <img
                        src="/assets/images/major/cert/lesson2.jpg"
                        alt="cert"
                      />
                    </div>
                  </TabPane>
                  <TabPane
                    className="certpanel"
                    tab={intl.get("cert-certlist2-name")}
                    key="DREAM-IT"
                  >
                    <div className="title">
                      {intl.get("cert-certlist2-title")}
                    </div>

                    <div className="certimg">
                      <img
                        src="/assets/images/major/cert/lesson3.jpg"
                        alt="cert"
                      />
                    </div>
                  </TabPane>
                  {/* <TabPane className="certpanel" tab={intl.get('cert-certlist3-name')} key="DREAM-S">
                                <div className="title">{intl.get('cert-certlist3-title')}</div>

                                <div className="certimg">
                                    <img src="/assets/images/major/cert/lesson1.png" alt="cert" />
                                </div>
                            </TabPane> */}
                  <TabPane
                    className="certpanel"
                    tab={intl.get("cert-certlist4-name")}
                    key="家庭培训师"
                  >
                    <div className="title">
                      {intl.get("cert-certlist4-title")}
                    </div>

                    <div className="certimg">
                      <img
                        src="/assets/images/major/cert/lesson4.jpg"
                        alt="cert"
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>

            <Layout className="certContainer">
              <div className="personBox">
                <div className="title">
                  {this.state.Type}
                  {intl.get("cert-personBox-title")}
                </div>
                <div className="time">
                  {intl.get("cert-personBox-date")}：{this.state.date}&nbsp;
                  {intl.get("cert-personBox-total")} {this.state.total_count}{" "}
                  {intl.get("cert-personBox-people")}
                </div>
                <div className="personList">{CertList()}</div>

                <Pagination
                  className="pages"
                  current={this.state.page}
                  total={this.state.total_count}
                  pageSize={this.state.page_size}
                  onChange={this.ChangePage}
                />
              </div>
            </Layout>
          </div>
        );
    }
}