import React from 'react'
import ReactDOM from 'react-dom'
import device from "current-device"

import './index.less'
import App from './App'

import reportWebVitals from './reportWebVitals'
import { WindowsFilled } from '@ant-design/icons';

// 判断客户端是否为移动设备，注意这里使用current-device判断，ipad不被认为是移动设备
// if (device.mobile()){
//   switch(window.location.pathname){
//     case "/team": //tiana 需求部分印刷品上扫描二维码后跳转到移动版team页面
//       window.location="https://m.ourbethel.cn/lists/about/pid/7"
//       break
//     default:
//       window.location= "https://m.ourbethel.cn"
//   }
  
// }
// console.log(window.location)

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

reportWebVitals()
