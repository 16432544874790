// eslint-disable-next-line
import {fetchPost,fetchGet} from '../services/request'
export let hospital_locations = (data = {}) => {
    return fetchGet('/hospital_locations',data)
}

export let hospitals = (data = {}) => {
    return fetchGet('/hospitals',data)
}

export let news = (data = {}) => {
    return fetchGet('/news',data)
}

export let newsinfo = (id = '') => {
    return fetchGet(`/news/${id}`)
}