import React,{Component} from 'react'
// import intl from 'react-intl-universal'

import './Detail.less'

import { Layout, Modal} from 'antd'

// import * as article from '../../../api/article'
import * as home from '../../../api/home'


export default class Detail extends Component{
    constructor(props)
    {
        super(props)

        var id = this.props.location.query ? this.props.location.query.id : null
        var type = this.props.location.query ? this.props.location.query.type : null

        if(!id)
        {
            this.props.history.push({pathname:'/article', query:{ type: type}})
            return false
        }

        this.state = {
            id:id,
            type:type,
            content:'',
            title:'',
            video_id:'',
            videos:[],
            sub_title: undefined
        }
    }
    async componentDidMount()
    {
        if(this.state.type === 'course')
        {
            this.VideoInfo()
        }else
        {
            this.ArtInfo()
        }
    }
    componentWillUnmount() 
    {
        if(this.player){
          this.player.destroy();
        }
    }
    ArtInfo = async () => {
        var id = this.state.id

        home.newsinfo(id).then((success) => {
            if(success)
            {
                console.log('-----success', success)
                this.setState({
                    content:success.content,
                    title:success.title,
                    summary: success.summary,
                    d_type: success.type
                })
            }
        })

    }
    VideoInfo = async () => {
        var id = this.state.id

        home.newsinfo(id).then((success) => {
            if(success)
            {
                this.setState({
                    content:success.content,
                    title:success.title,
                    video_id:success.video_id
                })

                if(success.videos)
                {
                    this.setState({
                        videos:success.videos
                    })
                }

                if(!window.polyvPlayer){
                    this.loadScript('/assets/js/player.js')
                    .then(() =>{
                        this.loadPlayer();
                    });
                }else
                {
                    this.loadPlayer();
                }
            }
        })
    }
    loadPlayer() 
    {
        this.player = window.polyvPlayer({
          wrap: '.player',
          width: '100%',
          height: '100%',
          watchStartTime: 0,
          watchEndTime: 180,
          vid: this.state.video_id,
        });

        window.s2j_onPlayOver = function()
        {
            Modal.warning({
                title: '温馨提醒',
                // content: '下载App购买观看完整视频',
                content: (
                    <div className="ModalQrcode">
                        <div className="img">
                            <img src="/assets/images/common/code-android-ios.jpg" alt="android" />
                        </div>
                        <div className="name">下载App购买观看完整视频</div>
                    </div>
                ),
            });
        }
    }
    loadScript(src) {
        const headElement = document.head || document.getElementsByTagName('head')[0];
        const _importedScript = {};
    
        return new Promise((resolve, reject) => {
          if (src in _importedScript) {
            resolve();
            return;
          }
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.onerror = err => {
            headElement.removeChild(script);
            reject(new URIError(`The Script ${src} is no accessible.`));
          }
          script.onload = () => {
            _importedScript[src] = true;
            resolve();
          }
          headElement.appendChild(script);
          script.src = src;
        })
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        let Player = () => {
            var output = ``

            if(this.state.type === 'course')
            {
                output = <div className="player"></div>
            }else
            {
                output = ''
            }

            return output
        }

        return (
            <div id="detail">
                {/* banner图 */}
                <Layout className="SingleBanner">
                    <div className="img">
                        <img src="/assets/images/about/introduction/banner.png" alt="banner" />
                    </div>
                </Layout>


                {/* 详细内容 */}
                <Layout id="detailed">
                    <div className="explain">
                        <div className="title">
                            <h4>{this.state.title}</h4>
                        </div>
                        {
                            (this.state.d_type === 1 && this.state.summary) && <div className="author">
                            <h4>{this.state.summary}</h4>
                        </div>
                        }
                    </div>

                    {Player()}

                    <div className="content" dangerouslySetInnerHTML = {{ __html:this.state.content }}></div>
                </Layout>
            </div>
        )
    }
}