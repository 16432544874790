import React,{Component} from 'react'
// import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './Lecture.less'

import { Layout, Pagination} from 'antd';

import * as lecture from '../../api/lecture'

import moment from 'moment'


export default class Lecture extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            lecture:[],
            page:1,
            page_size:10,
            total_count:0,
            total_pages:1,
        }
    }
    async componentDidMount()
    {
        await this.setState({
            lecture:[],
            page:1,
            total_count:0,
            total_pages:1
        })

        this.Lecture()
    }
    ChangeType = async (type) => 
    {
        await this.setState({
            type:type,
            lecture:[],
            page:1,
            total_count:0,
            total_pages:1
        })

        this.Lecture()
    }
    //获取文章列表
    Lecture = async () => {
        var data = {
            page:this.state.page,
            page_size:this.state.page_size,
        }

        var type = this.state.type

        if(type)
        {
            data.type = type
        }   

        lecture.chairs(data).then((success) => {
            if(success.items.length)
            {
                this.setState({
                    lecture:success.items,
                    total_count:success.total_count,
                    total_pages:success.total_pages
                })
            }
        })
    }
    ChangePage = async (page) => {
        if(page !== this.state.page)
        {
            await this.setState({
                page:page
            })

            this.Lecture()
        }
    }
    //咨询查看详情
    toDetail(info, e)
    {
        this.props.history.push({pathname:'/lectureinfo', query:{ info: info}})
    }
    render()
    {
        let LecList = () => {
            let arr = []

            this.state.lecture.map((item, index) => {
                arr.push(
                    <div className="item" key={index} onClick={this.toDetail.bind(this, item)}>
                        <div className="left">
                            <div className="title">{item.title}</div>
                            <div className="time">{intl.get('lecture-list-time')}：{moment(item.start_at).format('YYYY/MM')}</div>
                            <div className="name">{intl.get('lecture-list-author')}：{item.guest}</div>
                            {/* <Link className="btn start" to="/home">未开始</Link> */}
                        </div>
                        <div className="right">
                            <div className="img">
                                <img src={item.cover_url} alt="lecture" />
                            </div>
                        </div>
                    </div>
                )
                return arr
            })

            return arr
        }

        return (
          <div id="lecture">
            <Helmet>
              <title>{intl.get('lecture-html-head-title')}</title>
              <meta
                name="keywords"
                content="科普讲座，语言发育讲座，语迟讲座，培声公益讲座"
              />
              <meta
                name="description"
                content="美国培声听力语言中心以提升中国儿童言语-语言障碍的诊治水平和公众意识为己任，与国际言语语言专家，行为学专家，儿童心理学专家，国内三甲医院儿科主任、主治医师，儿童发育相关领域的专家携手造福中国儿童，持续不断输出高质量的科普讲座和义诊。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img
                  src="/assets/images/major/lecture/banner.png"
                  alt="banner"
                />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">
                    {intl.getHTML("lecture-banner-title")}
                  </h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div onClick={() => this.ChangeType(null)} className="link">
                  <span>{intl.get("lecture-menu-title1")}</span>
                  <span>{intl.get("lecture-menu-desc1")}</span>
                </div>
                <div onClick={() => this.ChangeType("new")} className="link">
                  <span>{intl.get("lecture-menu-title2")}</span>
                  <span>{intl.get("lecture-menu-desc2")}</span>
                </div>
                <div onClick={() => this.ChangeType("past")} className="link">
                  <span>{intl.get("lecture-menu-title3")}</span>
                  <span>{intl.get("lecture-menu-desc3")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="lectureContainer">
              <div className="lectureList">
                {/* <div className="item">
                            <div className="left">
                                <div className="title">刘雪曼教授：沟通、语言、言语三管齐下，全方位鉴别儿童语言障碍</div>
                                <div className="time">举办时间：2021/2/11</div>
                                <div className="name">演讲嘉宾：刘雪曼 教授</div>
                                <Link className="btn start" to="/home">未开始</Link>
                            </div>
                            <div className="right">
                                <div className="img">
                                    <img src="/assets/images/major/lecture/lecture1.png" alt="lecture" />
                                </div>
                            </div>
                        </div> */}

                {LecList()}
              </div>

              <div className="pagebox">
                <Pagination
                  className="pages"
                  current={this.state.page}
                  total={this.state.total_count}
                  pageSize={this.state.page_size}
                  onChange={this.ChangePage}
                  showSizeChanger={false}
                />
              </div>
            </Layout>
          </div>
        );
    }
}