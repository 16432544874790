import React,{Component} from 'react'

import './Dream.less'
import intl from 'react-intl-universal'

import { Layout} from 'antd';

import { Helmet } from 'react-helmet'


export default class Dream extends Component{
    constructor(props)
    {
        super(props)

        // var TimeLine2021 = 'item right Y2021'

        // if(localStorage.getItem('lang') === 'en-US')
        // {
        //     TimeLine2021 = 'item right Y2021 TimeLine2021'
        // }

        this.state = {
            // TimeLine2021:TimeLine2021
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
            <div id="dream">
                <Helmet>
                    <title>Dream培训</title>
                    <meta name="keywords" content="美国培声听力语言中心-致力于推动中国言语语言病理学发展，助千万儿童实现沟通梦想；培声，儿童语言康复，儿童语言干预，儿童语言发育障碍，婴幼儿语言测试，语迟，认知障碍，听力障碍，学习障碍，语音障碍，自闭症谱系，儿童听力语言沟通中心，培声集团，海南博鳌培声国际医学中心，海南博鳌培声沟通障碍医学中心" />
                    <meta name="description" content="美国培声听力语言中心是一所汇集权威言语语言病理学、听力学、心理语言学、儿科医学、康复学、家庭学等跨专业国际专家团队的临床、科研与培训机构。致力于为语言发育迟缓，语言障碍、言语障碍、听力障碍、认知障碍，听力障碍，学习障碍，自闭症谱系和具有其他发育障碍或精神发育疾病的孩子，以及医疗、康复、教育和科研领域专业人士提供专业的儿童语言干预康复和培训，从而提升中国儿童言语-语言障碍的诊治水平和公众意识，并专注于促进儿童语言发展的系统家长辅导和教师培训，从而在儿童家庭和幼儿园/学校中科学地提高儿童早期语言能力。" />
                </Helmet>

                {/* banner图 */}
                <Layout className="SingleBanner">
                    <div className="img">
                        <img src="/assets/images/train/dream/banner.jpg" alt="banner" />
                    </div>
                    <div className="box">
                        <div className="content">
                            <h3 className="title">{intl.getHTML('dream-banner-title')}</h3>
                        </div>
                    </div>
                </Layout>

                {/* 导航 */}
                <Layout className="menulist">
                    <div className="navlist">
                        <div onClick={()=>this.scrollToAnchor('introduction')} className="link">
                            <span>{intl.get('dream-menulist1-zh')}</span>
                            <span>{intl.get('dream-menulist1-en')}</span>
                        </div>
                        <div onClick={()=>this.scrollToAnchor('fit')} className="link">
                            <span>{intl.get('dream-menulist2-zh')}</span>
                            <span>{intl.get('dream-menulist2-en')}</span>
                        </div>
                        <div onClick={()=>this.scrollToAnchor('curriculum')} className="link">
                            <span>{intl.get('dream-menulist3-zh')}</span>
                            <span>{intl.get('dream-menulist3-en')}</span>
                        </div>
                        <div onClick={()=>this.scrollToAnchor('expert')} className="link">
                            <span>{intl.get('dream-menulist4-zh')}</span>
                            <span>{intl.get('dream-menulist4-en')}</span>
                        </div>
                        <div onClick={()=>this.scrollToAnchor('student')} className="link">
                            <span>{intl.get('dream-menulist5-zh')}</span>
                            <span>{intl.get('dream-menulist5-en')}</span>
                        </div>
                        <div onClick={()=>this.scrollToAnchor('signup')} className="link">
                            <span>{intl.get('dream-menulist6-zh')}</span>
                            <span>{intl.get('dream-menulist6-en')}</span>
                        </div>
                    </div>
                </Layout>
                
                <Layout className="childrenContainer">
                    <div className="modular">
                        {/* 关于培训 */}
                        <div id="introduction">
                            <div className="explain">
                                <div className="title">
                                    <h4>{intl.get('dream-introduction-explain-title')}</h4>
                                </div>
                                <div className="desc">
                                    {intl.get('dream-introduction-explain-desc')}
                                </div>
                            </div>

                            <div className="describe">
                                {intl.get('dream-introduction-explain-describe')}
                            </div>

                            <div className="list">
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/icon1.png" alt="img" />
                                    </div>
                                    <p className="desc">
                                        {intl.get('dream-introduction-list1')}
                                    </p>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/icon2.png" alt="img" />
                                    </div>
                                    <p className="desc">
                                        {intl.get('dream-introduction-list2')}
                                    </p>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/icon3.png" alt="img" />
                                    </div>
                                    <p className="desc">
                                        {intl.get('dream-introduction-list3')}
                                    </p>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/icon4.png" alt="img" />
                                    </div>
                                    <p className="desc">
                                        {intl.get('dream-introduction-list4')}
                                    </p>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/icon5.png" alt="img" />
                                    </div>
                                    <p className="desc">
                                        {intl.get('dream-introduction-list5')}
                                    </p>
                                </div>
                            </div>

                            <div className="content">
                                {intl.getHTML('dream-introduction-explain-content')}
                            </div>
                        </div>

                        {/* 适合对象 */}
                        <div id="fit">
                            <div className="explain">
                                <div className="title">
                                    <h4>{intl.get('dream-fit-explain-title')}</h4>
                                </div>
                                <div className="desc">
                                    {intl.get('dream-fit-explain-desc')}
                                </div>
                            </div>

                            <div className="teacher">
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/teacher1.png" alt="img" />
                                    </div>
                                    <div className="name">
                                        {intl.get('dream-fit-teacher1')}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/teacher2.png" alt="img" />
                                    </div>
                                    <div className="name">
                                        {intl.get('dream-fit-teacher2')}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/teacher3.png" alt="img" />
                                    </div>
                                    <div className="name">
                                        {intl.get('dream-fit-teacher3')}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src="/assets/images/train/dream/teacher4.png" alt="img" />
                                    </div>
                                    <div className="name">
                                        {intl.get('dream-fit-teacher4')}
                                    </div>
                                </div>
                            </div>

                            <div className="tablist">
                                <div className="title">
                                    <h4>{intl.get('dream-fit-tablist-title')}</h4> 
                                </div>

                                {intl.getHTML('dream-fit-tablist-list')}
                            </div>
                        </div>

                        {/* 课程内容 */}
                        <div id="curriculum">
                            <div className="explain">
                                <div className="title">
                                    <h4>{intl.get('dream-curriculum-explain-title')}</h4>
                                </div>
                                <div className="desc">
                                    {intl.get('dream-curriculum-explain-desc')}
                                </div>
                            </div>

                            <div className="tablist">
                                <div className="title">
                                    <h4>{intl.get('dream-curriculum-tablist1-title')}</h4> 
                                </div>

                                <ol className="list">
                                   {intl.getHTML('dream-curriculum-tablist1-list')}
                                </ol>

                                <div className="title">
                                    <h4>{intl.get('dream-curriculum-tablist2-title')}</h4> 
                                </div>

                                <ol className="list">
                                   {intl.getHTML('dream-curriculum-tablist2-list')}
                                </ol>
                            </div>
                        </div>

                        {/* 授课专家 */}
                        <div id="expert">
                            <div className="explain">
                                <div className="title">
                                    <h4>{intl.get('dream-expert-explain-title')}</h4>
                                </div>
                                <div className="desc">
                                    {intl.get('dream-expert-explain-desc')}
                                </div>
                            </div>

                            <div className="tablist">
                                <div className="title">
                                    <h4>{intl.get('dream-tablist-title')}</h4> 
                                </div>

                                <div className="list">
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert1.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist1-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist1-info')}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert2.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist2-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist2-info')}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert3.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist3-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist3-info')}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert4.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist4-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist4-info')}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert5.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist5-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist5-info')}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert6.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist6-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist6-info')}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert7.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist7-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist7-info')}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="background">
                                            <img src="/assets/images/train/dream/expert8.png" alt="expert" />
                                        </div>
                                        <div className="box">
                                            <div className="name">{intl.get('dream-tablist8-name')}</div>
                                            <ul className="info">
                                                {intl.getHTML('dream-tablist8-info')}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 学员反馈 */}
                        <div id="student">
                            <div className="explain">
                                <div className="title">
                                    <h4>{intl.get('dream-student-explain-title')}</h4>
                                </div>
                                <div className="desc">
                                    {intl.get('dream-student-explain-desc')}
                                </div>
                            </div>

                            <div className="tablist">
                                <div className="item">
                                    <div className="background">
                                        <img src="/assets/images/train/dream/student1.png" alt="student" />
                                    </div>
                                    <div className="box">
                                        {intl.get('dream-student-tablist-box1')}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="background">
                                        <img src="/assets/images/train/dream/student2.png" alt="student" />
                                    </div>
                                    <div className="box">
                                        {intl.get('dream-student-tablist-box2')}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="background">
                                        <img src="/assets/images/train/dream/student3.png" alt="student" />
                                    </div>
                                    <div className="box">
                                        {intl.get('dream-student-tablist-box3')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 报名方法 */}
                        <div id="signup">
                            <div className="left">
                                <div className="title">
                                    <h2>{intl.get('dream-signup-title-zh')}</h2>
                                    <span>{intl.get('dream-signup-title-en')}</span>
                                </div>
                                
                                <div className="desc">{intl.get('dream-signup-desc')}</div>

                                <div className="qrcode">
                                    <img src="/assets/images/train/dream/qrcode.png" alt="qrcode" />
                                </div>
                            </div>
                            <div className="right">
                                <img src="/assets/images/train/dream/appback.jpg" alt="appback" />
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        )
    }
}