import React,{Component} from 'react'
import intl from 'react-intl-universal'

import './Introduction.less'

import { Layout} from 'antd'

import { Helmet } from 'react-helmet'


export default class Introduction extends Component{
    constructor(props)
    {
        super(props)

        var TimeLine2021 = 'item right Y2021'

        if(localStorage.getItem('lang') === 'en-US')
        {
            TimeLine2021 = 'item right Y2021 TimeLine2021'
        }

        this.state = {
            TimeLine2021:TimeLine2021
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="introduction">
            <Helmet>
              <title>{intl.get("introduction-html-head-title")}</title>
              <meta
                name="keywords"
                content="关于培声，培声介绍，培声怎么样，培声理念，培声在哪里"
              />
              <meta
                name="description"
                content="美国培声听力语言中心于2011年在美国达拉斯成立研发中心， 2014年进入中国上海，在浦东新区北园路92号成立首家线下中心服务上海及周边城市。公司坚守REACH（Respect尊重，Excellence 卓越，Awareness 认知，Compassion 关爱，Honesty诚信）理念，旨在促进与提升医疗专业人士、教育专业人士、家长、等各界人士对言语语言病理及相关领域的意识和认知，营造一个人人都能关爱、支持、引导和激励他人的大家庭，通过汇聚和传递爱心，为患者带来治疗与康复。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img
                  src="/assets/images/about/introduction/banner.png"
                  alt="banner"
                />
              </div>
              <div
                className="box"
                style={
                  intl.options.currentLocale === "en-US"
                    ? { top: "12em" }
                    : { top: "17em" }
                }
              >
                <div className="content">
                  <h3
                    className="title"
                    style={
                      intl.options.currentLocale === "en-US"
                        ? { fontSize: "40px" }
                        : { fontSize: "45px" }
                    }
                  >
                    {intl.getHTML("introduction-banner-title")}
                  </h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("introduction-menulist1-zh")}</span>
                  <span>{intl.get("introduction-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("values")}
                  className="link"
                >
                  <span>{intl.get("introduction-menulist2-zh")}</span>
                  <span>{intl.get("introduction-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("history")}
                  className="link"
                >
                  <span>{intl.get("introduction-menulist3-zh")}</span>
                  <span>{intl.get("introduction-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("culture")}
                  className="link"
                >
                  <span>{intl.get("introduction-menulist4-zh")}</span>
                  <span>{intl.get("introduction-menulist4-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="comlist">
              {/* 关于培声 */}
              <div id="introduction">
                <div className="explain">
                  <div className="title">
                    <h4>{intl.get("introduction-explain-title")}</h4>
                  </div>
                  <div className="desc">
                    {intl.get("introduction-explain-desc")}
                  </div>
                </div>

                <div
                  className="card"
                  style={{
                    backgroundImage: `url(/assets/images/about/introduction/introductionbg.jpg)`,
                  }}
                >
                  <div className="avatar">
                    <img
                      src="/assets/images/about/introduction/introduction-logo.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="info">
                    {intl.get("introduction-card-info")}
                  </div>
                </div>

                <div className="describe">
                  {intl.get("introduction-card-describe")}
                </div>

                <div className="list">
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/about/introduction/icon1.png"
                        alt="img"
                      />
                    </div>
                    <div className="title">
                      {intl.get("introduction-card-list1-title")}
                    </div>
                    <p className="desc">
                      {intl.get("introduction-card-list1-desc")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/about/introduction/icon2.png"
                        alt="img"
                      />
                    </div>
                    <div className="title">
                      {intl.get("introduction-card-list2-title")}
                    </div>
                    <p className="desc">
                      {intl.get("introduction-card-list2-desc")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/about/introduction/icon3.png"
                        alt="img"
                      />
                    </div>
                    <div className="title">
                      {intl.get("introduction-card-list3-title")}
                    </div>
                    <p className="desc">
                      {intl.get("introduction-card-list3-desc")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/about/introduction/icon4.png"
                        alt="img"
                      />
                    </div>
                    <div className="title">
                      {intl.get("introduction-card-list4-title")}
                    </div>
                    <p className="desc">
                      {intl.get("introduction-card-list4-desc")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/about/introduction/icon5.png"
                        alt="img"
                      />
                    </div>
                    <div className="title">
                      {intl.get("introduction-card-list5-title")}
                    </div>
                    <p className="desc">
                      {intl.get("introduction-card-list5-desc")}
                    </p>
                  </div>
                </div>
              </div>
            </Layout>

            {/* 培声的企业价值观 */}
            <Layout id="values">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("introduction-values-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("introduction-values-explain-desc")}
                </div>
              </div>

              <div className="list">
                <div className="item">
                  <div className="left">
                    <div className="title">
                      <div className="en">
                        {intl.get("introduction-list1-title-en")}
                      </div>
                      <div className="zh">
                        {intl.get("introduction-list1-title-zh")}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    {intl.get("introduction-list1-right")}
                  </div>
                </div>
                <div className="item">
                  <div className="left">
                    <div className="title">
                      <div className="en">
                        {intl.get("introduction-list2-title-en")}
                      </div>
                      <div className="zh">
                        {intl.get("introduction-list2-title-zh")}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    {intl.get("introduction-list2-right")}
                  </div>
                </div>
                <div className="item">
                  <div className="left">
                    <div className="title">
                      <div className="en">
                        {intl.get("introduction-list3-title-en")}
                      </div>
                      <div className="zh">
                        {intl.get("introduction-list3-title-zh")}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    {intl.get("introduction-list3-right")}
                  </div>
                </div>
                <div className="item">
                  <div className="left">
                    <div className="title">
                      <div className="en">
                        {intl.get("introduction-list4-title-en")}
                      </div>
                      <div className="zh">
                        {intl.get("introduction-list4-title-zh")}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    {intl.get("introduction-list4-right")}
                  </div>
                </div>
                <div className="item">
                  <div className="left">
                    <div className="title">
                      <div className="en">
                        {intl.get("introduction-list5-title-en")}
                      </div>
                      <div className="zh">
                        {intl.get("introduction-list5-title-zh")}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    {intl.get("introduction-list5-right")}
                  </div>
                </div>
              </div>
            </Layout>

            {/* 培声大事记 */}
            <Layout id="history">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("introduction-history-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("introduction-history-explain-desc")}
                </div>
              </div>

              {/* 时间线 */}
              <div className="timeline">
                <ul>
                  <li className="item left Y2011">
                    <div className="box">
                      <ul>
                        <li>{intl.get("introduction-timeline-Y2011-list1")}</li>
                        <li>{intl.get("introduction-timeline-Y2011-list2")}</li>
                      </ul>
                    </div>
                    <div className="line">
                      <span className="year">2011</span>
                    </div>
                  </li>
                  <li className="item right Y2014">
                    <div className="line">
                      <span className="year">2014</span>
                    </div>
                    <div className="box">
                      <ul>
                        <li>{intl.get("introduction-timeline-Y2014-list1")}</li>
                        <li>{intl.get("introduction-timeline-Y2014-list2")}</li>
                        <li>
                          <div className="imgtext">
                            <span>
                              {intl.get("introduction-timeline-Y2014-list3")}
                            </span>
                            <span className="img">
                              <img
                                src="/assets/images/about/introduction/timeline1.png"
                                alt="img"
                              />
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="item left Y2016">
                    <div className="box">
                      <ul>
                        <li>{intl.get("introduction-timeline-Y2016-list1")}</li>
                        <li>{intl.get("introduction-timeline-Y2016-list2")}</li>
                      </ul>
                    </div>
                    <div className="line">
                      <span className="year">2016</span>
                    </div>
                  </li>
                  <li className="item right Y2018">
                    <div className="line">
                      <span className="year">2018</span>
                    </div>
                    <div className="box">
                      <ul>
                        <li>{intl.get("introduction-timeline-Y2018-list1")}</li>
                        <span className="img">
                          <img
                            src="/assets/images/about/introduction/timeline2.png"
                            alt="img"
                          />
                        </span>
                        <li>
                          <div className="imgtext">
                            <span>
                              {intl.get("introduction-timeline-Y2018-list2")}
                            </span>
                          </div>
                        </li>
                        <li>
                          {intl.getHTML("introduction-timeline-Y2018-list3")}
                        </li>
                        <span className="img">
                          <img
                            src="/assets/images/about/introduction/timeline3.png"
                            alt="img"
                          />
                        </span>
                      </ul>
                    </div>
                  </li>
                  <li className="item left Y2019">
                    <div className="box">
                      <ul>
                        <li>
                          <div className="imgtext">
                            <span>
                              {intl.get("introduction-timeline-Y2019-list1")}
                            </span>
                            <span className="img">
                              <img
                                src="/assets/images/about/introduction/timeline4.png"
                                alt="img"
                              />
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="imgtext">
                            <span>
                              {intl.get("introduction-timeline-Y2019-list2")}
                            </span>
                            <span className="img"></span>
                          </div>
                        </li>
                        <li>
                          <div className="imgtext">
                            <span>
                              {intl.get("introduction-timeline-Y2019-list3")}
                            </span>
                            <span className="img">
                              <img
                                src="/assets/images/about/introduction/timeline5.png"
                                alt="img"
                              />
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="line">
                      <span className="year">2019</span>
                    </div>
                  </li>
                  <li className="item right Y2020">
                    <div className="line">
                      <span className="year">2020</span>
                    </div>
                    <div className="box">
                      <ul>
                        <li>{intl.get("introduction-timeline-Y2020-list1")}</li>
                        <li>
                          {intl.getHTML("introduction-timeline-Y2020-list2")}
                        </li>
                        {intl.getHTML("introduction-timeline-Y2020-list3")?<li>{intl.get("introduction-timeline-Y2020-list3")}</li>:""}
                        
                      </ul>
                    </div>
                  </li>
                  <li className="item right Y2021">
                    <div className="line">
                      <span className="year">2021</span>
                    </div>
                    <div className="box">
                      <ul>
                        <li>
                          <div className="imgtext">
                            <span>
                              {intl.get("introduction-timeline-Y2021-list1")}
                            </span>
                            <span className="img">
                              <img
                                src="/assets/images/about/introduction/timeline6.png"
                                alt="img"
                              />
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="imgtext">
                            <span>
                              {intl.get("introduction-timeline-Y2021-list2")}
                            </span>
                            <span className="img">
                              <img
                                src="/assets/images/about/introduction/timeline7.png"
                                alt="img"
                              />
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </Layout>

            {/* 培声起源 */}
            <Layout id="culture">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("introduction-culture-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("introduction-culture-explain-desc")}
                </div>
              </div>

              <div className="content">
                {intl.getHTML("introduction-culture-explain-content")}
              </div>
            </Layout>
          </div>
        );
    }
}