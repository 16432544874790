import React, { Component } from "react";
// import {Link} from 'react-router-dom'
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";

import "./Team.less";

import { Layout } from "antd";

export default class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newskey: "en", //en zh asha
    };
  }
  tabs(e, action) {
    this.setState({ newskey: action });
  }
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  render() {
    return (
      <div id="team">
        <Helmet>
          <title>{intl.get("team-html-head-title")}</title>
          <meta
            name="keywords"
            content="培声专家，海外专家，北美专家，培声专家团队"
          />
          <meta
            name="description"
            content="美国培声科研团队来自美国哈佛大学，美国史密斯大学，美国德州大学达拉斯分校等高校，汇集了言语语言病理学、听力学、心理语言学、心理统计学、儿科医学等跨学科专家。培声专家团队由北美和英国认证的言语语言病理师组成，平均临床时间不低于3000+小时。"
          />
        </Helmet>

        {/* banner图 */}
        <Layout className="SingleBanner">
          <div className="img">
            <img src="/assets/images/about/team/banner.png" alt="banner" />
          </div>
          <div className="box">
            <div className="content">
              <h3 className="title">{intl.getHTML("team-banner-title")}</h3>
            </div>
          </div>
        </Layout>

        {/* 导航 */}
        <Layout className="menulist">
          <div className="navlist">
            <div
              onClick={() => this.scrollToAnchor("scientific")}
              className="link"
            >
              <span>{intl.get("team-menulist1-zh")}</span>
              <span>{intl.get("team-menulist1-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("expert")} className="link">
              <span>{intl.get("team-menulist2-zh")}</span>
              <span>{intl.get("team-menulist2-en")}</span>
            </div>
          </div>
        </Layout>

        <Layout className="comlist">
          {/* 美国培声旗下优秀科研团队 */}
          <div id="scientific">
            <div className="explain">
              <div className="title">
                <h4>{intl.get("team-explain-title")}</h4>
              </div>
              <div className="desc">{intl.get("team-menulist1-en")}</div>
            </div>

            <div className="box">
              <div className="img">
                <img
                  src="/assets/images/about/team/scientificimg.png"
                  alt="img"
                />
              </div>

              <div
                className={
                  intl.options.currentLocale === "en-US"
                    ? "content-en"
                    : "content-zh"
                }
              >
                {intl.getHTML("team-explain-content")}
              </div>
            </div>

            {/* 科研团队 */}
            <div className="teamlist">
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team5.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist5-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist5-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.get("team-teamlist5-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team1.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist1-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist1-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist1-describe")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team4.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist4-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist4-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist4-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team6.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist6-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist6-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist6-describe")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team22.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist22-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist22-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team7.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist7-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist7-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team8.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist8-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist8-list")}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team3.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist3-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist3-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist3-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team2.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist2-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist2-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist2-describe")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team17.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist17-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist17-list")}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div id="expert" className="explain">
              <div className="title">
                <h4>{intl.get("team-expert-explain-title")}</h4>
              </div>
              <div className="desc">{intl.get("team-expert-explain-desc")}</div>

              <div className="content">
                {intl.getHTML("team-expert-explain-content")}
                <br />
              </div>
            </div>

            {/* 专家团队 */}
            <div className="teamlist">
              {/* 

                            <div className="item">
                                <div className="img">
                                    <img src="/assets/images/about/team/team3.png" alt="img" />
                                </div>
                                <div className="introduce">
                                    <div className="name">{intl.get('team-teamlist10-name')}</div>
                                    <div className="content">
                                        <ul className="achievement">
                                            {intl.getHTML('team-teamlist10-list')}    
                                        </ul>

                                        <h4 className="title">{intl.get('team-teamlist-title')}</h4>

                                        <p className="describe">
                                            {intl.getHTML('team-teamlist10-describe')}    
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="img">
                                    <img src="/assets/images/about/team/team2.png" alt="img" />
                                </div>
                                <div className="introduce">
                                    <div className="name">{intl.get('team-teamlist11-name')}</div>
                                    <div className="content">
                                        <ul className="achievement">
                                            {intl.getHTML('team-teamlist11-list')}    
                                        </ul>

                                        <h4 className="title">{intl.get('team-teamlist-title')}</h4>

                                        <p className="describe">
                                            {intl.getHTML('team-teamlist11-describe')}    
                                        </p>
                                    </div>
                                </div>
                            </div> */}
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team1.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist9-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist9-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist9-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team2.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist2-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist2-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist2-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team3.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist3-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist3-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist3-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team9.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist12-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist12-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist12-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team10.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist13-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist13-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist13-describe")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team11.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist14-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist14-list")}
                    </ul>

                    <h4 className="title">{intl.get("team-teamlist-title")}</h4>

                    <p className="describe">
                      {intl.getHTML("team-teamlist14-describe")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team31.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist31-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist31-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team32.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist32-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist32-list")}
                    </ul>
                    {intl.get("team-teamlist32-describe") ? (
                      <h4 className="title">
                        {intl.get("team-teamlist-title")}
                      </h4>
                    ) : (
                      ""
                    )}
                    <p className="describe">
                      {intl.getHTML("team-teamlist32-describe")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team33.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist33-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist33-list")}
                    </ul>
                    {intl.get("team-teamlist32-describe") ? (
                      <h4 className="title">
                        {intl.get("team-teamlist-title")}
                      </h4>
                    ) : (
                      ""
                    )}
                    <p className="describe">
                      {intl.getHTML("team-teamlist33-describe")}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/about/team/team13.png"
                        alt="img"
                      />
                    </div> */}
              {/* <div className="introduce">
                      <div className="name">
                        {intl.get("team-teamlist16-name")}
                      </div>
                      <div className="content">
                        <ul className="achievement">
                          {intl.getHTML("team-teamlist16-list")}
                        </ul>

                        <h4 className="title">
                          {intl.get("team-teamlist-title")}
                        </h4>

                        <p className="describe">
                          {intl.getHTML("team-teamlist16-describe")}
                        </p>
                      </div>
                    </div> */}
              {/* </div> */}
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team20.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist20-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist20-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team26.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist26-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist26-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team27.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist27-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist27-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team30.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist30-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist30-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team29.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist29-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist29-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team21.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist21-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist21-list")}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team22.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist22-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist22-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team7.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist7-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist7-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team8.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist8-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist8-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team17.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist17-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist17-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team23.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist23-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist23-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team24.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist24-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist24-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team25.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist25-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist25-list")}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/assets/images/about/team/team28.png" alt="img" />
                </div>
                <div className="introduce">
                  <div className="name">{intl.get("team-teamlist28-name")}</div>
                  <div className="content">
                    <ul className="achievement">
                      {intl.getHTML("team-teamlist28-list")}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* 地图位置 */}
            <div className="position">
              <div className="img">
                <img src={intl.get("team-map-path")} alt="img" />
              </div>

              <div className="expertlist">
                {/* row 1 column 1 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall1.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist1-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist1-local")}
                    </div>
                  </div>
                </div>
                {/* row 1 column 2 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall11.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist11-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist11-local")}
                    </div>
                  </div>
                </div>
                {/* row 1 column 3 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall2.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist2-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist2-local")}
                    </div>
                  </div>
                </div>
                {/* row 1 column 4 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall3.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist3-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist3-local")}
                    </div>
                  </div>
                </div>
                {/* row 2column 1 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall4.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist4-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist4-local")}
                    </div>
                  </div>
                </div>
                {/* row 2 column 2 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall13.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist13-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist13-local")}
                    </div>
                  </div>
                </div>
                {/* row 2 column 3 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall17.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist17-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist17-local")}
                    </div>
                  </div>
                </div>
                {/* row 2 column 4 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall6.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist6-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist6-local")}
                    </div>
                  </div>
                </div>
                {/* row 3 column 1 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall7.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist7-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist7-local")}
                    </div>
                  </div>
                </div>
                {/* row 3 column 2 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall14.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist14-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist14-local")}
                    </div>
                  </div>
                </div>
                {/* row 3 column 3*/}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall18.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist18-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist18-local")}
                    </div>
                  </div>
                </div>
                {/* row 3 column 4 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall19.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist19-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist19-local")}
                    </div>
                  </div>
                </div>
                {/* row 4 column 1 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall9.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist9-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist9-local")}
                    </div>
                  </div>
                </div>
                {/* row 4 column 2 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall15.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist15-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist15-local")}
                    </div>
                  </div>
                </div>
                {/* row 4 column 3 */}
                <div className="item"></div>
                {/* row 4 column 4 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall20.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist20-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist20-local")}
                    </div>
                  </div>
                </div>
                {/* row 5 column 1 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall10.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist10-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist10-local")}
                    </div>
                  </div>
                </div>
                {/* row 5 column 2 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall16.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist16-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist16-local")}
                    </div>
                  </div>
                </div>
                {/* row 5 column 3 */}
                <div className="item"></div>
                {/* row 5 column 4 */}
                <div className="item">
                <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall23.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist22-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist22-local")}
                    </div>
                  </div>
                </div>
                {/* row 6 column 1 */}
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall21.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist21-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist21-local")}
                    </div>
                  </div>
                </div>
                {/* row 6 column 2 */}
                <div className="item">
                <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall24.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist23-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist23-local")}
                    </div>
                  </div>
                </div>
                {/* row 6 column 3 */}
                <div className="item"></div>
                {/* row 6 column 4 */}
                <div className="item">
                  
                </div>
                {/* row 7 column 1 */}
                <div className="item">
                <div className="img">
                    <img
                      src="/assets/images/about/team/teamsmall22.png"
                      alt="img"
                    />
                  </div>
                  <div className="info">
                    <div className="name">
                      {intl.get("team-position-expertlist24-name")}
                    </div>
                    <div className="local">
                      {intl.get("team-position-expertlist24-local")}
                    </div>
                  </div>
                </div>
                {/* row 7 column 2 */}
                <div className="item"></div>
                {/* row 7 column 3 */}
                <div className="item"></div>
                {/* row 7 column 4 */}
                <div className="item">
                  
                </div>
                {/* row 8 column 1 */}
                <div className="item"></div>
                {/* row 8 column 2 */}
                <div className="item"></div>
                {/* row 8 column 3 */}
                <div className="item"></div>
                {/* row 8 column 4 */}
                <div className="item">
                  
                </div>
                {/* <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div> */}
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}
