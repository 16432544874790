import React,{Component} from 'react'
// import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'

import './DreamFam.less'

import { Layout} from 'antd';

import { Helmet } from 'react-helmet'


const MsgStyle = {
    background:`url(/assets/images/train/dreamfam/message.png) no-repeat left top`,
    backgroundSize:`100% 100%`
}


export default class DreamFam extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="dreamfam">
            <Helmet>
              <title>{intl.get("dreamfam-html-head-title")}</title>
              <meta
                name="keywords"
                content="儿童语言家庭培训，儿童语言家庭干预，家庭语言干预，早期家庭干预"
              />
              <meta
                name="description"
                content="美国培声儿童语言家庭培训师资格培训由北美和英国认证的双语言语语言病理师团队授课，培训内容根据北美循证教育理念设计，赋能儿保同仁：快速准确地识别面临语言沟通落后的0-3岁婴幼儿；掌握儿童语言早期干预的科学原则和技巧；给予家长科学有效的建议和指导。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img src="/assets/images/train/dream/banner.jpg" alt="banner" />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">
                    {intl.getHTML("dreamfam-banner-title")}
                  </h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("dreamfam-menulist1-zh")}</span>
                  <span>{intl.get("dreamfam-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("fit")}
                  className="link"
                >
                  <span>{intl.get("dreamfam-menulist2-zh")}</span>
                  <span>{intl.get("dreamfam-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("curriculum")}
                  className="link"
                >
                  <span>{intl.get("dreamfam-menulist3-zh")}</span>
                  <span>{intl.get("dreamfam-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("highlights")}
                  className="link"
                >
                  <span>{intl.get("dreamfam-menulist4-zh")}</span>
                  <span>{intl.get("dreamfam-menulist4-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("expert")}
                  className="link"
                >
                  <span>{intl.get("dreamfam-menulist5-zh")}</span>
                  <span>{intl.get("dreamfam-menulist5-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("student")}
                  className="link"
                >
                  <span>{intl.get("dreamfam-menulist6-zh")}</span>
                  <span>{intl.get("dreamfam-menulist6-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("signup")}
                  className="link"
                >
                  <span>{intl.get("dreamfam-menulist7-zh")}</span>
                  <span>{intl.get("dreamfam-menulist7-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="childrenContainer">
              <div className="modular">
                {/* 关于培训 */}
                <div id="introduction">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamfam-introduction-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamfam-introduction-explain-desc")}
                    </div>
                  </div>

                  <div className="describe">
                    {intl.get("dreamfam-introduction-explain-describe")}
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon1.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamfam-introduction-list1")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon2.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamfam-introduction-list2")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon3.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamfam-introduction-list3")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* 适合对象 */}
                <div id="fit">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamfam-fit-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamfam-fit-explain-desc")}
                    </div>
                  </div>

                  <div className="teacher">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher1.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-fit-teacher1")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher2.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-fit-teacher2")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher3.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-fit-teacher3")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher4.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-fit-teacher4")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher5.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-fit-teacher5")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher6.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-fit-teacher6")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher7.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-fit-teacher7")}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 课程内容 */}
                <div id="curriculum">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamfam-curriculum-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamfam-curriculum-explain-desc")}
                    </div>
                  </div>

                  <div className="tablist">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon4.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-curriculum-tablist1-name")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-curriculum-tablist1-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon5.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-curriculum-tablist2-name")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-curriculum-tablist2-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon6.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-curriculum-tablist3-name")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-curriculum-tablist3-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon7.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-curriculum-tablist4-name")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-curriculum-tablist4-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon8.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-curriculum-tablist5-name")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-curriculum-tablist5-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/icon9.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamfam-curriculum-tablist6-name")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-curriculum-tablist6-info")}
                      </div>
                    </div>
                  </div>

                  <div className="cert">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamfam/cert.png"
                        alt="img"
                      />
                    </div>
                    <div className="info">
                      {intl.get("dreamfam-curriculum-cert-info")}
                    </div>
                  </div>
                </div>

                {/* 课程亮点 */}
                <div id="highlights">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamfam-highlights-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamfam-highlights-explain-desc")}
                    </div>
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="number">
                        <span>01</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamfam-highlights-list1-title")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-highlights-list1-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>02</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamfam-highlights-list2-title")}
                      </div>
                      <div className="info">
                        <ul className="lists">
                          {intl.getHTML("dreamfam-highlights-list2-info")}
                        </ul>
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>03</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamfam-highlights-list3-title")}
                      </div>
                      <div className="info">
                        <ul className="lists">
                          {intl.getHTML("dreamfam-highlights-list3-info")}
                        </ul>
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>04</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamfam-highlights-list4-title")}
                      </div>
                      <div className="info">
                        {intl.get("dreamfam-highlights-list4-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>05</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamfam-highlights-list5-title")}
                      </div>
                      <div className="info">
                        <ul className="lists">
                          {intl.getHTML("dreamfam-highlights-list5-info")}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 授课专家 */}
                <div id="expert">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamfam-expert-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamfam-expert-explain-desc")}
                    </div>
                  </div>

                  <div className="tablist">
                    <div className="title">
                      <h4>{intl.get("dreamfam-expert-tablist-title")}</h4>
                    </div>

                    <div className="list">
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert1.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list1-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list1-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert2.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list2-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list2-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert3.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list3-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list3-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert4.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list4-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list4-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert5.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list5-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list5-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert6.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list6-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list6-info")}
                          </ul>
                        </div>
                      </div>
                      {/* <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert7.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list7-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list7-info")}
                          </ul>
                        </div>
                      </div> */}
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert12.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list10-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML(
                              "dreamfam-expert-tablist-list10-info"
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert8.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list8-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list8-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert11.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list11-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list11-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert16.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list16-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list16-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert17.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list15-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list15-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert15.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list12-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list12-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert14.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list13-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list13-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamfam/expert13.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamfam-expert-tablist-list14-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamfam-expert-tablist-list14-info")}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 学员反馈 */}
                <div id="student">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamfam-student-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamfam-student-explain-desc")}
                    </div>
                  </div>

                  <div className="tablist">
                    <div className="item">
                      <div className="background">
                        <img
                          src="/assets/images/train/dreamit/student.png"
                          alt="student"
                        />
                      </div>
                      <div className="box">
                        <div className="content" style={MsgStyle}>
                          <div className="message">
                            {intl.get("dreamfam-student-tablist-list1-content")}
                          </div>
                        </div>
                        <div className="name">
                          {intl.get("dreamfam-student-tablist-list1-name")}
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="background">
                        <img
                          src="/assets/images/train/dreamit/student.png"
                          alt="student"
                        />
                      </div>
                      <div className="box">
                        <div className="content" style={MsgStyle}>
                          <div className="message">
                            {intl.get("dreamfam-student-tablist-list2-content")}
                          </div>
                        </div>
                        <div className="name">
                          {intl.get("dreamfam-student-tablist-list2-name")}
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="background">
                        <img
                          src="/assets/images/train/dreamit/student.png"
                          alt="student"
                        />
                      </div>
                      <div className="box">
                        <div className="content" style={MsgStyle}>
                          <div className="message">
                            {intl.get("dreamfam-student-tablist-list3-content")}
                          </div>
                        </div>
                        <div className="name">
                          {intl.get("dreamfam-student-tablist-list3-name")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 报名咨询 */}
              <div className="realtime">
                <div id="signup">
                  <div className="left">
                    <div className="qrcode">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamfam/qrcodeFam.jpeg"
                          alt="qrcode"
                        />
                      </div>
                      <div className="tip">
                        {intl.get("dreamfam-signup-left-tip")}
                      </div>
                    </div>
                    <div className="contact">
                      <div className="title">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon-msg.png"
                            alt="qrcode"
                          />
                        </div>
                        <span>{intl.get("dreamfam-signup-left-title")}</span>
                      </div>
                      <div className="phone">
                        {intl.get("dreamfam-signup-left-phone")}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="title">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/icon-post.png"
                          alt="qrcode"
                        />
                      </div>
                      <span>{intl.get("dreamfam-signup-right-title")}</span>
                    </div>
                    <div className="phone">
                      {intl.get("dreamfam-signup-right-phone")}
                    </div>
                    <div className="phone">
                      {intl.get("dreamfam-signup-right-email")}
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        );
    }
}