import React,{Component} from 'react'
// import intl from 'react-intl-universal'

import './TrainInfo.less'

import { Layout} from 'antd'

// import * as train from '../../../api/train'
import * as home from '../../../api/home'


export default class TrainInfo extends Component{
    constructor(props)
    {
        super(props)

        var id = this.props.location.query ? this.props.location.query.id : null

        if(!id)
        {
            // this.props.history.push('article')
            this.props.history.goBack()
            return false
        }

        this.state = {
            id:id,
            content:'',
            title:''
        }
    }
    async componentDidMount()
    {
        this.TrainInfo()
    }
    TrainInfo = async () => {
        var id = this.state.id

        home.newsinfo(id).then((success) => {
            if(success)
            {
                this.setState({
                    content:success.content,
                    title:success.title
                })
            }
        })

    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
            <div id="detail">
                {/* banner图 */}
                <Layout className="SingleBanner">
                    <div className="img">
                        <img src="/assets/images/about/introduction/banner.png" alt="banner" />
                    </div>
                </Layout>


                {/* 详细内容 */}
                <Layout id="detailed">
                    <div className="explain">
                        <div className="title">
                            <h4>{this.state.title}</h4>
                        </div>
                    </div>

                    <div className="content" dangerouslySetInnerHTML = {{ __html:this.state.content }}>
                    </div>
                </Layout>
            </div>
        )
    }
}