import React, { Component } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";

import "./DreamC.less";

import { Layout } from "antd";

import { Helmet } from "react-helmet";

const Back1Style = {
  background: `url(/assets/images/train/dreamc/stuback1.png) no-repeat left top`,
  backgroundSize: `100% 100%`,
};

const Back2Style = {
  background: `url(/assets/images/train/dreamc/stuback2.png) no-repeat left top`,
  backgroundSize: `100% 100%`,
};

const Back3Style = {
  background: `url(/assets/images/train/dreamc/stuback3.png) no-repeat left top`,
  backgroundSize: `100% 100%`,
};

export default class DreamC extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  render() {
    return (
      <div id="dreamc">
        <Helmet>
          <title>{intl.get('dreamc-html-head-title')}</title>
          <meta
            name="keywords"
            content="Dream-C儿童语言发育障碍临床专业资格培训，儿童语言沟通障碍临床培训，国家级继教学分，北美语言病理学硕士课程，国内外讲师团队，儿童语言发育进程，儿童语言康复，临床儿童语言康复治疗"
          />
          <meta
            name="description"
            content="美国培声DREAM-C 儿童语言障碍临床专业资格培训提供有效的儿童语言障碍评估与康复的全套解决方案！包括科学有效地帮助语言发育落后的儿童；系统性地学习儿童语言发展的专业理论知识；系统性地学习语言障碍诊治的临床框架与操作；了解并学习操作符合国际标准的儿童语言评估工具；制定循证的个体化干预计划及设计康复活动。"
          />
        </Helmet>

        {/* banner图 */}
        <Layout className="SingleBanner">
          <div className="img">
            <img src="/assets/images/train/dream/banner.jpg" alt="banner" />
          </div>
          <div className="box">
            <div className="content">
              <h3 className="title">{intl.getHTML("dreamc-banner-title")}</h3>
            </div>
          </div>
        </Layout>

        {/* 导航 */}
        <Layout className="menulist">
          <div className="navlist">
            <div
              onClick={() => this.scrollToAnchor("introduction")}
              className="link"
            >
              <span>{intl.get("dreamc-menulist1-zh")}</span>
              <span>{intl.get("dreamc-menulist1-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("fit")} className="link">
              <span>{intl.get("dreamc-menulist2-zh")}</span>
              <span>{intl.get("dreamc-menulist2-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("highlights")}
              className="link"
            >
              <span>{intl.get("dreamc-menulist3-zh")}</span>
              <span>{intl.get("dreamc-menulist3-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("curriculum")}
              className="link"
            >
              <span>{intl.get("dreamc-menulist4-zh")}</span>
              <span>{intl.get("dreamc-menulist4-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("expert")} className="link">
              <span>{intl.get("dreamc-menulist5-zh")}</span>
              <span>{intl.get("dreamc-menulist5-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("tools")} className="link">
              <span>{intl.get("dreamc-menulist6-zh")}</span>
              <span>{intl.get("dreamc-menulist6-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("student")}
              className="link"
            >
              <span>{intl.get("dreamc-menulist7-zh")}</span>
              <span>{intl.get("dreamc-menulist7-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("signup")} className="link">
              <span>{intl.get("dreamc-menulist8-zh")}</span>
              <span>{intl.get("dreamc-menulist8-en")}</span>
            </div>
          </div>
        </Layout>

        <Layout className="childrenContainer">
          <div className="modular">
            {/* 关于培训 */}
            <div id="introduction">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("dreamc-introduction-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("dreamc-introduction-explain-desc")}
                </div>
              </div>

              <div className="describe">
                {intl.get("dreamc-introduction-explain-describe")}
              </div>

              <div className="list">
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamc/icon1.png"
                      alt="img"
                    />
                  </div>
                  <p className="desc">
                    {intl.get("dreamc-introduction-list1")}
                  </p>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamc/icon2.png"
                      alt="img"
                    />
                  </div>
                  <p className="desc">
                    {intl.get("dreamc-introduction-list2")}
                  </p>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamc/icon3.png"
                      alt="img"
                    />
                  </div>
                  <p className="desc">
                    {intl.get("dreamc-introduction-list3")}
                  </p>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamc/icon4.png"
                      alt="img"
                    />
                  </div>
                  <p className="desc">
                    {intl.get("dreamc-introduction-list4")}
                  </p>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamc/icon5.png"
                      alt="img"
                    />
                  </div>
                  <p className="desc">
                    {intl.get("dreamc-introduction-list5")}
                  </p>
                </div>
              </div>

              <div className="content">
                {intl.get("dreamc-introduction-explain-content")}
              </div>
            </div>

            {/* 适合对象 */}
            <div id="fit">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("dreamc-fit-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("dreamc-fit-explain-desc")}
                </div>
              </div>

              <div className="teacher">
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/teacher1.png"
                      alt="img"
                    />
                  </div>
                  <div className="name">{intl.get("dreamc-fit-teacher1")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/teacher2.png"
                      alt="img"
                    />
                  </div>
                  <div className="name">{intl.get("dreamc-fit-teacher2")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/teacher3.png"
                      alt="img"
                    />
                  </div>
                  <div className="name">{intl.get("dreamc-fit-teacher3")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/teacher4.png"
                      alt="img"
                    />
                  </div>
                  <div className="name">{intl.get("dreamc-fit-teacher4")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/teacher5.png"
                      alt="img"
                    />
                  </div>
                  <div className="name">{intl.get("dreamc-fit-teacher5")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/teacher6.png"
                      alt="img"
                    />
                  </div>
                  <div className="name">{intl.get("dreamc-fit-teacher6")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/teacher7.png"
                      alt="img"
                    />
                  </div>
                  <div className="name">{intl.get("dreamc-fit-teacher7")}</div>
                </div>
              </div>
            </div>

            {/* 课程亮点 */}
            <div id="highlights">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("dreamc-highlights-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("dreamc-highlights-explain-desc")}
                </div>
              </div>

              <div className="list">
                <div className="item">
                  <div className="number">
                    <span>01</span>
                  </div>
                  <div className="title">
                    {intl.get("dreamc-highlights-list1-title")}
                  </div>
                  <div className="info">
                    {intl.get("dreamc-highlights-list1-info")}
                  </div>
                </div>
                <div className="item">
                  <div className="number">
                    <span>02</span>
                  </div>
                  <div className="title">
                    {intl.get("dreamc-highlights-list2-title")}
                  </div>
                  <div className="info">
                    {intl.get("dreamc-highlights-list2-info")}
                  </div>
                </div>
                <div className="item">
                  <div className="number">
                    <span>03</span>
                  </div>
                  <div className="title">
                    {intl.get("dreamc-highlights-list3-title")}
                  </div>
                  <div className="info">
                    {intl.getHTML("dreamc-highlights-list3-info")}
                  </div>
                </div>
                <div className="item">
                  <div className="number">
                    <span>04</span>
                  </div>
                  <div className="title">
                    {intl.get("dreamc-highlights-list4-title")}
                  </div>
                  <div className="info">
                    {intl.get("dreamc-highlights-list4-info")}
                  </div>
                </div>
                <div className="item">
                  <div className="left">
                    <div className="number">
                      <span>05</span>
                    </div>
                    <div className="title">
                      {intl.get("dreamc-highlights-list5-title")}
                    </div>
                    <div className="info">
                      {intl.get("dreamc-highlights-list5-info")}
                    </div>
                  </div>

                  <div className="right">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/cert1.png"
                        alt="img"
                      />
                    </div>
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/cert2.png"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 课程列表 */}
            <div id="curriculum">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("dreamc-curriculum-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("dreamc-curriculum-explain-desc")}
                </div>
              </div>

              <div className="tablist">
                <div className="title">
                  <h4>{intl.get("dreamc-curriculum-tablist1-title")}</h4>
                </div>

                <ul className="list online">
                  {intl.getHTML("dreamc-curriculum-tablist1-list")}
                </ul>

                <div className="title">
                  <h4>{intl.get("dreamc-curriculum-tablist2-title")}</h4>
                </div>
                <ul className="list">
                  {intl.getHTML("dreamc-curriculum-tablist2-list")}
                </ul>
              </div>
            </div>

            {/* 授课专家 */}
            <div id="expert">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("dreamc-expert-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("dreamc-expert-explain-desc")}
                </div>
              </div>

              <div className="tablist">
                <div className="title">
                  <h4>{intl.get("dreamc-expert-tablist1-title")}</h4>
                </div>

                <div className="list">
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert1.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist1-list1-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist1-list1-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert2.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist1-list2-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist1-list2-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert3.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist1-list3-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist1-list3-info")}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="title">
                  <h4>{intl.get("dreamc-expert-tablist2-title")}</h4>
                </div>

                <div className="list">
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert4.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list1-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list1-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert5.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list2-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list2-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert6.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list3-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list3-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert7.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list4-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list4-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert8.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list5-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list5-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert9.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list6-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list6-info")}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert10.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list7-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list7-info")}
                      </ul>
                    </div>
                  </div> */}
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert12.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list9-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list9-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert11.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list8-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list8-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert10.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list10-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list10-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert16.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list15-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list15-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert17.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list14-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list14-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert15.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list11-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list11-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert14.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list12-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list12-info")}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="background">
                      <img
                        src="/assets/images/train/dreamc/expert13.png"
                        alt="expert"
                      />
                    </div>
                    <div className="box">
                      <div className="name">
                        {intl.get("dreamc-expert-tablist2-list13-name")}
                      </div>
                      <ul className="info">
                        {intl.getHTML("dreamc-expert-tablist2-list13-info")}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 工具 */}
            <div id="tools">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("dreamit-tools-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("dreamit-tools-explain-desc")}
                </div>
              </div>

              <div className="tablist">
                <div className="title">
                  <h4>{intl.get("dreamc-tools-tablist1-title")}</h4>
                </div>

                <div className="describe">
                  {intl.get("dreamc-tools-tablist1-describe")}
                </div>

                <div className="title">
                  <h4>{intl.get("dreamc-tools-tablist2-title")}</h4>
                </div>

                <div className="list">
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon6.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist2-list1")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon7.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist2-list2")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon8.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist2-list3")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon9.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist2-list4")}
                    </p>
                  </div>
                </div>

                <div className="title">
                  <h4>{intl.get("dreamc-tools-tablist3-title")}</h4>
                </div>

                <div className="list2">
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon10.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist3-list1")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon11.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist3-list2")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon12.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist3-list3")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon13.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist3-list4")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon14.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist3-list5")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon15.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist3-list6")}
                    </p>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/icon16.png"
                        alt="img"
                      />
                    </div>
                    <p className="desc">
                      {intl.get("dreamc-tools-tablist3-list7")}
                    </p>
                  </div>
                </div>

                <div className="link">
                  <Link className="btn" to="/c">
                    {intl.get("dreamc-btn")}
                  </Link>
                </div>
              </div>
            </div>

            {/* 学员反馈 */}
            <div id="student">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("dreamc-student-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("dreamc-student-explain-desc")}
                </div>
              </div>

              <div className="stulist">
                <div className="item" style={Back1Style}>
                  <div className="left">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/avatar1.png"
                        alt="img"
                      />
                    </div>
                    <div className="info">
                      <div className="name">
                        {intl.get("dreamc-student-stulist1-name")}
                      </div>
                      <div className="type">
                        {intl.get("dreamc-student-stulist1-type")}
                      </div>
                    </div>
                  </div>

                  <div className="right">
                    <div className="content">
                      {intl.getHTML("dreamc-student-stulist1-content")}
                    </div>
                  </div>
                </div>

                <div className="item" style={Back2Style}>
                  <div className="left">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/avatar2.png"
                        alt="img"
                      />
                    </div>
                    <div className="info">
                      <div className="name">
                        {intl.get("dreamc-student-stulist2-name")}
                      </div>
                      <div className="type">
                        {intl.get("dreamc-student-stulist2-type")}
                      </div>
                    </div>
                  </div>

                  <div className="right">
                    <div className="content">
                      {intl.getHTML("dreamc-student-stulist2-content")}
                    </div>
                  </div>
                </div>

                <div className="item" style={Back3Style}>
                  <div className="left">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamc/avatar3.png"
                        alt="img"
                      />
                    </div>
                    <div className="info">
                      <div className="name">
                        {intl.get("dreamc-student-stulist3-name")}
                      </div>
                      <div className="type">
                        {intl.get("dreamc-student-stulist3-type")}
                      </div>
                    </div>
                  </div>

                  <div className="right">
                    <div className="content">
                      {intl.getHTML("dreamc-student-stulist3-content")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 报名咨询 */}
          <div className="realtime">
            <div id="signup">
              <div className="left">
                <div className="qrcode">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamc/qrcodeC.jpeg"
                      alt="qrcode"
                    />
                  </div>
                  <div className="tip">
                    {intl.get("dreamc-signup-left-tip")}
                  </div>
                </div>
                <div className="contact">
                  <div className="title">
                    <div className="img">
                      <img
                        src="/assets/images/train/dreamit/icon-msg.png"
                        alt="qrcode"
                      />
                    </div>
                    <span>{intl.get("dreamc-signup-left-title")}</span>
                  </div>
                  <div className="phone">
                    {intl.getHTML("dreamc-signup-left-phone")}
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="title">
                  <div className="img">
                    <img
                      src="/assets/images/train/dreamit/icon-post.png"
                      alt="qrcode"
                    />
                  </div>
                  <span>{intl.get("dreamc-signup-right-title")}</span>
                </div>
                <div className="phone">
                  {intl.get("dreamc-signup-right-phone")}
                </div>
                <div className="phone">
                  {intl.get("dreamc-signup-right-email")}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}
