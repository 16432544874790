import React, { Component } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";

import "./Mechanism.less";

// import * as mechanism from '../../api/home'

import { Layout } from "antd";
const institutions = [
  {
    icon: "assets/images/about/mechanism/company22.png",
    name: "mechanism-institution-name1",
    taglist: ["DREAM-C", "DREAM-IT", "FAM"],
  },
  {
    icon: "",
    name: "mechanism-institution-name2",
    taglist: ["DREAM-C", "DREAM-S", "DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company23.png",
    name: "mechanism-institution-name3",
    taglist: ["DREAM-C","DREAM-ITS","DREAM-S"],
  },
  {
    icon: "assets/images/about/mechanism/company21.png",
    name: "mechanism-institution-name4",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company24.png",
    name: "mechanism-institution-name5",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company25.png",
    name: "mechanism-institution-name6",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company20.png",
    name: "mechanism-institution-name7",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company45.png",
    name: "mechanism-institution-name8",
    taglist: ["DREAM-C", "DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company26.png",
    name: "mechanism-institution-name9",
    taglist: ["DREAM-C", "DREAM-IT", "DREAM-ITS", "FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company27.png",
    name: "mechanism-institution-name10",
    taglist: ["DREAM-C", "FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company27.png",
    name: "mechanism-institution-name11",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company28.png",
    name: "mechanism-institution-name12",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company29.png",
    name: "mechanism-institution-name13",
    taglist: ["DREAM-C", "FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company30.png",
    name: "mechanism-institution-name14",
    taglist: ["DREAM-C", "FAM"],
  },
  {
    icon: "",
    name: "mechanism-institution-name15",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company2.png",
    name: "mechanism-institution-name16",
    taglist: ["DREAM-C", "DREAM-IT", "DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company11.png",
    name: "mechanism-institution-name17",
    taglist: ["DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company31.png",
    name: "mechanism-institution-name18",
    taglist: ["DREAM-ITS", "DREAM-S", "DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company1.png",
    name: "mechanism-institution-name19",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company32.png",
    name: "mechanism-institution-name20",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name21",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name22",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company33.png",
    name: "mechanism-institution-name23",
    taglist: ["DREAM-ITS", "FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company10.png",
    name: "mechanism-institution-name24",
    taglist: ["DREAM-IT-S", "FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company34.png",
    name: "mechanism-institution-name25",
    taglist: ["DREAM-C", "DREAM-IT", "DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company47.png",
    name: "mechanism-institution-name26",
    taglist: ["DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company47.png",
    name: "mechanism-institution-name27",
    taglist: ["DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company19.png",
    name: "mechanism-institution-name28",
    taglist: ["DREAM-C", "DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company9.png",
    name: "mechanism-institution-name29",
    taglist: ["DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company18.png",
    name: "mechanism-institution-name30",
    taglist: ["DREAM-IT", "DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company4.png",
    name: "mechanism-institution-name31",
    taglist: ["DREAM-ITS", "FAM"],
  },
  {
    icon: "",
    name: "mechanism-institution-name32",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name33",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company36.png",
    name: "mechanism-institution-name34",
    taglist: ["DREAM-C", "DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company37.png",
    name: "mechanism-institution-name35",
    taglist: ["DREAM-C"],
  },
  {
    icon: "assets/images/about/mechanism/company14.png",
    name: "mechanism-institution-name36",
    taglist: ["DREAM-C", "DREAM-IT", "DREAM-S", "DREAM-ITS", "FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company38.png",
    name: "mechanism-institution-name37",
    taglist: ["DREAM-C", "FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company39.png",
    name: "mechanism-institution-name38",
    taglist: ["DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company48.png",
    name: "mechanism-institution-name39",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company5.png",
    name: "mechanism-institution-name40",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company15.png",
    name: "mechanism-institution-name41",
    taglist: ["FAM"],
  },
  // {
  //   icon: "assets/images/about/mechanism/company4.png",
  //   name: "mechanism-institution-name42",
  //   taglist: ["FAM"],
  // },
  {
    icon: "assets/images/about/mechanism/company35.png",
    name: "mechanism-institution-name43",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company40.png",
    name: "mechanism-institution-name44",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company13.png",
    name: "mechanism-institution-name45",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company3.png",
    name: "mechanism-institution-name46",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company41.png",
    name: "mechanism-institution-name47",
    taglist: ["FAM"],
  },
  {
    icon: "",
    name: "mechanism-institution-name48",
    taglist: ["FAM"],
  },
  {
    icon: "",
    name: "mechanism-institution-name49",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company42.png",
    name: "mechanism-institution-name50",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company44.png",
    name: "mechanism-institution-name51",
    taglist: ["FAM"],
  },
  {
    icon: "",
    name: "mechanism-institution-name52",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company46.png",
    name: "mechanism-institution-name53",
    taglist: ["FAM"],
  },
  {
    icon: "",
    name: "mechanism-institution-name54",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company49.png",
    name: "mechanism-institution-name55",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company11.png",
    name: "mechanism-institution-name56",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company50.png",
    name: "mechanism-institution-name57",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company51.png",
    name: "mechanism-institution-name58",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company8.png",
    name: "mechanism-institution-name59",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company7.png",
    name: "mechanism-institution-name60",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company6.png",
    name: "mechanism-institution-name61",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company17.png",
    name: "mechanism-institution-name62",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company63.png",
    name: "mechanism-institution-name63",
    taglist: [],
  },
  {
    icon: "",
    name: "mechanism-institution-name64",
    taglist: [],
  },
  {
    icon: "assets/images/about/mechanism/company65.png",
    name: "mechanism-institution-name65",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name66",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name67",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company68.png",
    name: "mechanism-institution-name68",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company69.png",
    name: "mechanism-institution-name69",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company70.png",
    name: "mechanism-institution-name70",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name71",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company73.png",
    name: "mechanism-institution-name73",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company74.png",
    name: "mechanism-institution-name74",
    taglist: ["DREAM-C","DREAM-S"],
  },
  {
    icon: "assets/images/about/mechanism/company75.png",
    name: "mechanism-institution-name75",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company76.png",
    name: "mechanism-institution-name76",
    taglist: ["DREAM-C","DREAM-IT"],
  },
  {
    icon: "assets/images/about/mechanism/company77.png",
    name: "mechanism-institution-name77",
    taglist: ["FAM"],
  },
  {
    icon: "assets/images/about/mechanism/company78.png",
    name: "mechanism-institution-name78",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company79.png",
    name: "mechanism-institution-name79",
    taglist: ["DREAM-S","DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company80.png",
    name: "mechanism-institution-name80",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company81.png",
    name: "mechanism-institution-name81",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company82.png",
    name: "mechanism-institution-name82",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company83.png",
    name: "mechanism-institution-name83",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company84.png",
    name: "mechanism-institution-name84",
    taglist: ["DREAM-IT","DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company85.png",
    name: "mechanism-institution-name85",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company86.png",
    name: "mechanism-institution-name86",
    taglist: ["DREAM-C","DREAM-IT"],
  },
  {
    icon: "",
    name: "mechanism-institution-name87",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name88",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company89.png",
    name: "mechanism-institution-name89",
    taglist: ["DREAM-C","DREAM-S","DREAM-IT","DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name90",
    taglist: ["DREAM-C","DREAM-S"],
  },
  {
    icon: "assets/images/about/mechanism/company91.png",
    name: "mechanism-institution-name91",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company92.png",
    name: "mechanism-institution-name92",
    taglist: ["DREAM-S","DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company93.png",
    name: "mechanism-institution-name93",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company94.png",
    name: "mechanism-institution-name94",
    taglist: ["DREAM-S","DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company95.png",
    name: "mechanism-institution-name95",
    taglist: ["DREAM-C","DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company96.png",
    name: "mechanism-institution-name96",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company97.png",
    name: "mechanism-institution-name97",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "assets/images/about/mechanism/company98.png",
    name: "mechanism-institution-name98",
    taglist: ["DREAM-ITS"],
  },
  {
    icon: "",
    name: "mechanism-institution-name99",
    taglist: ["DREAM-ITS"],
  },
]

export default class Mechanism extends Component {
  constructor(props) {
    super(props);

    var tool_id = this.props.location.query
      ? this.props.location.query.tool_id
      : "all";

    this.state = {
      tool_id: tool_id,
      hospital: [],
    };
  }
  componentDidMount() {
    this.hospitalList();
    console.log("get institution name:", intl.get);
  }
  componentDidUpdate() {}
  ChangeTool = async (tool_id) => {
    await this.setState({
      tool_id: tool_id,
    });

    this.hospitalList();
  };
  //获取医院列表
  hospitalList = () => {
    var tool_id = this.state.tool_id;

    // const its = all.map(item=>{
    //   return item.taglist.some(value=>value==="DREAM-ITS")
    // })

    // console.log(its)

    var datalist = {
      all: institutions,
      its: institutions.filter(function (item) {
        return item.taglist.includes("DREAM-ITS");
      }),
      s: institutions.filter(function (item) {
        return item.taglist.includes("DREAM-S");
      }),
      it: institutions.filter(function (item) {
        return item.taglist.includes("DREAM-IT");
      }),
      c: institutions.filter(function (item) {
        return item.taglist.includes("DREAM-C");
      }),
      fam: institutions.filter(function (item) {
        return item.taglist.includes("FAM");
      }),
      teach: [],
      education: [],
    };

    this.setState({
      hospital: datalist[tool_id],
    });
  };
  render() {
    //标签
    let Tool = (item) => {
      let arr = [];

      item.map((item, index) => {
        arr.push(
          <span className="tag" key={index}>
            {item}
          </span>
        );

        return arr;
      });

      return arr;
    };

    //医院列表
    let HospitalList = () => {
      let arr = [];

      this.state.hospital.map((item, index) => {
        // console.log(item);
        arr.push(
          <div className="item" key={index}>
            <div className="img">
              {/* <img src="assets/images/about/mechanism/icon1.png" alt="iconlist" /> */}
              <img
                src={
                  item.icon
                    ? item.icon
                    : "assets/images/about/mechanism/not.jpeg"
                }
                alt="iconlist"
              />
            </div>
            <h4 className="title">{intl.get(item.name)}</h4>
            <div className="taglist">{Tool(item.taglist)}</div>
          </div>
        );
        return arr;
      });

      return arr;
    };

    return (
      <div id="mechanism">
        <Helmet>
          <title>{intl.get("mechanism-html-head-title")}</title>
          <meta
            name="keywords"
            content="培声合作伙伴，培声三甲医院合作，培声合作项目，联系培声"
          />
          <meta
            name="description"
            content="从2011年至今，美国培声听力语言中心已经与全国近百家三甲医院，私立医院，妇幼保健院，医疗平台和教育平台建立长期合作关系，共同提升中国儿童言语-语言障碍的诊治水平和公众意识。"
          />
        </Helmet>

        {/* banner图 */}
        <Layout className="SingleBanner">
          <div className="img">
            <img src="/assets/images/about/mechanism/banner.png" alt="banner" />
          </div>
          <div className="box">
            <div className="content">
              <h3 className="title">
                {intl.getHTML("mechanism-banner-title")}
              </h3>
            </div>
          </div>
        </Layout>

        <Layout className="comlist">
          {/* 导航 */}
          <div className="navlist">
            <div onClick={() => this.ChangeTool("all")} className="link">
              {intl.get("mechanism-navlist1")}
            </div>
            <div onClick={() => this.ChangeTool("its")} className="link">
              {intl.get("mechanism-navlist2")}
            </div>
            <div onClick={() => this.ChangeTool("s")} className="link">
              {intl.get("mechanism-navlist3")}
            </div>
            <div onClick={() => this.ChangeTool("it")} className="link">
              {intl.get("mechanism-navlist4")}
            </div>
            <div onClick={() => this.ChangeTool("c")} className="link">
              {intl.get("mechanism-navlist5")}
            </div>
            <div onClick={() => this.ChangeTool("fam")} className="link">
              {intl.get("mechanism-navlist6")}
            </div>
            <div onClick={() => this.ChangeTool("teach")} className="link">
              {intl.get("mechanism-navlist7")}
            </div>
            <div onClick={() => this.ChangeTool("education")} className="link">
              {intl.get("mechanism-navlist8")}
            </div>
            <div className="link">
              <Link className="link" to="/project">
                {intl.get("mechanism-navlist9")}
              </Link>
            </div>
          </div>

          <div className="iconlist">{HospitalList()}</div>
        </Layout>

        <Layout className="contact">
          <div className="back">
            <img
              src="/assets/images/about/mechanism/contactbg.png"
              alt="back"
            />
          </div>

          <div className="box">
            <div className="explain">
              <div className="title">
                <h4>{intl.get("mechanism-contact-box-title")}</h4>
              </div>
              <div className="desc">
                {intl.get("mechanism-contact-box-desc")}
              </div>
            </div>

            <div className="list">
              <div className="item">
                <div className="header">
                  <div className="icon">
                    <img
                      src="/assets/images/about/mechanism/icons-communication_skill.png"
                      alt="icon"
                    />
                  </div>
                  <h3 className="title">
                    {intl.get("mechanism-contact-list1-title")}
                  </h3>
                </div>
                <div className="content">
                  {intl.getHTML("mechanism-contact-list1-content")}
                </div>
                <div className="phone">
                  {intl.get("mechanism-contact-list1-phone")}
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <div className="icon">
                    <img
                      src="/assets/images/about/mechanism/icons-handshake_heart.png"
                      alt="icon"
                    />
                  </div>
                  <h3 className="title">
                    {intl.get("mechanism-contact-list2-title")}
                  </h3>
                </div>
                <div className="content">
                  {intl.getHTML("mechanism-contact-list2-content")}
                </div>
                <div className="phone">
                  {intl.get("mechanism-contact-list2-phone")}
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <div className="icon">
                    <img
                      src="/assets/images/about/mechanism/icons-handshake_heart.png"
                      alt="icon"
                    />
                  </div>
                  <h3 className="title">
                    {intl.get("mechanism-contact-list3-title")}
                  </h3>
                </div>
                <div className="content">
                  {intl.getHTML("mechanism-contact-list3-content")}
                </div>
                <div className="phone">
                  {intl.get("mechanism-contact-list3-phone")}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}
