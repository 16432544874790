import React, { Component } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import "./CommonFooter.less";

class CommonFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qrcode: "wechat", //wechat tiktok unknown zhihu
    };
  }
  tabsQrcode(e, action) {
    this.setState({ qrcode: action });
  }
  render() {
    var location = this.props.location.pathname.split("/");
    var current = location[location.length - 1]
      ? location[location.length - 1]
      : "home";
    var routelist = [
      "home",
      "its",
      "s",
      "c",
      "it",
      "introduction",
      "dream",
      "dreamit",
      "dreamfam",
      "dreamc",
      "detail",
    ];

    return (
      <div
        className="footer"
        style={
          routelist.includes(current)
            ? {
                backgroundImage: `url(/assets/images/common/footerbg.png)`,
                backgroundColor: "#fff",
              }
            : {
                backgroundImage: `url(/assets/images/common/footerbg.png)`,
                backgroundColor: "transparent",
              }
        }
      >
        <div className="guide">
          <div className="site">
            <div className="logo">
              <img src="/assets/images/common/footer-logo.png" alt="logo" />
            </div>

            <ul className="list">
              <li>
                <div className="icon">
                  <img src="/assets/images/common/icons-call.png" alt="phone" />
                </div>
                <p>
                  +86 021-58979157 <br />
                  +86 021-58818026
                </p>
              </li>
              <li>
                <div className="icon">
                  <img
                    src="/assets/images/common/icons-marker.png"
                    alt="phone"
                  />
                </div>
                <p>{intl.get("footer-marker")}</p>
              </li>
              <li>
                <div className="icon">
                  <img
                    src="/assets/images/common/icons-newpost.png"
                    alt="phone"
                  />
                </div>
                <p>contact@ourbethel.cn</p>
              </li>
            </ul>
          </div>

          <div className="aboutus">
            <div className="title">
              <span className="main">
                {intl.get("footer-menu-about-title")}
              </span>
              <span className="short">
                {intl.get("footer-menu-about-desc")}
              </span>
            </div>

            <ul className="list">
              <li>
                <Link className="link" to="/it">
                  {intl.get("footer-menu1")}
                </Link>
              </li>
              <li>
                <Link className="link" to="/dream">
                  {intl.get("footer-menu2")}
                </Link>
              </li>
              <li>
                <Link className="link" to="/online">
                  {intl.get("footer-menu3")}
                </Link>
              </li>
              <li>
                <Link className="link" to="/article">
                  {intl.get("footer-menu4")}
                </Link>
              </li>
              <li>
                <Link className="link" to="/join">
                  {intl.get("footer-menu5")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="followus">
            <div className="title">
              <span className="main">
                {intl.get("footer-menu-follow-title")}
              </span>
              <span className="short">
                {intl.get("footer-menu-follow-desc")}
              </span>
            </div>

            <div className="share">
              <div
                className={this.state.qrcode === "wechat" ? "active" : ""}
                onClick={(e) => this.tabsQrcode(e, "wechat")}
              >
                <img
                  src="/assets/images/common/icons-wechat.png"
                  alt="wechat"
                />
              </div>
              <div
                className={this.state.qrcode === "tiktok" ? "active" : ""}
                onClick={(e) => this.tabsQrcode(e, "tiktok")}
              >
                <img
                  src="/assets/images/common/icons-tiktok.png"
                  alt="tiktok"
                />
              </div>
              <div
                className={this.state.qrcode === "unknown" ? "active" : ""}
                onClick={(e) => this.tabsQrcode(e, "unknown")}
              >
                <img
                  src="/assets/images/common/icons-unknown.png"
                  alt="unknown"
                />
              </div>
              <div
                className={this.state.qrcode === "zhihu" ? "active" : ""}
                onClick={(e) => this.tabsQrcode(e, "zhihu")}
              >
                <img src="/assets/images/common/icons-zhihu.png" alt="zhihu" />
              </div>
            </div>

            <div
              className="qrcode"
              style={this.state.qrcode === "wechat" ? { opacity: 1 } : {}}
            >
              <div className="item">
                <div className="img">
                  <img src="/assets/images/common/code-service.jpg" alt="wx" />
                </div>
                <div className="name">
                  {intl.get("footer-menu-follow-wechat")}
                </div>
              </div>
            </div>

            <div
              className="qrcode"
              style={this.state.qrcode === "tiktok" ? { opacity: 1 } : {}}
            >
              <div className="item">
                <div className="img">
                  <img
                    src="/assets/images/common/code-tiktok.png"
                    alt="tiktok"
                  />
                </div>
                <div className="name">
                  {intl.get("footer-menu-follow-douyin")}
                </div>
              </div>
            </div>

            <div
              className="qrcode"
              style={this.state.qrcode === "unknown" ? { opacity: 1 } : {}}
            >
              <div className="item">
                <div className="img">
                  <img
                    src="/assets/images/common/code-unknown.png"
                    alt="unknown"
                  />
                </div>
                <div className="name">{intl.get("footer-menu-follow-ql")}</div>
              </div>
            </div>

            <div
              className="qrcode"
              style={this.state.qrcode === "zhihu" ? { opacity: 1 } : {}}
            >
              <div className="item">
                <div className="img">
                  <img src="/assets/images/common/code-zhihu.png" alt="zhihu" />
                </div>
                <div className="name">
                  {intl.get("footer-menu-follow-zhihu")}
                </div>
              </div>
            </div>
          </div>

          <div className="appdown">
            <div className="title">
              <span className="main">{intl.get("footer-menu-app-title")}</span>
              <span className="short">{intl.get("footer-menu-app-desc")}</span>
            </div>

            <div
              className="qrcode"
              style={this.state.os === "android" ? { opacity: 1 } : {}}
            >
              <div className="item">
                <div className="img">
                  <img
                    src="/assets/images/common/code-android-ios.jpg"
                    alt="android"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-text">
          <div className="copyright">
            {intl.get("footer-copyright")}&nbsp;
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              style={{ color: "white" }}
            >
              {intl.get("footer-copyright-cert")}
            </a>
          </div>
          <div className="beian">
            <img
              src="/assets/images/common/ga_logo.png"
              className="beian-img"
              alt="beian"
            />
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018305"
              className="beian-link"
              rel="noreferrer"
            >
              <p className="beian-p">沪公网安备 31011502018305号</p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line
export default (props) => (
  <CommonFooter {...props} key={props.location.pathname} />
);
