import React, { Component } from "react";
import { Route } from "react-router-dom";
import intl from "react-intl-universal";
import ScrollToTop from "./ScrollToTop";

import { Layout } from "antd";

//公共组件
import CommonHeader from "./components/Common/Header/CommonHeader";
import CommonFooter from "./components/Common/Footer/CommonFooter";
import Detail from "./components/Common/Detail/Detail"; //文章详细
import NewsInfo from "./components/Common/NewsInfo/NewsInfo"; //咨询详细
import LectureInfo from "./components/Common/LectureInfo/LectureInfo"; //讲座详细
import TrainInfo from "./components/Common/TrainInfo/TrainInfo"; //招募详细

import Home from "./components/Home/Home";
import About from "./components/About/About";

//语言测评
import ITS from "./components/Language/ITS";
import S from "./components/Language/S";
import IT from "./components/Language/IT";
import C from "./components/Language/C";

//关于我们
import Mechanism from "./components/About/Mechanism"; //合作机构
import Project from "./components/About/Project"; //公益项目
import Company from "./components/About/Company"; //公司荣誉
import Introduction from "./components/About/Introduction"; //培声简介
import Team from "./components/About/Team"; //专业团队

//干预咨询
import Children from "./components/Contact/Children"; //儿童语言家庭干预指导
import Online from "./components/Contact/Online"; //远程一对一咨询
import Offline from "./components/Contact/Offline"; //线下门诊一对一

//专业培训
import Dream from "./components/Train/Dream"; //DREAM专业资格培训
import DreamIT from "./components/Train/DreamIT"; //DREAM-IT培训
import DreamFam from "./components/Train/DreamFam"; //家庭培训师培训
import DreamC from "./components/Train/DreamC"; //Dream-C培训

//专业人士
import Lecture from "./components/Major/Lecture"; //公益讲座
import Article from "./components/Major/Article"; //专业文章视频
import Train from "./components/Major/Train"; //专业培训
import Cert from "./components/Major/Cert"; //专业资格查询
import Application from "./components/Major/Application"; //陪伴app

//加入我们
import Join from "./components/Home/Join";

//引入事件对象
import { emit } from "./emit";

// antd默认多语言项
import zh_CN from "antd/es/locale/zh_CN";
import en_US from "antd/es/locale/en_US";

//自定义语言项目
import ZH from "./locales/zh/index";
import EN from "./locales/en/index";

//自定义语言项
const locales = {
  // "en-US": require('./locales/en-us.json'),
  // "zh-CN": require('./locales/zh-cn.json'),
  "en-US": EN,
  "zh-CN": ZH,
};

export default class RouterWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      antdLang: zh_CN, // 修改antd  组件的国际化
    };

    //判断是否为手机还是移动端
    var sUserAgent = navigator.userAgent.toLowerCase();

    console.log(sUserAgent);
  }
  componentDidMount() {
    // emit.on('change_language', lang => this.loadLocales(lang)); // 监听语言改变事件
    emit.on("change_language", (lang) => {
      var LangActive = localStorage.getItem("lang");

      var LangArr = ["zh-CN", "en-US"];

      if (LangArr.includes(lang) && LangActive !== lang) {
        localStorage.setItem("lang", lang);
      } else {
        localStorage.setItem("lang", "zh-CN");
      }

      //刷新一下
      window.location.reload();
    }); // 监听语言改变事件

    this.loadLocales(); // 初始化语言
  }
  loadLocales() {
    // configure of default language
    // var lang = 'zh-CN';
    var lang = "en-US";
    var LangActive = localStorage.getItem("lang");

    var LangArr = ["zh-CN", "en-US"];

    if (!LangArr.includes(LangActive)) {
      localStorage.setItem("lang", lang);
    } else {
      lang = LangActive;
    }

    intl
      .init({
        currentLocale: lang, // 设置初始语音
        locales,
      })
      .then(() => {
        this.setState({
          antdLang: lang === "zh-CN" ? zh_CN : en_US,
        });
      });
  }
  render() {
    return (
      <Layout className="container">
        <ScrollToTop>
          <CommonHeader
            match={this.props.match}
            location={this.props.location}
            history={this.props.history}
            locale={this.state.antdLang}
            exact
          />
          <Route path="/detail" component={Detail} exact />
          <Route path="/newsinfo" component={NewsInfo} exact />
          <Route path="/lectureinfo" component={LectureInfo} exact />
          <Route path="/traininfo" component={TrainInfo} exact />

          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} exact />

          <Route path="/its" component={ITS} exact />
          <Route path="/s" component={S} exact />
          <Route path="/it" component={IT} exact />
          <Route path="/c" component={C} exact />

          <Route path="/mechanism" component={Mechanism} exact />
          <Route path="/project" component={Project} exact />
          <Route path="/company" component={Company} exact />
          <Route path="/introduction" component={Introduction} exact />
          <Route path="/team" component={Team} exact />

          <Route path="/join" component={Join} exact />

          <Route path="/children" component={Children} exact />
          <Route path="/online" component={Online} exact />
          <Route path="/offline" component={Offline} exact />

          <Route path="/dream" component={Dream} exact />
          <Route path="/dreamit" component={DreamIT} exact />
          <Route path="/dreamfam" component={DreamFam} exact />
          <Route path="/dreamc" component={DreamC} exact />

          <Route path="/lecture" component={Lecture} exact />
          <Route path="/article" component={Article} exact />
          <Route path="/train" component={Train} exact />
          <Route path="/cert" component={Cert} exact />
          <Route path="/application" component={Application} exact />

          <CommonFooter
            match={this.props.match}
            location={this.props.location}
            history={this.props.history}
            exact
          />
        </ScrollToTop>
      </Layout>
    );
  }
}
