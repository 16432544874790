import React,{Component} from 'react'
import { Helmet } from 'react-helmet'
import intl from 'react-intl-universal'

import './Join.less'

import {
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';

import {Carousel, Layout, Tabs} from 'antd'
const { TabPane } = Tabs;



export default class Join extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            bannerSet:{
                dots: {
                    className:'charlist-dots'
                },
                lazyLoad: true,
                // autoplay:true,
                effect:'scrollx'
            }
        }

        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
    }
    next() {
        this.slider.next()
    }
    prev() {
        this.slider.prev()
    }
    render()
    {
        const operations = <div className="jobnav">
                                <h3 className="title">{intl.get('join-jobnav-title')}</h3>
                                <div className="desc">{intl.get('join-jobnav-desc')}</div>
                            </div>;

        return (
            <div id="join">
                <Helmet>
                    <title>{intl.get("join-html-head-title")}</title>
                    <meta name="keywords" content="加入我们" />
                    <meta name="description" content="加入我们" />
                </Helmet>

                {/* banner图 */}
                <Layout className="SingleBanner">
                    <div className="img">
                        <img src="/assets/images/join/banner.png" alt="banner" />
                    </div>
                    <div className="box">
                        <div className="content">
                            <h3 className="title">{intl.get('join-banner-title')}</h3>
                        </div>
                    </div>
                </Layout>


                {/* 团队 */}
                <div className="jointeam">
                    {/* 列表 */}
                    <div className="welfare">
                        <div className="item">
                            <div className="img">
                                <img src="/assets/images/join/icon1.png" alt="icon" />
                            </div>
                            <div className="title">{intl.get('join-jointeam-list1-title')}</div>
                            <div className="desc">{intl.get('join-jointeam-list1-desc')}</div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src="/assets/images/join/icon2.png" alt="icon" />
                            </div>
                            <div className="title">{intl.get('join-jointeam-list2-title')}</div>
                            <div className="desc">{intl.get('join-jointeam-list2-desc')}</div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src="/assets/images/join/icon3.png" alt="icon" />
                            </div>
                            <div className="title">{intl.get('join-jointeam-list3-title')}</div>
                            <div className="desc">{intl.get('join-jointeam-list3-desc')}</div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src="/assets/images/join/icon4.png" alt="icon" />
                            </div>
                            <div className="title">{intl.get('join-jointeam-list4-title')}</div>
                            <div className="desc">{intl.get('join-jointeam-list4-desc')}</div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src="/assets/images/join/icon5.png" alt="icon" />
                            </div>
                            <div className="title">{intl.get('join-jointeam-list5-title')}</div>
                            <div className="desc">{intl.get('join-jointeam-list5-desc')}</div>
                        </div>
                    </div>

                    {/* 人物 */}
                    <div className="character">
                        <Carousel  {...this.state.bannerSet} className="charlist" ref={el => (this.slider = el)}>
                            <div className="item">
                                <div className="avatar">
                                    <img src="/assets/images/join/avatar1.png" alt="avatar" />
                                </div>
                                <div className="box">
                                    <div className="title">{intl.get('join-character-list1-title')}</div>
                                    <div className="info">{intl.get('join-character-list1-info')}</div>
                                    <div className="content">{intl.get('join-character-list1-content')}</div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="avatar">
                                    <img src="/assets/images/join/avatar2.png" alt="avatar" />
                                </div>
                                <div className="box">
                                    <div className="title">{intl.get('join-character-list2-title')}</div>
                                    <div className="info">{intl.get('join-character-list2-info')}</div>
                                    <div className="content">{intl.get('join-character-list2-content')}</div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="avatar">
                                    <img src="/assets/images/join/avatar3.png" alt="avatar" />
                                </div>
                                <div className="box">
                                    <div className="title">{intl.get('join-character-list3-title')}</div>
                                    <div className="info">{intl.get('join-character-list3-info')}</div>
                                    <div className="content">{intl.get('join-character-list3-content')}</div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="avatar">
                                    <img src="/assets/images/join/avatar4.png" alt="avatar" />
                                </div>
                                <div className="box">
                                    <div className="title">{intl.get('join-character-list4-title')}</div>
                                    <div className="info">{intl.get('join-character-list4-info')}</div>
                                    <div className="content">{intl.get('join-character-list4-content')}</div>
                                </div>
                            </div>
                            
                        </Carousel>

                        <div className="charlist-btn">
                            <button className="left" onClick={this.prev}>
                                <LeftOutlined />
                            </button>
                            <button className="right" onClick={this.next}>
                                <RightOutlined />
                            </button>
                        </div>
                    </div>
                </div>


                {/* 岗位 */}
                <div className="jobox">
                    <div className="joblist">
                        <Tabs className="tablist" defaultActiveKey="1" tabPosition="left" tabBarGutter={0} tabBarExtraContent={operations} animated={true}>
                            <TabPane className="jobpanel" tab={intl.get('join-joblist1-name')} key="1">
                                <div className="title">{intl.get('join-joblist1-title')}</div>

                                <div className="content">
                                    {intl.getHTML('join-joblist1-content')}
                                </div>
                            </TabPane>  
                            <TabPane className="jobpanel" tab={intl.get('join-joblist3-name')} key="3">
                                <div className="title">{intl.get('join-joblist3-title')}</div>

                                <div className="content">
                                    {intl.getHTML('join-joblist3-content')}
                                </div>
                            </TabPane>
                            <TabPane className="jobpanel" tab={intl.get('join-joblist4-name')} key="4">
                                <div className="title">{intl.get('join-joblist4-title')}</div>

                                <div className="content">
                                    {intl.getHTML('join-joblist4-content')}
                                </div>
                            </TabPane>
                            
                            {/* <TabPane className="jobpanel" tab={intl.get('join-joblist2-name')} key="2">
                                <div className="title">{intl.get('join-joblist2-title')}</div>

                                <div className="content">
                                    {intl.getHTML('join-joblist2-content')}
                                </div>
                            </TabPane>
                            <TabPane className="jobpanel" tab={intl.get('join-joblist5-name')} key="5">
                                <div className="title">{intl.get('join-joblist5-title')}</div>

                                <div className="content">
                                    {intl.getHTML('join-joblist5-content')}
                                </div>
                            </TabPane>
                            <TabPane className="jobpanel" tab={intl.get('join-joblist6-name')} key="6">
                                <div className="title">{intl.get('join-joblist6-name')}</div>

                                <div className="content">
                                    {intl.getHTML('join-joblist6-content')}
                                </div>
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>

                {/* 联系我们 */}
                <div className="contact">
                    <div className="box">
                        <div className="title">
                            <h3 className="big">{intl.get('join-contact-title-big')}</h3>
                            <div className="small">{intl.get('join-contact-title-small')}</div>
                        </div>

                        <div className="content">
                            <ul className="info">
                                {intl.getHTML('join-contact-content-info')}
                            </ul>

                            <div className="img">
                                <img src="/assets/images/join/qrcode.jpg" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* 空背景 */}
                <div className="background"></div>

            </div>
        )
    }
}