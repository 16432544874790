import React,{Component} from 'react'
// import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './Online.less'

import { Layout} from 'antd';


export default class Online extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="online">
            <Helmet>
              <title>{intl.get('online-html-head-title')}</title>
              <meta
                name="keywords"
                content="一对一语言咨询，一对一语言康复咨询，一对一语言康复计划，一对一专家咨询，一对一远程专家指导"
              />
              <meta
                name="description"
                content="美国培声远程一对一咨询（“Telepractice”）是目前北美和其它国家比较常见的一种干预方式。比较适合远程咨询的儿童主要是轻中度单纯性的语言发育迟缓，没有其它复杂的发育问题，且发现语迟的时间较早。针对这类儿童，以家庭为中心的远程咨询指导，主要是为了教会家长针对性干预的具体策略和目标，让家长可以在家中，通过和孩子的游戏及日常互动，促进儿童语言发育。通过治疗师指导家长在家中进行干预，这类孩子将获得与线下一对一相比更高频的干预。家长掌握针对性个体化的干预策略后，可以持续对孩子进行支持，使其尽快赶上同龄人。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img
                  src="/assets/images/contact/children/banner.png"
                  alt="banner"
                />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">{intl.get("online-banner-title")}</h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("online-menulist1-zh")}</span>
                  <span>{intl.get("online-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("highlights")}
                  className="link"
                >
                  <span>{intl.get("online-menulist2-zh")}</span>
                  <span>{intl.get("online-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("case")}
                  className="link"
                >
                  <span>{intl.get("online-menulist3-zh")}</span>
                  <span>{intl.get("online-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("problem")}
                  className="link"
                >
                  <span>{intl.get("online-menulist4-zh")}</span>
                  <span>{intl.get("online-menulist4-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="onlineContainer">
              <div className="modular">
                {/* 简介 */}
                <div id="introduction">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("online-introduction-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("online-introduction-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="back">
                      <img
                        src="/assets/images/contact/online/introduction-back.png"
                        alt="back"
                      />
                    </div>

                    <div className="desc">
                      {intl.get("online-introduction-explain-content")}
                    </div>
                  </div>
                </div>

                {/* 亮点 */}
                <div id="highlights">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("online-highlights-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("online-highlights-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="item">
                      <div className="icon">
                        <img
                          src="/assets/images/contact/online/highlights1.png"
                          alt="icon"
                        />
                      </div>
                      <h4 className="title">
                        {intl.get("online-highlights-list1-title")}
                      </h4>
                      <div className="desc">
                        {intl.get("online-highlights-list1-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img
                          src="/assets/images/contact/online/highlights2.png"
                          alt="icon"
                        />
                      </div>
                      <h4 className="title">
                        {intl.get("online-highlights-list2-title")}
                      </h4>
                      <div className="desc">
                        {intl.get("online-highlights-list2-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img
                          src="/assets/images/contact/online/highlights3.png"
                          alt="icon"
                        />
                      </div>
                      <h4 className="title">
                        {intl.get("online-highlights-list3-title")}
                      </h4>
                      <div className="desc">
                        {intl.get("online-highlights-list3-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img
                          src="/assets/images/contact/online/highlights4.png"
                          alt="icon"
                        />
                      </div>
                      <h4 className="title">
                        {intl.get("online-highlights-list4-title")}
                      </h4>
                      <div className="desc">
                        {intl.get("online-highlights-list4-info")}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 往期案例 */}
                <div id="case">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("online-case-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("online-case-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="card">
                      <img
                        src={intl.get("online-case-explain-img")}
                        alt="card"
                      />
                    </div>
                    <div className="desc">
                      {intl.getHTML("online-case-explain-content")}
                    </div>
                  </div>
                </div>

                {/* 常见问题 */}
                <div id="problem">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("online-problem-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("online-problem-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="item">
                      <p className="title">
                        {intl.getHTML("online-problem-list1-title")}
                      </p>
                      <p className="desc">
                        {intl.getHTML("online-problem-list1-desc")}
                      </p>
                    </div>

                    <div className="item">
                      <p className="title">
                        {intl.getHTML("online-problem-list2-title")}
                      </p>
                      <p className="desc">
                        {intl.getHTML("online-problem-list2-desc")}
                      </p>
                    </div>

                    <div className="item">
                      <p className="title">
                        {intl.getHTML("online-problem-list3-title")}
                      </p>
                      <p className="desc">
                        {intl.getHTML("online-problem-list3-desc")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* 报名 */}
              <div id="signup">
                <div className="left">
                  <div className="qrcode">
                    <div className="img">
                      <img
                        src="/assets/images/contact/children/wxcode.png"
                        alt="img"
                      />
                    </div>
                    <div className="name">
                      {intl.get("online-signup-left-qrcode")}
                    </div>
                  </div>

                  <div className="short">
                    {intl.get("online-signup-left-short")}
                    <br />
                    {intl.get("online-signup-left-phone")}
                  </div>
                </div>

                <div className="center"></div>

                <div className="right">
                  <a
                    className="btn"
                    href="https://ipp.ourbethel.cn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.get("online-signup-right-title")}
                  </a>
                </div>
              </div>
            </Layout>
          </div>
        );
    }
}