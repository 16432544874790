import React, { Component } from "react";
import { Link } from "react-router-dom";
import AwesomeSwiper from "react-awesome-swiper";
import { Helmet } from "react-helmet";
import intl from "react-intl-universal";

import "./Home.less";
import * as home from "../../api/home";

import moment from "moment";

import { Layout, Select } from "antd";
const { Option } = Select;

const config = {
  slidesPerView: 3,
  preloadImages: false,
  lazy: true,
  speed: 500,
  pagination: {
    el: ".swiper-pagination",
    bulletElement: "li",
    hideOnClick: true,
    clickable: true,
  },
};

const childStyle = {
  background: `url(/assets/images/home/childrenbg.jpg) no-repeat left top`,
  backgroundSize: `100% 98%`,
};

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: "major", //major parent
      citylist: [],
      city: null,
      hospital: [],
      newstype: "all",
      newslist: [],
    };
  }

  componentDidMount() {
    console.log('We are in didMount')
    this.citylist();
    this.hospitalList();
    console.log(
      intl.options.currentLocale === "en-US"
        ? "现在是在英文环境"
        : "现在是在中文环境"
    );
    //获取最新资讯
    this.newslist();
  }
  //获取医院地区
  citylist = async () => {
    home.hospital_locations().then((success) => {
      if (success.locations.length) {
        this.setState({
          citylist: success.locations,
        });
      }
    });
  };
  //获取医院列表
  hospitalList = async () => {
    var data = {
      page_size: 1000,
    };

    var city = this.state.city;

    if (city) {
      var area = city.split("-");

      data.province = area[0];
      data.city = area[1];
    }

    home.hospitals(data).then((success) => {
      if (success.items.length) {
        this.setState({
          hospital: success.items,
        });
      }
    });
  };
  //获取新闻资讯
  newslist = async () => {
    var data = {
      page: 1,
      page_size: 1000,
      w_type: this.state.newstype,
      type: 0,
    };

    home.news(data).then((success) => {
      if (success.items.length) {
        this.setState({
          newslist: success.items,
        });
      }
    });
  };
  tabs(e, action) {
    this.setState({ current: action });
  }
  // 选择城市
  ChangeCity = async (city) => {
    await this.setState({
      city: city,
      hospital: [],
    });

    this.hospitalList();
  };
  //选择咨询
  ChangeNews = async (type) => {
    await this.setState({
      newstype: type,
    });

    this.newslist();
  };

  //咨询查看详情
  toDetail(newsinfo, e) {
    this.props.history.push({
      pathname: "/newsinfo",
      query: { newsinfo: newsinfo },
    });
  }
  render() {
    //最新咨询
    let NewsList = () => {
      let arr = [];
      this.state.newslist.map((item, index) => {
        arr.push(
          <div className="swiper-slide item" key={index}>
            <div className="card">
              <div className="thumb">
                {/* <img src="/assets/images/home/activity1.png" alt="icon" /> */}
                <img src={item.cover_url} alt="icon" />
              </div>

              <div className="front">
                <h3 className="title">
                  {item.title.length < 24
                    ? item.title
                    : item.title.slice(0, 24) + "..."}
                </h3>

                {/* <p className="desc" dangerouslySetInnerHTML = {{ __html:item.content }}></p> */}
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: item.summary }}
                ></p>

                <div className="date">
                  {moment(item.createdAt).format("YYYY/MM/DD")}
                </div>
              </div>

              <div className="back" onClick={this.toDetail.bind(this, item)}>
                <h3 className="title">{item.title}</h3>

                {/* <p className="desc" dangerouslySetInnerHTML = {{ __html:item.content }}></p> */}
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: item.summary }}
                ></p>

                <div className="date">
                  {moment(item.createdAt).format("YYYY/MM/DD")}
                </div>

                <div className="join">
                  <img src="/assets/images/home/icons-join.png" alt="join" />
                </div>
              </div>
            </div>
          </div>
        );
        return arr;
      });

      return arr;
    };

    return (
      <div id="home">
        <Helmet>
          <title>{intl.get("home-html-head-title")}</title>
          <meta
            name="keywords"
            content="美国培声听力语言中心-致力于推动中国言语语言病理学发展，助千万儿童实现沟通梦想；培声，儿童语言康复，儿童语言干预，儿童语言发育障碍，婴幼儿语言测试，语迟，认知障碍，听力障碍，学习障碍，语音障碍，自闭症谱系，儿童听力语言沟通中心，培声集团，海南博鳌培声国际医学中心，海南博鳌培声沟通障碍医学中心"
          />
          <meta
            name="description"
            content="美国培声听力语言中心是一所汇集权威言语语言病理学、听力学、心理语言学、儿科医学、康复学、家庭学等跨专业国际专家团队的临床、科研与培训机构。致力于为语言发育迟缓，语言障碍、言语障碍、听力障碍、认知障碍，听力障碍，学习障碍，自闭症谱系和具有其他发育障碍或精神发育疾病的孩子，以及医疗、康复、教育和科研领域专业人士提供专业的儿童语言干预康复和培训，从而提升中国儿童言语-语言障碍的诊治水平和公众意识，并专注于促进儿童语言发展的系统家长辅导和教师培训，从而在儿童家庭和幼儿园/学校中科学地提高儿童早期语言能力。"
          />
        </Helmet>
        {/* 关于我们 */}
        <Layout className="about">
          <div className="box">
            <div className="title">
              <h4>{intl.get("about-title-zh")}</h4>
            </div>
            <div className="desc">{intl.get("about-title-en")}</div>
          </div>

          <div className="list">
            <div className="item">
              <div className="total">
                <div className="icon">
                  <img src="/assets/images/home/icons1.svg" alt="icon" />
                </div>
                <div className="nums">{intl.get("about-list1-number")}</div>
              </div>
              <div className="info">{intl.get("about-list1-info")}</div>
            </div>
            <div className="item">
              <div className="total">
                <div className="icon">
                  <img src="/assets/images/home/icons2.svg" alt="icon" />
                </div>
                <div className="nums">{intl.get("about-list2-number")}</div>
              </div>
              <div className="info">{intl.get("about-list2-info")}</div>
            </div>
            <div className="item">
              <div className="total">
                <div className="icon">
                  <img src="/assets/images/home/icons3.svg" alt="icon" />
                </div>
                <div className="nums">{intl.get("about-list3-number")}</div>
              </div>
              <div className="info">{intl.get("about-list3-info")}</div>
            </div>
            <div className="item">
              <div className="total">
                <div className="icon">
                  <img src="/assets/images/home/icons5.svg" alt="icon" />
                </div>
                <div className="nums">{intl.get("about-list4-number")}</div>
              </div>
              <div className="info">{intl.get("about-list4-info")}</div>
            </div>
          </div>

          <div className="introduce">
            <div className="left">”</div>
            <div className="center">{intl.get("about-introduce")}</div>
            <div className="right">”</div>
          </div>
        </Layout>

        {/* 地图 */}
        <Layout className="map">
          <img src={intl.get("map-img-path")} alt="map" />
        </Layout>

        {/* 产品和服务 */}
        <Layout className="service">
          <div className="box">
            <div className="title">
              <h4>{intl.get("service-title-zh")}</h4>
            </div>
            <div className="desc">{intl.get("service-title-en")}</div>
          </div>

          <div className="switch">
            <label
              className={this.state.current === "major" ? "active" : ""}
              onClick={(e) => this.tabs(e, "major")}
            >
              {intl.get("service-tabs1")}
            </label>
            <label
              className={this.state.current === "parent" ? "active" : ""}
              onClick={(e) => this.tabs(e, "parent")}
            >
              {intl.get("service-tabs2")}
            </label>
          </div>

          <div
            className="serbox"
            style={
              this.state.current === "major"
                ? { opacity: 1, height: "100%" }
                : {}
            }
          >
            <div className="serbg">
              <img
                className="img"
                src="/assets/images/home/major.png"
                alt="major"
              />
            </div>
            <div className="serlist">
              <div className="serinfo">
                <h3 className="title">{intl.get("service-tabs1-title")}</h3>
                <ul className="list">
                  <li>
                    <Link to="/" className="link">
                      {intl.get("service-tabs1-list1")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="link">
                      {intl.get("service-tabs1-list2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="link">
                      {intl.get("service-tabs1-list3")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="serbox"
            style={
              this.state.current === "parent"
                ? { opacity: 1, height: "100%" }
                : {}
            }
          >
            <div className="serbg">
              <img
                className="img"
                src="/assets/images/home/major2.png"
                alt="major"
              />
            </div>
            <div className="serlist">
              <div className="serinfo">
                <h3 className="title">{intl.get("service-tabs2-title")}</h3>
                <ul className="list">
                  <li>
                    <Link to="/" className="link">
                      {intl.get("service-tabs2-list1")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="link">
                      {intl.get("service-tabs2-list2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="link">
                      {intl.get("service-tabs2-list3")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Layout>

        {/* 梦想 */}
        <Layout className="dream">
          <AwesomeSwiper
            className="box"
            ref={(ref) => (this.swiperRef = ref)}
            config={config}
          >
            <div className="swiper-wrapper list">
              <div id="tool" className="swiper-slide item">
                <div className="icon">
                  <div className="img">
                    <img src="/assets/images/home/dream1.svg" alt="icon" />
                  </div>

                  <div className="age">{intl.get("dream-item1-age")}</div>
                </div>

                <div className="desc">
                  <h3 className="title">{intl.get("dream-item1-title")}</h3>
                  <ul className="list">
                    <li>
                      <Link to="/its" className="link">
                        {intl.get("dream-item1-list1")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/its" className="link">
                        {intl.get("dream-item1-list2")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/its" className="link">
                        {intl.get("dream-item1-list3")}
                      </Link>
                    </li>
                    {intl.get("dream-item1-list4") ? (
                      <li>
                        <Link to="/its" className="link">
                          {intl.get("dream-item1-list4")}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {intl.get("dream-item1-list5") ? (
                      <li>
                        <Link to="/its" className="link">
                          {intl.get("dream-item1-list5")}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {intl.get("dream-item1-list6") ? (
                      <li>
                        <Link to="/its" className="link">
                          {intl.get("dream-item1-list6")}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
              <div className="swiper-slide item">
                <div className="icon">
                  <div className="img">
                    <img src="/assets/images/home/dream3.svg" alt="icon" />
                  </div>

                  <div className="age">{intl.get("dream-item2-age")}</div>
                </div>

                <div className="desc">
                  <h3 className="title">{intl.get("dream-item2-title")}</h3>
                  <ul className="list">
                    <li>
                      <Link to="/it" className="link">
                        {intl.get("dream-item2-list1")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/it" className="link">
                        {intl.get("dream-item2-list2")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/it" className="link">
                        {intl.get("dream-item2-list3")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/it" className="link">
                        {intl.get("dream-item2-list4")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/it" className="link">
                        {intl.get("dream-item2-list5")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="swiper-slide item">
                <div className="icon">
                  <div className="img">
                    <img src="/assets/images/home/dream2.svg" alt="icon" />
                  </div>

                  <div className="age">{intl.get("dream-item3-age")}</div>
                </div>

                <div className="desc">
                  <h3 className="title">{intl.get("dream-item3-title")}</h3>
                  <ul className="list">
                    <li>
                      <Link to="/s" className="link">
                        {intl.get("dream-item3-list1")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/s" className="link">
                        {intl.get("dream-item3-list2")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/s" className="link">
                        {intl.get("dream-item3-list3")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/s" className="link">
                        {intl.get("dream-item3-list4")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/s" className="link">
                        {intl.get("dream-item3-list5")}
                      </Link>
                    </li>
                    {intl.get("dream-item3-list6") ? (
                      <li>
                        <Link to="/s" className="link">
                          {intl.get("dream-item3-list6")}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
              <div className="swiper-slide item">
                <div className="icon">
                  <div className="img">
                    <img src="/assets/images/home/dream4.svg" alt="icon" />
                  </div>

                  <div className="age">{intl.get("dream-item4-age")}</div>
                </div>

                <div className="desc">
                  <h3 className="title">{intl.get("dream-item4-title")}</h3>
                  <ul className="list">
                    <li>
                      <Link to="/c" className="link">
                        {intl.get("dream-item4-list1")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/c" className="link">
                        {intl.get("dream-item4-list2")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/c" className="link">
                        {intl.get("dream-item4-list3")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/c" className="link">
                        {intl.get("dream-item4-list4")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/c" className="link">
                        {intl.get("dream-item4-list5")}
                      </Link>
                    </li>
                    {intl.get("dream-item4-list6") ? (
                      <li>
                        <Link to="/c" className="link">
                          {intl.get("dream-item4-list6")}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="swiper-pagination bethelPage"></div>
          </AwesomeSwiper>

          <div className="background">
            <img src="/assets/images/home/dreambg.jpg" alt="background" />
          </div>
        </Layout>

        {/* app下载 */}
        <Layout className="app">
          <div className="box">
            <div
              className={intl.options.currentLocale==="en-US"?"title-en":"title-zh"}
              // style={
              //   intl.options.currentLocale === "en-US"
              //     ? { fontSize: "30px", height: "5em" }
              //     : { fontSize: "38px" }
              // }
            >
              {intl.get("app-box-title")}
            </div>

            <ul className="list">
              <li>
                <img src="/assets/images/home/app-book.svg" alt="app" />
                <Link to="/application" className="link">
                  {intl.get("app-box-list1")}
                </Link>
              </li>
              <li>
                <img src="/assets/images/home/app-intelligence.svg" alt="app" />
                <Link to="/application" className="link">
                  {intl.get("app-box-list2")}
                </Link>
              </li>
              <li>
                <img
                  src="/assets/images/home/app-protection-mask.svg"
                  alt="app"
                />
                <Link to="/application" className="link">
                  {intl.get("app-box-list3")}
                </Link>
              </li>
              <li>
                <img src="/assets/images/home/app-marker.svg" alt="app" />
                <Link to="/application" className="link">
                  {intl.get("app-box-list4")}
                </Link>
              </li>
              <li>
                <img src="/assets/images/home/app-training.svg" alt="app" />
                <Link to="/application" className="link">
                  {intl.get("app-box-list5")}
                </Link>
              </li>
              <li>
                <img
                  src="/assets/images/home/app-online_support.svg"
                  alt="app"
                />
                <Link to="/application" className="link">
                  {intl.get("app-box-list6")}
                </Link>
              </li>
            </ul>

            <div className="download">
              <a className="android">{intl.get("app-download-android")}</a>
              <a className="ios">{intl.get("app-download-ios")}</a>
              <div className="downapp">
                <div className="img">
                  <img
                    src="/assets/images/common/code-android-ios.jpg"
                    alt="android"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="phone">
            <img src="/assets/images/home/phone.png" alt="phone" />
          </div>
        </Layout>

        {/* 儿童 */}
        <div className="children" style={childStyle}>
          <div className="book">
            <img src="/assets/images/home/book.png" alt="book" />
          </div>

          <div className="info">
            <h3 className="title">{intl.getHTML("children-info-title")}</h3>

            <ul className="list">
              <li>
                <Link to="/" className="link">
                  {intl.get("children-list1")}
                </Link>
              </li>
              <li>
                <Link to="/" className="link">
                  {intl.get("children-list2")}
                </Link>
              </li>
              <li>
                <Link to="/" className="link">
                  {intl.get("children-list3")}
                </Link>
              </li>
              <li>
                <Link to="/" className="link">
                  {intl.get("children-list4")}
                </Link>
              </li>
              <li>
                <Link to="/" className="link">
                  {intl.get("children-list5")}
                </Link>
              </li>
              <li>
                <Link to="/" className="link">
                  {intl.get("children-list6")}
                </Link>
              </li>
              <li>
                <Link to="/" className="link">
                  {intl.get("children-list7")}
                </Link>
              </li>
            </ul>

            <a
              className="btn"
              href="https://weidian.com/item.html?p=iphone&itemID=1418312770254880631246&a=b&wfr=wxBuyerShare&distributorId=512421445&share_relation=63e6afa0edc87166_512421445_1"
            >
              {intl.get("children-btn")}
            </a>
          </div>
        </div>

        {/* 培训 */}
        <Layout className="train">
          <div className="explain">
            <div className="title">
              <h4>{intl.get("train-explain-title")}</h4>
            </div>
            <div className="desc">{intl.get("train-explain-desc")}</div>
          </div>

          <div className="lesson">
            <AwesomeSwiper
              className="box"
              ref={(ref) => (this.swiperRef = ref)}
              config={{
                slidesPerView: 3,
                spaceBetween: 20,
                preloadImages: false,
                lazy: true,
                speed: 500,
              }}
            >
              <div className="swiper-wrapper list">
                <div className="swiper-slide item">
                  <div className="back">
                    <div className="img">
                      <img src="/assets/images/home/lesson2.jpg" alt="icon" />
                    </div>

                    <div className="desc">
                      <h3 className="title">
                        {intl.get("train-lesson2-title")}
                      </h3>
                      <ul className="news">
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson2-new1")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson2-new2")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson2-new3")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson2-new4")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson2-new5")}
                          </Link>
                        </li>
                      </ul>

                      <Link to="/dreamc" className="more">
                        {intl.get("train-lesson-btn")} &gt;
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <div className="img">
                      <img src="/assets/images/home/lesson3.jpg" alt="icon" />
                    </div>

                    <div className="desc">
                      <h3 className="title">
                        {intl.get("train-lesson3-title")}
                      </h3>
                      <ul className="news">
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson3-new1")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson3-new2")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson3-new3")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson3-new4")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson3-new5")}
                          </Link>
                        </li>
                      </ul>

                      <Link to="/dreamit" className="more">
                        {intl.get("train-lesson-btn")} &gt;
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <div className="img2">
                      <img src="/assets/images/home/lesson4.jpg" alt="icon" />
                    </div>

                    <div className="desc">
                      <h3 className="title">
                        {intl.get("train-lesson1-title")}
                      </h3>
                      <ul className="news">
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson1-new1")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson1-new2")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson1-new3")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson1-new4")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson1-new5")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="link">
                            {intl.get("train-lesson1-new6")}
                          </Link>
                        </li>
                      </ul>

                      <Link to="/dreamfam" className="more">
                        {intl.get("train-lesson-btn")} &gt;
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </AwesomeSwiper>
          </div>
        </Layout>

        {/* 线下门诊 */}
        {/* <Layout className="offline">
                    <div className="explain">
                        <div className="title">
                            <h4>{intl.get('offline-explain-title')}</h4>
                        </div>
                        <div className="desc">
                            {intl.get('offline-explain-desc')}
                        </div>
                    </div>

                    <div className="position">
                        <div className="icon">
                            <img src="/assets/images/home/marker.svg" alt="icon" />
                        </div>
                        <div className="select">
                            <Select className="city" defaultValue="" style={{ width: 140,padding:0 }} bordered={false} onChange={this.ChangeCity}>
                                <Option value="">选择城市</Option>
                                <Option value="sh">{intl.get('offline-city1')}</Option>
                                <Option value="sh">{intl.get('offline-city2')}</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="local">
                        <AwesomeSwiper className="box" ref={ref => (this.swiperRef = ref)} config={{slidesPerView:2,preloadImages: false,lazy: true,speed: 500}}>
                            <div className="swiper-wrapper list">
                                <div className="swiper-slide item">
                                    <div className="back">
                                        <div className="img">
                                            <img src="/assets/images/home/local1.jpg" alt="icon" />
                                        </div>

                                        <div className="desc">
                                            <h3 className="title">{intl.get('offline-list1-title')}</h3>
                                            
                                            <div className="information">
                                                <div className="phone">
                                                    <div className="img">
                                                        <img src="/assets/images/home/icons-phone.png" alt="phone" />
                                                    </div>
                                                    <div className="text">
                                                        {intl.get('offline-list1-phone')}
                                                    </div>
                                                </div>

                                                <div className="marker">
                                                    <div className="img">
                                                        <img src="/assets/images/home/icons-marker.png" alt="phone" />
                                                    </div>
                                                    <div className="text">
                                                        {intl.get('offline-list1-address')}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide item">
                                    <div className="back">
                                        <div className="img">
                                            <img src="/assets/images/home/local2.jpg" alt="icon" />
                                        </div>

                                        <div className="desc">
                                            <h3 className="title">{intl.get('offline-list2-title')}</h3>
                                            
                                            <div className="information">
                                                <div className="phone">
                                                    <div className="img">
                                                        <img src="/assets/images/home/icons-phone.png" alt="phone" />
                                                    </div>
                                                    <div className="text">
                                                        {intl.get('offline-list2-phone')}
                                                    </div>
                                                </div>

                                                <div className="marker">
                                                    <div className="img">
                                                        <img src="/assets/images/home/icons-marker.png" alt="phone" />
                                                    </div>
                                                    <div className="text">
                                                        {intl.get('offline-list2-address')}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination offlinePage"></div>
                        </AwesomeSwiper>
                    </div>
                </Layout> */}

        {/* 合作机构 */}
        <Layout className="cooperation">
          <div className="explain">
            <div className="title">
              <h4>
                <Link to="/mechanism">
                  {intl.get("cooperation-explain-title")}
                </Link>
              </h4>
            </div>
            <div className="desc">{intl.get("cooperation-explain-desc")}</div>
          </div>

          <div className="company">
            <AwesomeSwiper
              className="box"
              ref={(ref) => (this.swiperRef = ref)}
              config={{
                slidesPerView: 7,
                spaceBetween: 20,
                preloadImages: false,
                lazy: true,
                speed: 500,
                autoplay: true,
              }}
            >
              <div className="swiper-wrapper list">
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company1.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company2.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company3.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company4.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company5.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company6.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company7.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company8.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company9.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company10.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company11.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company12.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company13.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company14.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company15.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company16.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company17.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company18.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company19.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company20.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company21.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company22.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company23.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company24.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company25.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company26.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company27.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company28.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company29.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company30.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company31.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company32.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company33.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company34.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company35.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company36.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company37.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company38.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company39.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="swiper-slide item">
                  <div className="back">
                    <img
                      src="/assets/images/about/mechanism/company47.png"
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-pagination companyPage"></div>
            </AwesomeSwiper>
          </div>
          <a className="btn" href="/mechanism" target="_top">
            {intl.get("home-institution-btn")}
          </a>
        </Layout>

        {/* 最新资讯 */}
        <Layout className="article">
          <div className="explain">
            <div className="title">
              <h4>{intl.get("article-explain-title")}</h4>
            </div>
            <div className="desc">{intl.get("article-explain-desc")}</div>
          </div>

          {/* <div className="position"> */}
          {/* <div className="icon">
                            <img src="/assets/images/home/icons-news.png" alt="icon" />
                        </div> */}
          {/* <div className="select">
                            <Select className="city" defaultValue={this.state.newstype} style={{ width: 140,padding:0 }} bordered={false} onChange={this.ChangeNews}>
                                <Option value="all">全部资讯</Option>
                                <Option value="new">本周的最新活动</Option>
                                <Option value="recommend">本周推荐的文章和视频</Option>
                                <Option value="hot">本周推荐的热门问题的解答</Option>
                                <Option value="soon">本周哪些医院即将开展培训</Option>
                            </Select>
                        </div> */}
          {/* </div> */}

          <div className="local">
            <AwesomeSwiper
              className="box"
              ref={(ref) => (this.swiperRef = ref)}
              config={{
                slidesPerView: 4,
                preloadImages: false,
                lazy: true,
                speed: 500,
                spaceBetween: 40,
              }}
            >
              <div className="swiper-wrapper list">
                {/* <div className="swiper-slide item">
                                    <div className="card">
                                        <div className="thumb">
                                            <img src="/assets/images/home/activity1.png" alt="icon" />
                                        </div>

                                        <div className="front">
                                            <h3 className="title">把握机遇储备知识技能,言语-语言障碍...</h3>

                                            <p className="desc">
                                                言语-语言病理学是一门研究儿童与成人的言语-语言沟通障碍。
                                            </p>

                                            <div className="date">2021/1/4</div>
                                        </div>

                                        <div className="back">
                                            <h3 className="title">把握机遇储备知识技能,言语-语言障…</h3>

                                            <p className="desc">言语-语言病理学是一门研究儿童与成人的言语-语言沟通障碍。</p>

                                            <div className="date">2021/1/4</div>

                                            <div className="join">
                                                <img src="/assets/images/home/icons-join.png" alt="join" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                {NewsList()}
              </div>
              <div className="swiper-pagination bethelPage"></div>
            </AwesomeSwiper>
            <a className="btn" href="/article" target="_top">
              {intl.get("home-more-article-btn")}
            </a>
          </div>
        </Layout>
      </div>
    );
  }
}
