import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'

import './DreamIT.less'

import { Layout} from 'antd';

import { Helmet } from 'react-helmet'


const MsgStyle = {
    background:`url(/assets/images/train/dreamit/message.png) no-repeat left top`,
    backgroundSize:`100% 100%`
}


export default class DreamIT extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="dreamit">
            <Helmet>
              <title>{intl.get("dreamit-html-head-title")}</title>
              <meta
                name="keywords"
                content="1.Dream-IT婴幼儿语言沟通发育迟缓临床专业资格培训，国家级继教学分，婴幼儿早期语言发育，手把手指导，海内外讲师团队，婴幼儿语言沟通评估"
              />
              <meta
                name="description"
                content="美国培声DREAM-IT婴幼儿语言沟通发育迟缓临床专业资格培训赋能更多专业人士：科学有效地帮助语言沟通落后的婴幼儿；系统性学习婴幼儿语言沟通发育的专业理论知识；系统性学习婴幼儿语言沟通评估和干预的临床思路与技巧；学习操作国际认可的婴幼儿语言沟通测评工具；利用多个具体案例分析如何制定循证的个体化干预计划及设计干预活动。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img src="/assets/images/train/dream/banner.jpg" alt="banner" />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">
                    {intl.getHTML("dreamit-banner-title")}
                  </h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist1-zh")}</span>
                  <span>{intl.get("dreamit-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("fit")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist2-zh")}</span>
                  <span>{intl.get("dreamit-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("highlights")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist3-zh")}</span>
                  <span>{intl.get("dreamit-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("curriculum")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist4-zh")}</span>
                  <span>{intl.get("dreamit-menulist4-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("expert")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist5-zh")}</span>
                  <span>{intl.get("dreamit-menulist5-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("tools")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist6-zh")}</span>
                  <span>{intl.get("dreamit-menulist6-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("student")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist7-zh")}</span>
                  <span>{intl.get("dreamit-menulist7-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("signup")}
                  className="link"
                >
                  <span>{intl.get("dreamit-menulist8-zh")}</span>
                  <span>{intl.get("dreamit-menulist8-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="childrenContainer">
              <div className="modular">
                {/* 关于培训 */}
                <div id="introduction">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamit-introduction-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamit-introduction-explain-desc")}
                    </div>
                  </div>

                  <div className="describe">
                    {intl.get("dreamit-introduction-explain-describe")}
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/icon1.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamit-introduction-list1")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/icon2.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamit-introduction-list2")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/icon3.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamit-introduction-list3")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/icon4.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamit-introduction-list4")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/icon5.png"
                          alt="img"
                        />
                      </div>
                      <p className="desc">
                        {intl.get("dreamit-introduction-list5")}
                      </p>
                    </div>
                  </div>

                  <div className="content">
                    {intl.get("dreamit-introduction-explain-content")}
                  </div>

                  <div className="tabinfo">
                    <div className="title">
                      <h4>{intl.get("dreamit-introduction-tabinfo-title")}</h4>
                    </div>

                    <div className="info">
                      {intl.getHTML("dreamit-introduction-tabinfo-info")}
                    </div>

                    <div className="content">
                      {intl.get("dreamit-introduction-tabinfo-content")}
                    </div>
                  </div>
                </div>

                {/* 适合对象 */}
                <div id="fit">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamit-fit-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamit-fit-explain-desc")}
                    </div>
                  </div>

                  <div className="teacher">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher1.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamit-fit-teacher1")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher2.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamit-fit-teacher2")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher3.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamit-fit-teacher3")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher4.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamit-fit-teacher4")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher5.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamit-fit-teacher5")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher6.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamit-fit-teacher6")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/teacher7.png"
                          alt="img"
                        />
                      </div>
                      <div className="name">
                        {intl.get("dreamit-fit-teacher7")}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 课程亮点 */}
                <div id="highlights">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamit-highlights-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamit-highlights-explain-desc")}
                    </div>
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="number">
                        <span>01</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamit-highlights-list1-title")}
                      </div>
                      <div className="info">
                        {intl.getHTML("dreamit-highlights-list1-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>02</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamit-highlights-list2-title")}
                      </div>
                      <div className="info">
                        {intl.getHTML("dreamit-highlights-list2-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>03</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamit-highlights-list3-title")}
                      </div>
                      <div className="info">
                        {intl.getHTML("dreamit-highlights-list3-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>04</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamit-highlights-list4-title")}
                      </div>
                      <div className="info">
                        {intl.getHTML("dreamit-highlights-list4-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>05</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamit-highlights-list5-title")}
                      </div>
                      <div className="info">
                        {intl.getHTML("dreamit-highlights-list5-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="number">
                        <span>06</span>
                      </div>
                      <div
                        className={
                          intl.options.currentLocale === "en-US"
                            ? "title-en"
                            : "title-zh"
                        }
                      >
                        {intl.get("dreamit-highlights-list6-title")}
                      </div>
                      <div className="info">
                        {intl.getHTML("dreamit-highlights-list6-info")}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 课程列表 */}
                <div id="curriculum">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamit-curriculum-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamit-curriculum-explain-desc")}
                    </div>
                  </div>

                  <div className="tablist">
                    <div className="title">
                      <h4>{intl.get("dreamit-tablist-list1-title")}</h4>
                    </div>

                    <ol className="list">
                      {intl.getHTML("dreamit-tablist-list1-info")}
                    </ol>

                    <div className="title">
                      <h4>{intl.get("dreamit-tablist-list2-title")}</h4>
                    </div>
                    <ol className="list">
                      {intl.getHTML("dreamit-tablist-list2-info")}
                    </ol>
                  </div>
                </div>

                {/* 授课专家 */}
                <div id="expert">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamit-expert-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamit-expert-explain-desc")}
                    </div>
                  </div>

                  <div className="tablist">
                    <div className="title">
                      <h4>{intl.get("dreamit-expert-tablist-title")}</h4>
                    </div>

                    <div className="list">
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert1.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list1-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list1-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert2.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list2-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list2-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert3.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list3-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list3-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert4.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list4-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list4-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert5.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list5-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list5-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert6.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list6-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list6-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert7.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list7-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list7-info")}
                          </ul>
                        </div>
                      </div>
                      {/* <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert8.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list8-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list8-info")}
                          </ul>
                        </div>
                      </div> */}
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert12.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list10-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list10-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert9.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list9-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list9-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert11.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list11-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list11-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert16.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list16-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list16-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert17.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list15-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list15-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert15.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list12-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list12-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert14.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list13-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list13-info")}
                          </ul>
                        </div>
                      </div>
                      <div className="item">
                        <div className="background">
                          <img
                            src="/assets/images/train/dreamit/expert13.png"
                            alt="expert"
                          />
                        </div>
                        <div className="box">
                          <div className="name">
                            {intl.get("dreamit-expert-tablist-list14-name")}
                          </div>
                          <ul className="info">
                            {intl.getHTML("dreamit-expert-tablist-list14-info")}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 工具 */}
                <div id="tools">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamit-tools-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamit-tools-explain-desc")}
                    </div>
                  </div>

                  <div className="tablist">
                    <div className="title">
                      <h4>{intl.get("dreamit-tools-tablist1-title")}</h4>
                    </div>

                    <div className="describe">
                      {intl.get("dreamit-tools-tablist1-describe")}
                    </div>

                    <div className="title">
                      <h4>{intl.get("dreamit-tools-tablist2-title")}</h4>
                    </div>

                    <div className="list">
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon6.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist2-list1")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon7.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist2-list2")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon8.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist2-list3")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon9.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist2-list4")}
                        </p>
                      </div>
                    </div>

                    <div className="title">
                      <h4>{intl.get("dreamit-tools-tablist3-title")}</h4>
                    </div>

                    <div className="list2">
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon10.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist3-list1")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon11.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist3-list2")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon12.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist3-list3")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon13.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist3-list4")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon14.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist3-list5")}
                        </p>
                      </div>
                      <div className="item">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon15.png"
                            alt="img"
                          />
                        </div>
                        <p className="desc">
                          {intl.get("dreamit-tools-tablist3-list6")}
                        </p>
                      </div>
                    </div>

                    <div className="link">
                      <Link className="btn" to="/it">
                        {intl.get("dreamit-btn")}
                      </Link>
                    </div>
                  </div>
                </div>

                {/* 学员反馈 */}
                <div id="student">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("dreamit-student-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("dreamit-student-explain-desc")}
                    </div>
                  </div>

                  <div className="tablist">
                    <div className="item">
                      <div className="background">
                        <img
                          src="/assets/images/train/dreamit/student.png"
                          alt="student"
                        />
                      </div>
                      <div className="box">
                        <div className="content" style={MsgStyle}>
                          <span className="describe">
                            {intl.get("dreamit-student-tablist-list1-content")}
                          </span>
                        </div>
                        <div className="name">
                          {intl.get("dreamit-student-tablist-list1-name")}
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="background">
                        <img
                          src="/assets/images/train/dreamit/student.png"
                          alt="student"
                        />
                      </div>
                      <div className="box">
                        <div className="content" style={MsgStyle}>
                          <span className="describe">
                            {intl.get("dreamit-student-tablist-list2-content")}
                          </span>
                        </div>
                        <div className="name">
                          {intl.get("dreamit-student-tablist-list2-name")}
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="background">
                        <img
                          src="/assets/images/train/dreamit/student.png"
                          alt="student"
                        />
                      </div>
                      <div className="box">
                        <div className="content" style={MsgStyle}>
                          <span className="describe">
                            {intl.get("dreamit-student-tablist-list3-content")}
                          </span>
                        </div>
                        <div className="name">
                          {intl.get("dreamit-student-tablist-list3-name")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 报名咨询 */}
              <div className="realtime">
                <div id="signup">
                  <div className="left">
                    <div className="qrcode">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/qrcodeIT.jpeg"
                          alt="qrcode"
                        />
                      </div>
                      <div className="tip">
                        {intl.get("dreamit-signup-left-tip")}
                      </div>
                    </div>
                    <div className="contact">
                      <div className="title">
                        <div className="img">
                          <img
                            src="/assets/images/train/dreamit/icon-msg.png"
                            alt="qrcode"
                          />
                        </div>
                        <span>{intl.get("dreamit-signup-left-title")}</span>
                      </div>
                      <div className="phone">
                        {intl.get("dreamit-signup-left-phone")}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="title">
                      <div className="img">
                        <img
                          src="/assets/images/train/dreamit/icon-post.png"
                          alt="qrcode"
                        />
                      </div>
                      <span>{intl.get("dreamit-signup-right-title")}</span>
                    </div>
                    <div className="phone">
                      {intl.get("dreamit-signup-right-phone")}
                    </div>
                    <div className="phone">
                      {intl.get("dreamit-signup-right-email")}
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        );
    }
}