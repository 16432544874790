import React, { Component } from "react";
// import {Link} from 'react-router-dom'
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";

import "./Children.less";

import { Layout } from "antd";

export default class Children extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  render() {
    return (
      <div id="children">
        <Helmet>
          <title>{intl.get("children-html-head-title")}</title>
          <meta
            name="keywords"
            content="儿童语言家庭干预，家庭语言干预，早期家庭干预，家长语言培训"
          />
          <meta
            name="description"
            content="美国培声儿童语言家庭干预指导是以家庭干预指导内容以家庭为中心设计，培声言语语言病理专家在课程的八周中会为孩子进行的一对一全面评估、制定个体化康复计划，手把手指导家长在家进行课程中的干预技巧、带领家长参与个体化的小组讨论。根据孩子的DREAM-IT测评结果，家长也会通过培声培伴APP获取由培声海外专家团队研发的个体化的互动游戏干预指导视频，从视频中获得方向性与系统性的指引。每期课程仅招收6-8为家长，全程由拥有北美与英国认证，具有海外硕士以上学历的言语语言病理师亲自授课答疑。每堂课，专家会为家长进行一对一的个体化视频反馈，让家长获得最适合孩子的干预目标与计巧。"
          />
        </Helmet>

        {/* banner图 */}
        <Layout className="SingleBanner">
          <div className="img">
            <img
              src="/assets/images/contact/children/banner.png"
              alt="banner"
            />
          </div>
          <div className="box">
            <div className="content">
              <h3 className="title">{intl.getHTML("children-banner-title")}</h3>
            </div>
          </div>
        </Layout>

        {/* 导航 */}
        <Layout className="menulist">
          <div className="navlist">
            <div
              onClick={() => this.scrollToAnchor("introduction")}
              className="link"
            >
              <span>{intl.get("children-menulist1-zh")}</span>
              <span>{intl.get("children-menulist1-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("pain")} className="link">
              <span>{intl.get("children-menulist2-zh")}</span>
              <span>{intl.get("children-menulist2-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("highlights")}
              className="link"
            >
              <span>{intl.get("children-menulist3-zh")}</span>
              <span>{intl.get("children-menulist3-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("book")} className="link">
              <span>{intl.get("children-menulist4-zh")}</span>
              <span>{intl.get("children-menulist4-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("form")} className="link">
              <span>{intl.get("children-menulist5-zh")}</span>
              <span>{intl.get("children-menulist5-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("expert")} className="link">
              <span>{intl.get("children-menulist6-zh")}</span>
              <span>{intl.get("children-menulist6-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("case")} className="link">
              <span>{intl.get("children-menulist7-zh")}</span>
              <span>{intl.get("children-menulist7-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("problem")}
              className="link"
            >
              <span>{intl.get("children-menulist8-zh")}</span>
              <span>{intl.get("children-menulist8-en")}</span>
            </div>
          </div>
        </Layout>

        <Layout className="childrenContainer">
          <div className="modular">
            {/* 简介 */}
            <div id="introduction">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-introduction-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-introduction-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="back">
                  <img
                    src="/assets/images/contact/children/introduction-back.png"
                    alt="back"
                  />
                </div>

                <div className="desc">
                  {intl.getHTML("children-introduction-explain-content")}
                </div>
              </div>
            </div>

            {/* 痛点 */}
            <div id="pain">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-pain-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-pain-explain-desc")}
                </div>
              </div>

              <div className="list">
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/pain1.png"
                      alt="pain1"
                    />
                  </div>
                  <div className="title">{intl.get("children-pain-list1")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/pain2.png"
                      alt="pain2"
                    />
                  </div>
                  <div className="title">{intl.get("children-pain-list2")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/pain3.png"
                      alt="pain3"
                    />
                  </div>
                  <div className="title">{intl.get("children-pain-list3")}</div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/pain4.png"
                      alt="pain4"
                    />
                  </div>
                  <div className="title">{intl.get("children-pain-list4")}</div>
                </div>
              </div>
            </div>

            {/* 亮点 */}
            <div id="highlights">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-highlights-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-highlights-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="item">
                  <div className="icon">
                    <img
                      src="/assets/images/contact/children/highlights1.png"
                      alt="icon"
                    />
                  </div>
                  <h4 className="title">
                    {intl.get("children-highlights-list1-title")}
                  </h4>
                  <div className="desc">
                    {intl.getHTML("children-highlights-list1-info")}
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img
                      src="/assets/images/contact/children/highlights2.png"
                      alt="icon"
                    />
                  </div>
                  <h4 className="title">
                    {intl.get("children-highlights-list2-title")}
                  </h4>
                  <div className="desc">
                    {intl.get("children-highlights-list2-info")}
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img
                      src="/assets/images/contact/children/highlights3.png"
                      alt="icon"
                    />
                  </div>
                  <h4 className="title">
                    {intl.get("children-highlights-list3-title")}
                  </h4>
                  <div className="desc">
                    {intl.get("children-highlights-list3-info")}
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img
                      src="/assets/images/contact/children/highlights4.png"
                      alt="icon"
                    />
                  </div>
                  <h4 className="title">
                    {intl.get("children-highlights-list4-title")}
                  </h4>
                  <div className="desc">
                    {intl.get("children-highlights-list4-info")}
                  </div>
                </div>
              </div>
            </div>

            {/* 用书 */}
            <div id="book">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-book-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-book-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="back">
                  <img
                    src="/assets/images/contact/children/book.png"
                    alt="back"
                  />
                </div>

                <div className="desc">
                  {intl.getHTML("children-book-explain-content")}
                </div>
              </div>
            </div>

            {/* 形式与内容 */}
            <div id="form">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-form-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-form-explain-desc")}
                </div>
              </div>

              <div className="content">
                {intl.getHTML("children-form-explain-content")}
              </div>
            </div>

            {/* 专家介绍 */}
            <div id="expert">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-expert-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-expert-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/expert1.png"
                      alt="img"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      {intl.get("team-expert-list1-name")}
                    </div>
                    <ul className="list">
                      {intl.getHTML("team-teamlist1-list")}
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/expert2.png"
                      alt="img"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      {intl.get("team-teamlist2-name")}
                    </div>
                    <ul className="list">
                      {intl.getHTML("team-teamlist2-list")}
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/expert3.png"
                      alt="img"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      {intl.get("team-teamlist3-name")}
                    </div>
                    <ul className="list">
                      {intl.getHTML("team-teamlist3-list")}
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/expert4.png"
                      alt="img"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      {intl.get("team-teamlist13-name")}
                    </div>
                    <ul className="list">
                      {intl.getHTML("team-teamlist13-list")}
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/expert5.png"
                      alt="img"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      {intl.get("team-teamlist12-name")}
                    </div>
                    <ul className="list">
                      {intl.getHTML("team-teamlist12-list")}
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/expert6.png"
                      alt="img"
                    />
                  </div>
                  <div className="box">
                    <div className="title">
                      {intl.get("team-teamlist14-name")}
                    </div>
                    <ul className="list">
                      {intl.getHTML("team-teamlist14-list")}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* 往期案例 */}
            <div id="case">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-case-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-case-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="card">
                  <img src={intl.get("children-case-explain-img")} alt="card" />
                </div>
                <div className="desc">
                  {intl.getHTML("children-case-explain-content")}
                </div>
                <div className="pages">
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/case1.png"
                      alt="case1"
                    />
                  </div>
                  <div className="img">
                    <img
                      src="/assets/images/contact/children/case2.png"
                      alt="case2"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* 常见问题 */}
            <div id="problem">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("children-problem-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("children-problem-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="item">
                  <p className="title">
                    {intl.getHTML("children-problem-list1-title")}
                  </p>
                  <p className="desc">
                    {intl.getHTML("children-problem-list1-desc")}
                  </p>
                </div>
                {intl.get("children-problem-list2-title") ? (
                  <div className="item">
                    <p className="title">
                      {intl.getHTML("children-problem-list2-title")}
                    </p>
                    <p className="desc">
                      {intl.getHTML("children-problem-list2-desc")}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="item">
                      <p className="title">
                        {intl.getHTML("children-problem-list3-title")}
                      </p>
                      <p className="desc">
                        {intl.getHTML("children-problem-list3-desc")}
                      </p>
                    </div> */}
              </div>
            </div>
          </div>

          {/* 报名 */}
          <div id="signup">
            <div className="left">
              <div className="qrcode">
                <div className="img">
                  <img
                    src="/assets/images/contact/children/wxcode.png"
                    alt="img"
                  />
                </div>
                <div className="name">
                  {intl.get("children-signup-left-qrcode")}
                </div>
              </div>

              <div className="short">
                {intl.get("children-signup-left-short")}
                <br />
                {intl.get("children-signup-left-phone")}
              </div>
            </div>

            <div className="center"></div>

            <div className="right">
              <a
                href="https://ipp.ourbethel.cn/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                {intl.get("children-signup-right-title")}
              </a>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}
