import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
// import { Helmet } from 'react-helmet'

import './Project.less'

import { Layout} from 'antd';


export default class Project extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
            <div id="project">
                {/* banner图 */}
                <Layout className="SingleBanner">
                    <div className="img">
                        <img src="/assets/images/about/mechanism/banner.png" alt="banner" />
                    </div>
                    <div className="box">
                        <div className="content">
                            <h3 className="title">{intl.getHTML('project-banner-title')}</h3>
                        </div>
                    </div>
                </Layout>

                <Layout className="comlist">
                    {/* 导航 */}
                    <div className="navlist">
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 'all' }}}>
                                {intl.get('project-navlist1')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 'its' }}}>
                                {intl.get('project-navlist2')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 's' }}}>
                                {intl.get('project-navlist3')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 'it' }}}>
                                {intl.get('project-navlist4')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 'c' }}}>
                                {intl.get('project-navlist5')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 'fam' }}}>
                                {intl.get('project-navlist6')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 'teach' }}}>
                                {intl.get('project-navlist7')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={{pathname:'/mechanism' , query : { tool_id : 'education' }}}>
                                {intl.get('project-navlist8')}    
                            </Link>
                        </div>
                        <div className="link">
                            <Link className="link" to="/project">
                                {intl.get('project-navlist9')}   
                            </Link>
                        </div>
                    </div>

                    <div className="prolist">
                        <div className="item">
                            <div className="title">
                                <div className="icon">
                                    <img src="/assets/images/about/project/icons1.png" alt="icons" />
                                </div>
                                <div className="name">{intl.get('project-prolist1-title')}   </div>
                            </div>

                            <div className="desc">
                                {intl.getHTML('project-prolist1-desc')}
                            </div>

                            <div className="qrcode">
                                <img src="/assets/images/about/project/qrcode1.png" alt="qrcode" />
                            </div>
                        </div>

                        <div className="item">
                            <div className="title">
                                <div className="icon">
                                    <img src="/assets/images/about/project/icons1.png" alt="icons" />
                                </div>
                                <div className="name">{intl.get('project-prolist2-title')}   </div>
                            </div>

                            <div className="desc">
                                {intl.getHTML('project-prolist2-desc')}
                            </div>

                            <div className="qrcode">
                                <img src="/assets/images/about/project/qrcode2.png" alt="qrcode" />
                            </div>
                        </div>

                        

                    </div>
                </Layout>
            </div>
        )
    }
}