import React,{Component} from 'react'

export default class About extends Component{
    render()
    {
        return (
            <div id="about">
                
            </div>
        )
    }
}