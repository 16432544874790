import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './Train.less'

import { Layout} from 'antd';

// import * as train from '../../api/train'
import * as home from '../../api/home'

import moment from 'moment'
import intl from 'react-intl-universal'


export default class Train extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            camplist:[]
        }
    }
    componentDidMount()
    {
        this.camplist()
    }
    camplist = async () => {

        var data = {
            page_size:1000,
            type:3
        }

        await home.news(data).then(success => {
            console.log(success)
            // return false
            this.setState({
                camplist:success.items
            })
        })
    }
    toDetail(info, e)
    {
        this.props.history.push({pathname:'/traininfo', query:{ id: info._id}})
    }
    render()
    {
        //培训招募
        let CampList = () => {
            let arr = []

            this.state.camplist.map((item, index) => {
                arr.push(
                    <div className="item" key={index} onClick={this.toDetail.bind(this, item)}>
                        <div className="left">
                            {/* <div className="title">{item.name}</div> */}
                            {/* <div className="desc">{intl.get('train-list-author')}：{item.author.author}</div> */}
                            <div className="title">{item.title}</div>
                            <div className="desc">{item.summary}</div>
                        </div>
                        <div className="right">
                            <div className="time">{intl.get('train-list-time')}</div>
                            <div className="line"></div>
                            <div className="date">{moment(item.registration_end_at).format('YYYY/MM/DD')}</div>
                        </div>
                    </div>
                )
                return arr
            })

            return arr
        }

        return (
          <div id="train">
            <Helmet>
              <title>{intl.get('train-html-head-title')}</title>
              <meta
                name="keywords"
                content="培训报名，最新培训，Dream-IT婴幼儿语言沟通发育迟缓临床专业资格培训，Dream-C儿童语言发育障碍临床专业资格培训，培声儿童语言家庭培训师资格培训，培声证书查询"
              />
              <meta
                name="description"
                content="为更好提升专业人士的儿童言语-语言障碍的诊治水平，美国培声国内外团队参考北美儿童言语语言硕士课程，打造了Dream-IT婴幼儿语言沟通发育迟缓临床专业资格培训，Dream-C儿童语言发育障碍临床专业资格培训，培声儿童语言家庭培训师资格培训，MENT培训，以及针对早教老师的儿童语言发育教师课程。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img src="/assets/images/major/train/banner.png" alt="banner" />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">
                    {intl.getHTML("train-banner-title")}
                  </h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <Link className="link" to="/train">
                  <span>{intl.get("train-menu-title1")}</span>
                  <span>{intl.get("train-menu-desc1")}</span>
                </Link>
                <Link className="link" to="/cert">
                  <span>{intl.get("train-menu-title2")}</span>
                  <span>{intl.get("train-menu-desc2")}</span>
                </Link>
              </div>
            </Layout>

            <Layout className="trainContainer">
              <div className="trainList">{CampList()}</div>
            </Layout>
          </div>
        );
    }
}