import React,{Component} from 'react'
// import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './Offline.less'

import { Layout} from 'antd';


export default class Offline extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="offline">
            <Helmet>
              {/* <title>线下一对一干预治疗</title> */}
              <title>{intl.get("offilne-html-head-title")}</title>
              <meta
                name="keywords"
                content="3.培声儿童听力语言沟通中心，一对一咨询，一对一语言康复咨询，专家一对一干预，一对一专家指导"
              />
              <meta
                name="description"
                content="美国培声线下一对一咨询是专家与孩子面对面互动，并在互动中指导家长如何开展针对性的活动，促进孩子的语言发展。同时，鼓励家长在场学习干预的方法和活动，并将干预延伸到家庭的日常活动中。课堂上，治疗师会根据前期制定的语言目标，设计相应的结构化游戏互动，以支持孩子达到干预目标，从而循序渐进地达到短期和长期语言目标。每节课后，治疗师向家长发送详细的干预指导总结及家庭干预建议。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img
                  src="/assets/images/contact/children/banner.png"
                  alt="banner"
                />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">{intl.get("offline-banner-title")}</h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("offline-menulist1-zh")}</span>
                  <span>{intl.get("offline-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("highlights")}
                  className="link"
                >
                  <span>{intl.get("offline-menulist2-zh")}</span>
                  <span>{intl.get("offline-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("case")}
                  className="link"
                >
                  <span>{intl.get("offline-menulist3-zh")}</span>
                  <span>{intl.get("offline-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("problem")}
                  className="link"
                >
                  <span>{intl.get("offline-menulist4-zh")}</span>
                  <span>{intl.get("offline-menulist4-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="offlineContainer">
              <div className="modular">
                {/* 简介 */}
                <div id="introduction">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("offline-introduction-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("offline-introduction-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="back">
                      <img
                        src="/assets/images/contact/offline/introduction-back.png"
                        alt="back"
                      />
                    </div>

                    <div className="desc">
                      {intl.get("offline-introduction-explain-content")}
                    </div>
                  </div>
                </div>

                {/* 亮点 */}
                <div id="highlights">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("offline-highlights-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("offline-highlights-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="item">
                      <div className="icon">
                        <img
                          src="/assets/images/contact/offline/highlights1.png"
                          alt="icon"
                        />
                      </div>
                      <h4 className="title">
                        {intl.get("offline-highlights-list1-title")}
                      </h4>
                      <div className="desc">
                        {intl.get("offline-highlights-list1-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img
                          src="/assets/images/contact/offline/highlights2.png"
                          alt="icon"
                        />
                      </div>
                      <h4 className="title">
                        {intl.get("offline-highlights-list2-title")}
                      </h4>
                      <div className="desc">
                        {intl.get("offline-highlights-list2-info")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img
                          src="/assets/images/contact/offline/highlights3.png"
                          alt="icon"
                        />
                      </div>
                      <h4 className="title">
                        {intl.get("offline-highlights-list3-title")}
                      </h4>
                      <div className="desc">
                        {intl.get("offline-highlights-list3-info")}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 往期案例 */}
                <div id="case">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("offline-case-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("offline-case-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="card">
                      <img
                        src={intl.get("offline-case-explain-img")}
                        alt="card"
                      />
                    </div>
                    <div className="desc">
                      {intl.getHTML("offline-case-explain-content")}
                    </div>
                  </div>
                </div>

                {/* 常见问题 */}
                <div id="problem">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("offline-problem-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("offline-problem-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="item">
                      <p className="title">
                        {intl.getHTML("offline-problem-list1-title")}
                      </p>
                      <p className="desc">
                        {intl.getHTML("offline-problem-list1-desc")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* 报名 */}
              <div id="signup">
                <div className="left">
                  <div className="qrcode">
                    <div className="img">
                      <img
                        src="/assets/images/contact/children/wxcode.png"
                        alt="img"
                      />
                    </div>
                    <div className="name">
                      {intl.get("offline-signup-left-qrcode")}
                    </div>
                  </div>

                  <div className="short">
                    {intl.get("offline-signup-left-short")}
                    <br />
                    {intl.get("offline-signup-left-phone")}
                  </div>
                </div>

                <div className="center"></div>

                <div className="right">
                  <a
                    className="btn"
                    href="https://ipp.ourbethel.cn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.get("offline-signup-right-title")}
                  </a>
                </div>
              </div>
            </Layout>
          </div>
        );
    }
}