/**
 * 统一引入
 */
const ModulesFile = require.context('./',true,/\.json$/)

let LangList = {}

for(var item of ModulesFile.keys())
{
  var list = ModulesFile(item)

  //合并对象 循环多次合并
  LangList = Object.assign(LangList,list)
}

export default LangList