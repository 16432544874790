import React,{Component} from 'react'
// import intl from 'react-intl-universal'

import './LectureInfo.less'

import { Layout, Modal} from 'antd'

// import * as article from '../../../api/article'


export default class LectureInfo extends Component{
    constructor(props)
    {
        super(props)

        var info = this.props.location.query ? this.props.location.query.info : null

        if(!info)
        {
            // this.props.history.push('/')
            this.props.history.goBack()
            return false
        }

        this.state = {
            title:info.title,
            video_id:info.video_id.replace(/^\s+|\s+$/g,"")
        }
    }
    componentDidMount() 
    {
        if(!window.polyvPlayer){
            this.loadScript('/assets/js/player.js')
            .then(() =>{
                this.loadPlayer();
            });
        }else
        {
            this.loadPlayer();
        }
    }
    componentWillUnmount() 
    {
        if(this.player){
          this.player.destroy();
        }
    }
    loadPlayer() 
    {
        // console.log(this.state.video_id)

        this.player = window.polyvPlayer({
          wrap: '.player',
          width: '100%',
          height: '100%',
          watchStartTime: 0,
          watchEndTime: 180,
          vid: this.state.video_id,
        });

        window.s2j_onPlayOver = function()
        {
            Modal.warning({
                title: '温馨提醒',
                // content: '下载App购买观看完整视频',
                content: (
                    <div className="ModalQrcode">
                        <div className="img">
                            <img src="/assets/images/common/code-android-ios.jpg" alt="android" />
                        </div>
                        <div className="name">下载App购买观看完整视频</div>
                    </div>
                ),
            });
        }
    }
    loadScript(src) {
        const headElement = document.head || document.getElementsByTagName('head')[0];
        const _importedScript = {};
    
        return new Promise((resolve, reject) => {
          if (src in _importedScript) {
            resolve();
            return;
          }
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.onerror = err => {
            headElement.removeChild(script);
            reject(new URIError(`The Script ${src} is no accessible.`));
          }
          script.onload = () => {
            _importedScript[src] = true;
            resolve();
          }
          headElement.appendChild(script);
          script.src = src;
        })
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
            <div id="detail">
                {/* banner图 */}
                <Layout className="SingleBanner">
                    <div className="img">
                        <img src="/assets/images/about/introduction/banner.png" alt="banner" />
                    </div>
                </Layout>


                {/* 详细内容 */}
                <Layout id="detailed">
                    <div className="explain">
                        <div className="title">
                            <h4>{this.state.title}</h4>
                        </div>
                    </div>

                    <div className="player"></div>

                    {/* <div className="content" dangerouslySetInnerHTML = {{ __html:this.state.content }}></div> */}
                </Layout>
            </div>
        )
    }
}