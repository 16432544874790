import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './IT.less'

import { Layout} from 'antd';


export default class IT extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
    }
    render()
    {
        return (
          <div id="IT">
            <Helmet>
              <title>Dream-IT</title>
              <meta
                name="keywords"
                content="语言测评，婴幼儿语言沟通测评，婴幼儿语言全面评估，婴幼儿语言评估，语言测试报告"
              />
              <meta
                name="description"
                content="美国培声DREAM-IT梦想婴幼儿语言沟通测评（以下简称“DREAM-IT”）是专为0-3岁中国大陆婴幼儿设计的全面语言沟通测评工具。DREAM-IT的测评题目全面，详尽，并且含有丰富的实例帮助家长理解并参与作答。测评内容覆盖婴幼儿语言沟通发展的四个能区，包括：语言理解、语言表达、社交沟通和认知玩耍，后两个能区是婴幼儿语言发展必备的和紧不可分的基础技能。DREAM-IT可以帮助临床专业人士详细了解婴幼儿语言沟通相关能力的发展进程和特点，并通过培声研发的人工智能技术，获取近期的干预目标，以制定详细的干预计划。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img src="/assets/images/language/IT/banner.png" alt="banner" />
              </div>
              <div className="box">
                <div className="thumb">
                  <img src="/assets/images/language/IT/thumb.png" alt="thumb" />
                </div>
                <div className="content">
                  <h3
                    className="title"
                    style={
                      intl.options.currentLocale === "en-US"
                        ? { fontSize: "2em", lineHeight: "40px" }
                        : { fontSize: "2.5em" }
                    }
                  >
                    {intl.getHTML("IT-banner-title")}
                  </h3>
                  <p className="desc">{intl.get("IT-banner-desc")}</p>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("IT-menulist1-zh")}</span>
                  <span>{intl.get("IT-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("application")}
                  className="link"
                >
                  <span>{intl.get("IT-menulist2-zh")}</span>
                  <span>{intl.get("IT-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("features")}
                  className="link"
                >
                  <span>{intl.get("IT-menulist3-zh")}</span>
                  <span>{intl.get("IT-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("international")}
                  className="link"
                >
                  <span>{intl.get("IT-menulist4-zh")}</span>
                  <span>{intl.get("IT-menulist4-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("case")}
                  className="link"
                >
                  <span>{intl.get("IT-menulist5-zh")}</span>
                  <span>{intl.get("IT-menulist5-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("tool")}
                  className="link"
                >
                  <span>{intl.get("IT-menulist6-zh")}</span>
                  <span>{intl.get("IT-menulist6-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="ITContainer">
              <div className="modular">
                {/* 简介 */}
                <div id="introduction">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-explain-title")}</h4>
                    </div>
                    <div className="desc">{intl.get("IT-explain-desc")}</div>
                  </div>

                  <div className="content">
                    <div className="desc">{intl.get("IT-explain-content")}</div>

                    <div className="back">
                      <img
                        src="/assets/images/language/IT/introduction-back.png"
                        alt="back"
                      />
                    </div>
                  </div>
                </div>

                {/* 应用场景 */}
                <div id="application">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-application-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("IT-application-explain-desc")}
                    </div>
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/IT/application1.png"
                          alt="application1"
                        />
                      </div>
                      <div className="box">
                        <div className="name">
                          {intl.get("IT-application-list1-name")}
                        </div>
                        <div className="info">
                          <ol>{intl.getHTML("IT-application-list1-info")}</ol>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/IT/application2.png"
                          alt="application2"
                        />
                      </div>
                      <div className="box">
                        <div className="name">
                          {intl.get("IT-application-list2-name")}
                        </div>
                        <div className="info">
                          <ol>{intl.getHTML("IT-application-list2-info")}</ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 特点与优势 */}
                <div id="features">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-features-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("IT-features-explain-desc")}
                    </div>
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/IT/icon1.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("IT-features-list1-title")}
                      </div>
                      <p className="desc">
                        {intl.get("IT-features-list1-desc")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/IT/icon2.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("IT-features-list2-title")}
                      </div>
                      <p className="desc">
                        {intl.get("IT-features-list2-desc")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/IT/icon3.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("IT-features-list3-title")}
                      </div>
                      <p className="desc">
                        {intl.get("IT-features-list3-desc")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/IT/icon4.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("IT-features-list4-title")}
                      </div>
                      <p className="desc">
                        {intl.get("IT-features-list4-desc")}
                      </p>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/IT/icon5.png"
                          alt="img"
                        />
                      </div>
                      <div className="title">
                        {intl.get("IT-features-list5-title")}
                      </div>
                      <p className="desc">
                        {intl.get("IT-features-list5-desc")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* 国际标准 */}
                <div id="international">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-internationalIT-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("IT-internationalIT-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("IT-internationalIT-explain-content")}
                    </div>

                    <div className="back">
                      <img
                        src="/assets/images/language/IT/international1.png"
                        alt="back"
                      />
                      <img
                        src="/assets/images/language/IT/international2.png"
                        alt="back"
                      />
                    </div>
                  </div>
                </div>

                {/* 临床案例与测评报告 */}
                <div id="case">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-case-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("IT-case-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="card">
                      <img src={intl.get("IT-case-img")} alt="card" />
                    </div>
                    <div className="desc">
                      {intl.getHTML("IT-case-explain-content")}
                    </div>
                  </div>
                </div>

                {/* 语言测评工具购买 */}
                <div id="tool">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-tool-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("IT-tool-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("IT-tool-explain-content")}
                      <div className="notice">
                        {intl.get("IT-tool-explain-notice")}
                      </div>
                      <div className="qrcode">
                        <img
                          src="/assets/images/common/contact-qrcode.jpeg"
                          alt="qrcode"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* 了解更多 */}
                <div id="learnmore">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-learnmore1-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("IT-learnmore1-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("IT-learnmore1-explain-content")}
                    </div>

                    <div className="link">
                      <Link className="btn" to="/dreamit">
                        {intl.get("IT-learnmore1-explain-btn")}
                      </Link>
                    </div>
                  </div>
                </div>

                {/* 了解更多 */}
                <div id="learnmore">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("IT-learnmore2-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("IT-learnmore2-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("IT-learnmore2-explain-content")}
                    </div>

                    <div className="link">
                      <Link
                        className="btn"
                        to={{
                          pathname: "/mechanism",
                          query: { tool_id: "it" },
                        }}
                      >
                        {intl.get("IT-learnmore2-explain-btn")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        );
    }
}