import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'

import './Company.less'

import { Layout} from 'antd';

import { Helmet } from 'react-helmet'


export default class Home extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            newskey:'en',  //en zh asha 
        }
    }
    tabs(e, action)
    {
        this.setState({newskey:action})
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="company">
            <Helmet>
              <title>{intl.get("campany-html-head-title")}</title>
              <meta
                name="keywords"
                content="3.培声科研成果，培声发表的学术期刊，培声媒体采访"
              />
              <meta
                name="description"
                content="美国培声科研团队陆续推出两款婴幼儿和儿童语言筛查工具Dream-ITS和Dream-S，两款全面评估工具Dream-IT与Dream-C，评估消毒，信度和临床应用文献发表于国内外权威中英文周刊。受邀参与电视台，电台进行语言发育相关问题的科普，刘雪曼博士作为客座教授为青岛和昆明的大学生进行授课。 "
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img
                  src="/assets/images/about/company/banner.png"
                  alt="banner"
                />
              </div>
              <div className="box">
                <div className="content">
                  <h3 className="title">
                    {intl.getHTML("company-banner-title")}
                  </h3>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("scientific")}
                  className="link"
                >
                  <span>{intl.get("company-menulist1-zh")}</span>
                  <span>{intl.get("company-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("academic")}
                  className="link"
                >
                  <span>{intl.get("company-menulist2-zh")}</span>
                  <span>{intl.get("company-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("media")}
                  className="link"
                >
                  <span>{intl.get("company-menulist3-zh")}</span>
                  <span>{intl.get("company-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("award")}
                  className="link"
                >
                  <span>{intl.get("company-menulist4-zh")}</span>
                  <span>{intl.get("company-menulist4-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="comlist">
              {/* 科研成果 */}
              <div id="scientific">
                <div className="explain">
                  <div className="title">
                    <h4>{intl.get("company-explain-title")}</h4>
                  </div>
                  <div className="desc">{intl.get("company-explain-desc")}</div>
                </div>

                <div className="list">
                  <div className="item">
                    <div className="left">
                      <div className="head">
                        <div className="sort">01</div>
                        <div className="title">
                          <div>
                            {intl.get("company-scientific-list1-title-en")}
                          </div>
                          <div>
                            {intl.get("company-scientific-list1-title-zh")}
                          </div>
                        </div>
                      </div>
                      <div className="desc">
                        {intl.get("company-scientific-list1-desc")}
                        <Link className="more" to="/its">
                          {" "}
                          {intl.get("campany-read-more-link-title")}&gt;
                        </Link>
                      </div>
                    </div>
                    <div className="right">
                      <img
                        src="/assets/images/about/company/thumb1.png"
                        alt="thumb"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="head">
                        <div className="sort">02</div>
                        <div className="title">
                          <div>
                            {intl.get("company-scientific-list2-title-en")}
                          </div>
                          <div>
                            {intl.get("company-scientific-list2-title-zh")}
                          </div>
                        </div>
                      </div>
                      <div className="desc">
                        {intl.get("company-scientific-list2-desc")}
                        <Link className="more" to="/it">
                          {" "}
                          {intl.get("campany-read-more-link-title")}&gt;{" "}
                        </Link>
                      </div>
                    </div>
                    <div className="right">
                      <img
                        src="/assets/images/about/company/thumb2.png"
                        alt="thumb"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="head">
                        <div className="sort">03</div>
                        <div className="title">
                          <div>
                            {intl.get("company-scientific-list3-title-en")}
                          </div>
                          <div>
                            {intl.get("company-scientific-list3-title-zh")}
                          </div>
                        </div>
                      </div>
                      <div className="desc">
                        {intl.get("company-scientific-list3-desc")}
                        <Link className="more" to="/s">
                          {intl.get("campany-read-more-link-title")}&gt;
                        </Link>
                      </div>
                    </div>
                    <div className="right">
                      <img
                        src="/assets/images/about/company/thumb3.png"
                        alt="thumb"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="head">
                        <div className="sort">04</div>
                        <div className="title">
                          <div>
                            {intl.get("company-scientific-list4-title-en")}
                          </div>
                          <div>
                            {intl.get("company-scientific-list4-title-zh")}
                          </div>
                        </div>
                      </div>
                      <div className="desc">
                        {intl.get("company-scientific-list4-desc")}
                        <Link className="more" to="/c">
                          {intl.get("campany-read-more-link-title")}&gt;
                        </Link>
                      </div>
                    </div>
                    <div className="right">
                      <img
                        src="/assets/images/about/company/thumb4.png"
                        alt="thumb"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="head">
                        <div className="sort">05</div>
                        <div className="title">
                          <div>
                            {intl.get("company-scientific-list5-title-en")}
                          </div>
                          <div>
                            {intl.get("company-scientific-list5-title-zh")}
                          </div>
                        </div>
                      </div>
                      <div className="desc">
                        {intl.get("company-scientific-list5-desc")}
                        <Link className="more" to="/children">
                          {intl.get("campany-read-more-link-title")}&gt;
                        </Link>
                      </div>
                    </div>
                    <div className="right">
                      <img
                        src="/assets/images/about/company/thumb5.jpg"
                        alt="thumb"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Layout>

            {/* 学术发表 */}
            <Layout id="academic">
              <div className="back">
                <img
                  src="/assets/images/about/company/academic.png"
                  alt="back"
                />
              </div>

              <div className="box">
                <div className="explain">
                  <div className="title">
                    <h4>{intl.get("company-academic-explain-title")}</h4>
                  </div>
                  <div className="desc">
                    {intl.get("company-academic-explain-desc")}
                  </div>
                </div>

                <div className="btnlist">
                  <button
                    className={this.state.newskey === "en" ? "active" : ""}
                    onClick={(e) => this.tabs(e, "en")}
                  >
                    {intl.get("company-academic-btnlist1")}
                  </button>
                  <button
                    className={this.state.newskey === "zh" ? "active" : ""}
                    onClick={(e) => this.tabs(e, "zh")}
                  >
                    {intl.get("company-academic-btnlist2")}
                  </button>
                  <button
                    className={this.state.newskey === "asha" ? "active" : ""}
                    onClick={(e) => this.tabs(e, "asha")}
                  >
                    {intl.get("company-academic-btnlist3")}
                  </button>
                </div>

                <div
                  className={
                    this.state.newskey === "en"
                      ? "newlist newlist-active"
                      : "newlist"
                  }
                >
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        An interprofessional team approach to the differential
                        diagnosis of children with language disorders
                      </div>
                      <div className="desc">
                        Pediatric Clinics, 65(1), 73-90.
                      </div>
                      <div className="position">
                        Liu, X. L., Zahrt, D. M., & Simms, M. D.
                      </div>
                    </div>
                    <div className="right">2018</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        A narrative evaluation of Mandarin-speaking children
                        with language impairment
                      </div>
                      <div className="desc">
                        Journal of Speech, Language, and Hearing
                        Research, 61(2), 345-359.
                      </div>
                      <div className="position">
                        Hao, Y., Sheng, L., Zhang, Y., Jiang, F., de Villiers,
                        J., Lee, W., & Liu, X. L.
                      </div>
                    </div>
                    <div className="right">2018</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Research to establish the validity, reliability, and
                        clinical utility of a comprehensive language assessment
                        of Mandarin.
                      </div>
                      <div className="desc">
                        Journal of Speech, Language, and Hearing Research,
                        60(3), 592-606.
                      </div>
                      <div className="position">
                        Liu, X., de Villiers, J., Ning, C., Rolfhus, E.,
                        Hutchings, T., Lee, W., ... & Zhang, Y. W.
                      </div>
                    </div>
                    <div className="right">2017</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        New language outcome measures for Mandarin-speaking
                        children with hearing loss
                      </div>
                      <div className="desc">
                        Journal of otology, 11(1), 24–32
                      </div>
                      <div className="position">
                        Liu, X., de Villiers, J., Lee, W., Ning, C., Rolfhus,
                        E., Hutchings, T., Jiang, F., & Zhang, Y
                      </div>
                    </div>
                    <div className="right">2016</div>
                  </div>
                </div>

                <div
                  className={
                    this.state.newskey === "zh"
                      ? "newlist newlist-active"
                      : "newlist"
                  }
                >
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        景林烨, 刘雪曼, 袁海英, & 苏蕾. (2020).
                        浅谈语言障碍的影响与危害
                      </div>
                      <div className="desc">
                        中国听力语言康复科学杂志, v.18;No.99(02), 9-12.
                      </div>
                      <div className="position">作者：刘雪曼</div>
                    </div>
                    <div className="right">2020</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        林媛媛, 章依文, 刘雪曼, & Lee Wendy. (2020).
                        家庭方言环境对儿童语言发育的影响. 
                      </div>
                      <div className="desc">
                        中国儿童保健杂志 2020年28卷8期, 849-853页, ISTIC CA.
                      </div>
                      <div className="position">作者：刘雪曼</div>
                    </div>
                    <div className="right">2020</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        马希瑞, 章依文, 林媛媛, 刘雪曼, Lee, & Wendy. (2019).
                        执行功能在语言障碍和多动/注意缺陷行为关系中的调节作用. 
                      </div>
                      <div className="desc">
                        中国儿童保健杂志, v.27;No.206(08), 18-21.
                      </div>
                      <div className="position">作者：刘雪曼</div>
                    </div>
                    <div className="right">2019</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        刘雪曼. (2019). 儿童语言障碍与语言评估. 
                      </div>
                      <div className="desc">
                        中国听力语言康复科学杂志, 17(03), 161-165.
                      </div>
                      <div className="position">
                        作者：马希瑞/章依文/林媛媛/刘雪曼/Lee/Wendy
                      </div>
                    </div>
                    <div className="right">2019</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        刘雪曼. (2015). 听障儿童干预和康复效果评估进展. 
                      </div>
                      <div className="desc">
                        中华耳科学杂志, v.13(04), 568-577.
                      </div>
                      <div className="position">
                        作者：林媛媛/章依文/刘雪曼/ Lee Wendy
                      </div>
                    </div>
                    <div className="right">2015</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        刘雪曼. (2012).
                        听障儿童干预和康复效果评估过程及评估工具的最新进展. 
                      </div>
                      <div className="desc">
                        中国听力语言康复科学杂 VOL.18 9-12 页
                      </div>
                      <div className="position">作者：刘雪曼</div>
                    </div>
                    <div className="right">2012</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        第八次全国听力学及嗓音言语医学暨第五次全国人工听觉技术学术会议
                      </div>
                      <div className="desc">第五次全国人工听觉技术学术会议</div>
                      <div className="position">作者：刘雪曼</div>
                    </div>
                    <div className="right">2012</div>
                  </div>
                </div>

                <div
                  className={
                    this.state.newskey === "asha"
                      ? "newlist newlist-active"
                      : "newlist"
                  }
                >
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Clinician-Guided Parent-Implemented Intervention for
                        Language Delays in China Using Innovative Technology.
                      </div>
                      <div className="desc">
                        Accepted by 2020 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        Liu, X., Lee, W., Hutchings, T., de Villiers, J.,
                        Rolfhus, E., Yao, L., Zhao, J., Jiang, F., Huim F., Sun,
                        S., Qiu, H., Qi, L., & Wang, X.
                      </div>
                    </div>
                    <div className="right">2020</div>
                  </div>

                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Efforts to Standardize the Differential Diagnosis of
                        Language Disorders Versus Other Disorders in China.
                      </div>
                      <div className="desc">
                        Presented at 2019 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        Liu, X., Simms, M., Zhang, Y., Hao, Y., Lee, W., Cheng,
                        Q., & Li, T.
                      </div>
                    </div>
                    <div className="right">2019</div>
                  </div>

                  <div className="item">
                    <div className="left">
                      <div className="title">
                        The Development of a Language Screener for Mandarin-
                        speaking Children.
                      </div>
                      <div className="desc">
                        De Villiers, J., Liu, X., Lee, W., Hutchings, T., &
                        Rolfhus, E.
                      </div>
                      <div className="position">
                        Presented at 2019 ASHA Convention in United States.
                      </div>
                    </div>
                    <div className="right">2019</div>
                  </div>

                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Cross Generational Perceptions of Suggested Strategies
                        for Children with Communication Disorders in China.
                      </div>
                      <div className="desc">
                        Presented at 2019 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        Malloy, R., Watson, J., Liu, X., & Lee, W.
                      </div>
                    </div>
                    <div className="right">2019</div>
                  </div>

                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Behavior Validity Study of Infant Toddler Screening in
                        China.
                      </div>
                      <div className="desc">
                        Presented at 2019 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        De Villiers, J., Lee, W., Liu, X., Hutchings, T.,
                        Rolfhus, E.
                      </div>
                    </div>
                    <div className="right">2019</div>
                  </div>

                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Development of an Early Language and Developmental
                        Screener for Chinese Infants and Toddlers: The DREAM-IT.
                      </div>
                      <div className="desc">
                        Presented at 2018 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        De Villiers, J., Liu, X., Lee, W., Hutchings, T.,
                        Rolfhus, E., & Yao, L.
                      </div>
                    </div>
                    <div className="right">2018</div>
                  </div>

                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Building the Foundation for Pediatric Language
                        Rehabilitation in China.
                      </div>
                      <div className="desc">
                        Presented at 2017 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        Jin, X., Zhang, Y., Liu, X., Simms, M.
                      </div>
                    </div>
                    <div className="right">2017</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Developing a Sustainable Discipline: The China
                        Experience.
                      </div>
                      <div className="desc">
                        Presented at 2016 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        Liu, X., Louko, L., Jiang, J., Clark, J., Hallowell, B.,
                        Lee, W., …Watson, J.
                      </div>
                    </div>
                    <div className="right">2016</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        Acquisition and delay of exhaustivity in multiple
                        wh-questions: Evidence from children acquiring Mandarin.
                      </div>
                      <div className="desc">
                        Poster presented at 2016 ASHA Convention in United
                        States.
                      </div>
                      <div className="position">
                        Liu, X., de Villiers, J., Ning, C., Rolfhus, E.,
                        Hutchings, T., Lee, W., ... & Zhang, Y.
                      </div>
                    </div>
                    <div className="right">2016</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        The Development of a New Norm-Referenced Mandarin
                        Assessment for Children.
                      </div>
                      <div className="desc">
                        Presented at 2014 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        de Villiers, J., Liu, X., Lee, W., Hutchings, T, &
                        Rolfhus, E.
                      </div>
                    </div>
                    <div className="right">2014</div>
                  </div>
                  <div className="item">
                    <div className="left">
                      <div className="title">
                        The China Consortium for Communication Health.
                      </div>
                      <div className="desc">
                        Presented at 2014 ASHA Convention in United States.
                      </div>
                      <div className="position">
                        Liu, X., Louko, L., Jiang, J., Clark, J., Hallowell, B.,
                        Lee, W., …Watson, J.
                      </div>
                    </div>
                    <div className="right">2014</div>
                  </div>
                </div>
              </div>
            </Layout>

            {/* 媒体报道 */}
            <Layout id="media">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("company-media-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("company-media-explain-desc")}
                </div>
              </div>

              <div className="list">
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media1.png"
                      alt="media"
                    />
                  </div>
                  <a
                    href="http://qingdao.dzwww.com/gjzzz/202007/t20200713_6241471.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="title"
                  >
                    {intl.get("company-media-list1-title")}
                  </a>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list1-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list1-tv")}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media2.png"
                      alt="media"
                    />
                  </div>
                  <a
                    href="https://www.sohu.com/a/341170866_100181583"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="title"
                  >
                    {intl.get("company-media-list2-title")}
                  </a>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list2-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list2-tv")}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media3.png"
                      alt="media"
                    />
                  </div>
                  <a
                    href="http://sc.china.com.cn/index.php?m=wap&siteid=1&a=show_wap&catid=1198&id=323540"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="title"
                  >
                    {intl.get("company-media-list3-title")}
                  </a>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list3-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list3-tv")}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media4.png"
                      alt="media"
                    />
                  </div>
                  <div className="title">
                    {intl.get("company-media-list4-title")}
                  </div>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list4-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list4-tv")}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media5.png"
                      alt="media"
                    />
                  </div>
                  <div className="title">
                    {intl.get("company-media-list5-title")}
                  </div>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list5-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list5-tv")}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media6.png"
                      alt="media"
                    />
                  </div>
                  <div className="title">
                    {intl.get("company-media-list6-title")}
                  </div>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list6-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list6-tv")}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media7.png"
                      alt="media"
                    />
                  </div>
                  <div className="title">
                    {intl.get("company-media-list7-title")}
                  </div>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list7-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list7-tv")}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/about/company/media8.png"
                      alt="media"
                    />
                  </div>
                  <div className="title">
                    {intl.get("company-media-list8-title")}
                  </div>
                  <div className="info">
                    <div className="date">
                      {intl.get("company-media-list8-date")}
                    </div>
                    <div className="tv">
                      {intl.get("company-media-list8-tv")}
                    </div>
                  </div>
                </div>
              </div>
            </Layout>

            {/* 获奖情况 */}
            <Layout id="award">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("company-award-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("company-award-explain-desc")}
                </div>
              </div>

              <div className="box">
                <div className="book">
                  <img src="/assets/images/home/book.png" alt="book" />
                </div>

                <div className="info">
                  <h3 className="title">
                    {intl.get("company-award-box-info-title")}
                  </h3>
                  <p className="desc">
                    {intl.getHTML("company-award-box-info-desc")}
                  </p>
                </div>
              </div>
            </Layout>

            {/* 连接底部背景色 */}
            <Layout id="background"></Layout>
          </div>
        );
    }
}