import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './C.less'

import { Layout} from 'antd';


export default class C extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    console.log(
      intl.options.currentLocale === "en-US"
        ? "现在是在英文环境"
        : "现在是在中文环境"
    );
  }
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  render() {
    return (
      <div id="C">
        <Helmet>
          <title>Dream-C</title>
          <meta
            name="keywords"
            content="儿童语言测评，儿童语言发育测试，儿童语言能力测试，语言测试报告，2.5-8岁语言评估，2.5-8岁语言能力评估"
          />
          <meta
            name="description"
            content="美国培声梦想普通话听力理解和表达能力标准化评估（以下简称“DREAM-C”）是中国大陆首个诊断性的参照常模的儿童普通话标准化语言测试。该工具由中美跨专业权威专家团队历时三年研发。DREAM-C通过评估儿童语言理解和语言表达，对儿童的语言习得进行全面的测试，并且对儿童在各年龄段应该具有的普通话语言技能（词汇、语义、句法等）做详细的分析。"
          />
        </Helmet>

        {/* banner图 */}
        <Layout className="SingleBanner">
          <div className="img">
            <img src="/assets/images/language/C/banner.png" alt="banner" />
          </div>
          <div className="box">
            <div className="thumb">
              <img src="/assets/images/language/C/thumb.png" alt="thumb" />
            </div>
            <div className="content">
              <h3
                className="title"
                style={
                  intl.options.currentLocale === "en-US"
                    ? { fontSize: "2em", lineHeight: "40px" }
                    : { fontSize: "2.5em" }
                }
              >
                {intl.getHTML("C-banner-title")}
              </h3>
              <p className="desc">{intl.getHTML("C-banner-desc")}</p>
            </div>
          </div>
        </Layout>

        {/* 导航 */}
        <Layout className="menulist">
          <div className="navlist">
            <div
              onClick={() => this.scrollToAnchor("introduction")}
              className="link"
            >
              <span>{intl.get("C-menulist1-zh")}</span>
              <span>{intl.get("C-menulist1-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("application")}
              className="link"
            >
              <span>{intl.get("C-menulist2-zh")}</span>
              <span>{intl.get("C-menulist2-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("features")}
              className="link"
            >
              <span>{intl.get("C-menulist3-zh")}</span>
              <span>{intl.get("C-menulist3-en")}</span>
            </div>
            <div
              onClick={() => this.scrollToAnchor("international")}
              className="link"
            >
              <span>{intl.get("C-menulist4-zh")}</span>
              <span>{intl.get("C-menulist4-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("case")} className="link">
              <span>{intl.get("C-menulist5-zh")}</span>
              <span>{intl.get("C-menulist5-en")}</span>
            </div>
            <div onClick={() => this.scrollToAnchor("tool")} className="link">
              <span>{intl.get("C-menulist6-zh")}</span>
              <span>{intl.get("C-menulist6-en")}</span>
            </div>
          </div>
        </Layout>

        <Layout className="CContainer">
          <div className="modular">
            {/* 简介 */}
            <div id="introduction">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-explain-title")}</h4>
                </div>
                <div className="desc">{intl.get("C-explain-desc")}</div>
              </div>

              <div className="content">
                <div className="desc">{intl.get("C-explain-content")}</div>

                <div className="back">
                  <img
                    src="/assets/images/language/C/introduction-back.png"
                    alt="back"
                  />
                </div>
              </div>
            </div>

            {/* 应用场景 */}
            <div id="application">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-application-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("C-application-explain-desc")}
                </div>
              </div>

              <div className="list">
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/language/C/application1.png"
                      alt="application1"
                    />
                  </div>
                  <div className="box">
                    <div className="name">
                      {intl.get("C-application-list1-name")}
                    </div>
                    <div className="info">
                      <ol>{intl.getHTML("C-application-list1-info")}</ol>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="img">
                    <img
                      src="/assets/images/language/C/application2.png"
                      alt="application2"
                    />
                  </div>
                  <div className="box">
                    <div className="name">
                      {intl.get("C-application-list2-name")}
                    </div>
                    <div className="info">
                      <ol>{intl.getHTML("C-application-list2-info")}</ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 特点与优势 */}
            <div id="features">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-features-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("C-features-explain-desc")}
                </div>
              </div>

              <div className="content">
                {intl.getHTML("C-features-explain-content")}
              </div>
            </div>

            {/* 国际标准 */}
            <div id="international">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-internationalC-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("C-internationalC-explain-desc")}
                </div>
              </div>

              <div className="introduce">
                {intl.getHTML("C-internationalC-explain-content")}
                <img
                  src="/assets/images/language/C/international1.png"
                  alt="international1"
                />
                <img
                  src="/assets/images/language/C/international2.png"
                  alt="international2"
                />
                <img
                  src="/assets/images/language/C/international3.png"
                  alt="international3"
                />
                <img
                  src="/assets/images/language/C/international4.png"
                  alt="international4"
                />
              </div>
            </div>

            {/* 临床案例与测评报告 */}
            <div id="case">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-case-explain-title")}</h4>
                </div>
                <div className="desc">{intl.get("C-case-explain-desc")}</div>
              </div>

              <div className="content">
                <div className="card">
                  <img src={intl.get("C-case-img")} alt="card" />
                </div>
                <div className="desc">
                  {intl.getHTML("C-case-explain-content")}
                </div>

                <div className="pages">
                  <div className="img">
                    <img
                      src="/assets/images/language/C/pages1.png"
                      alt="pages1"
                    />
                  </div>
                  <div className="img">
                    <img
                      src="/assets/images/language/C/pages2.png"
                      alt="pages2"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* 语言测评工具购买 */}
            <div id="tool">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-tool-explain-title")}</h4>
                </div>
                <div className="desc">{intl.get("C-tool-explain-desc")}</div>
              </div>

              <div className="content">
                <div className="desc">
                  {intl.getHTML("C-tool-explain-content")}
                  <div className="notice">
                    {intl.get("C-tool-explain-notice")}
                  </div>
                  <div className="qrcode">
                    <img
                      src="/assets/images/common/contact-qrcode.jpeg"
                      alt="qrcode"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* 了解更多 */}
            <div id="learnmore">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-learnmore1-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("C-learnmore1-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="desc">
                  {intl.getHTML("C-learnmore1-explain-content")}
                </div>

                <div className="link">
                  <Link className="btn" to="/dreamc">
                    {intl.get("C-learnmore1-explain-btn")}
                  </Link>
                </div>
              </div>
            </div>

            {/* 了解更多 */}
            <div id="learnmore">
              <div className="explain">
                <div className="title">
                  <h4>{intl.get("C-learnmore2-explain-title")}</h4>
                </div>
                <div className="desc">
                  {intl.get("C-learnmore2-explain-desc")}
                </div>
              </div>

              <div className="content">
                <div className="desc">
                  {intl.getHTML("C-learnmore2-explain-content")}
                </div>

                <div className="link">
                  <Link
                    className="btn"
                    to={{ pathname: "/mechanism", query: { tool_id: "c" } }}
                  >
                    {intl.get("C-learnmore2-explain-btn")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}