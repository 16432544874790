import './App.less'
import {BrowserRouter,Switch} from 'react-router-dom'
import RouterWrap from './router'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <RouterWrap />
      </Switch>
    </BrowserRouter>
  )
}

export default App
