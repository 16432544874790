import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import intl from 'react-intl-universal'
import { Helmet } from 'react-helmet'

import './ITS.less'

import { Layout} from 'antd';


export default class Home extends Component{
    constructor(props)
    {
        super(props)

        this.state = {
            
        }
    }
    scrollToAnchor = (anchorName) => 
    {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({behavior:'smooth'}); }
        }
      }
    render()
    {
        return (
          <div id="ITS">
            <Helmet>
              <title>Dream-ITS</title>
              <meta
                name="keywords"
                content="1.婴幼儿语言测试，0-3岁语言测试，0-3岁语言能力测试，婴幼儿语言水平测试，婴幼儿语言发育测试"
              />
              <meta
                name="description"
                content="美国培声DREAM-IT-S梦想婴幼儿语言沟通能力筛查（以下简称“DREAM-IT-S”）是专门针对0-3岁婴幼儿设计研发的一款语言沟通筛查工具。DREAM-IT-S通过家长问卷的形式，筛查婴幼儿语言沟通发展的四个能区，包括：语言理解、语言表达、社交沟通和认知玩耍，后两个能区是婴幼儿语言发展必备的和紧不可分的基础技能。DREAM-IT-S是儿童语言落后“早发现、早评估、早干预”的重要基础。"
              />
            </Helmet>

            {/* banner图 */}
            <Layout className="SingleBanner">
              <div className="img">
                <img
                  src="/assets/images/language/ITS/banner.png"
                  alt="banner"
                />
              </div>
              <div className="box">
                <div className="thumb">
                  <img
                    src="/assets/images/language/ITS/thumb.png"
                    alt="thumb"
                  />
                </div>
                <div className="content">
                  <h3
                    className="title"
                    style={
                      intl.options.currentLocale === "en-US"
                        ? { fontSize: "2em", lineHeight: "40px" }
                        : { fontSize: "2.5em" }
                    }
                  >
                    {intl.getHTML("ITS-banner-title")}
                  </h3>
                  <p className="desc">{intl.get("ITS-banner-desc")}</p>
                </div>
              </div>
            </Layout>

            {/* 导航 */}
            <Layout className="menulist">
              <div className="navlist">
                <div
                  onClick={() => this.scrollToAnchor("introduction")}
                  className="link"
                >
                  <span>{intl.get("ITS-menulist1-zh")}</span>
                  <span>{intl.get("ITS-menulist1-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("application")}
                  className="link"
                >
                  <span>{intl.get("ITS-menulist2-zh")}</span>
                  <span>{intl.get("ITS-menulist2-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("features")}
                  className="link"
                >
                  <span>{intl.get("ITS-menulist3-zh")}</span>
                  <span>{intl.get("ITS-menulist3-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("internationalITS")}
                  className="link"
                >
                  <span>{intl.get("ITS-menulist4-zh")}</span>
                  <span>{intl.get("ITS-menulist4-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("case")}
                  className="link"
                >
                  <span>{intl.get("ITS-menulist5-zh")}</span>
                  <span>{intl.get("ITS-menulist5-en")}</span>
                </div>
                <div
                  onClick={() => this.scrollToAnchor("tool")}
                  className="link"
                >
                  <span>{intl.get("ITS-menulist6-zh")}</span>
                  <span>{intl.get("ITS-menulist6-en")}</span>
                </div>
              </div>
            </Layout>

            <Layout className="ITSContainer">
              <div className="modular">
                {/* 简介 */}
                <div id="introduction">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("ITS-explain-title")}</h4>
                    </div>
                    <div className="desc">{intl.get("ITS-explain-desc")}</div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.get("ITS-explain-content")}
                    </div>

                    <div className="back">
                      <img
                        src="/assets/images/language/ITS/introduction-back.png"
                        alt="back"
                      />
                    </div>
                  </div>
                </div>

                {/* 应用场景 */}
                <div id="application">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("ITS-application-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("ITS-application-explain-desc")}
                    </div>
                  </div>

                  <div className="list">
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/ITS/application3.png"
                          alt="application1"
                        />
                      </div>
                      <div className="title">
                        {intl.get("ITS-application-list1")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/ITS/application2.png"
                          alt="application2"
                        />
                      </div>
                      <div className="title">
                        {intl.get("ITS-application-list2")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="img">
                        <img
                          src="/assets/images/language/ITS/application1.png"
                          alt="application3"
                        />
                      </div>
                      <div className="title">
                        {intl.get("ITS-application-list3")}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 特点与优势 */}
                <div id="features">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("ITS-features-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("ITS-features-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("ITS-features-explain-content")}
                    </div>

                    <div className="back">
                      <img src={intl.get("ITS-feature-img")} alt="back" />
                    </div>
                  </div>
                </div>

                {/* 国际标准 */}
                <div id="internationalITS">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("ITS-internationalITS-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("ITS-internationalITS-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="back">
                      <img
                        src="/assets/images/language/ITS/international.png"
                        alt="back"
                      />
                    </div>

                    <div className="desc">
                      {intl.get("ITS-internationalITS-explain-content")}
                    </div>
                  </div>
                </div>

                {/* 临床案例与测评报告 */}
                <div id="case">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("ITS-case-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("ITS-case-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="card">
                      <img src={intl.get("ITS-case-img")} alt="card" />
                    </div>
                    <div className="desc">
                      {intl.getHTML("ITS-case-explain-content")}
                    </div>
                    <div className="pages">
                      <div className="img">
                        <img
                          src="/assets/images/language/ITS/pages1.png"
                          alt="pages1"
                        />
                      </div>
                      <div className="img">
                        <img
                          src="/assets/images/language/ITS/pages2.png"
                          alt="pages2"
                        />
                      </div>
                      <div className="img">
                        <img
                          src="/assets/images/language/ITS/pages3.png"
                          alt="pages3"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* 语言测评工具购买 */}
                <div id="tool">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("ITS-tool-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("ITS-tool-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("ITS-tool-explain-content")}
                      <div className="notice">
                        {intl.get("ITS-tool-explain-notice")}
                      </div>
                      <div className="qrcode">
                        <img
                          src="/assets/images/common/xcx-qrcode.png"
                          alt="qrcode"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* 了解更多 */}
                <div id="learnmore">
                  <div className="explain">
                    <div className="title">
                      <h4>{intl.get("ITS-learnmore-explain-title")}</h4>
                    </div>
                    <div className="desc">
                      {intl.get("ITS-learnmore-explain-desc")}
                    </div>
                  </div>

                  <div className="content">
                    <div className="desc">
                      {intl.getHTML("ITS-learnmore-explain-content")}
                    </div>

                    <div className="link">
                      <Link
                        className="btn"
                        to={{
                          pathname: "/mechanism",
                          query: { tool_id: "its" },
                        }}
                      >
                        {intl.get("ITS-learnmore-explain-btn")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        );
    }
}